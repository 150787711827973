import 'moment/locale/es-mx';
import Condominio from '../../../../modelo/Condominio';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const formateador = new Intl.NumberFormat('es-MX', {
	currency: 'MXN',
	style: 'currency'
});

const seccion = (condominio: Condominio) =>
	new Promise((resolve) => {
		if (condominio) {
			const { globalesValorTerreno, superficie, valorTerreno } = condominio;

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									colSpan: 2,
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'DATOS DEL TERRENO'
								},
								{}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'ÁREA TERRENO GLOBAL'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR UNITARIO TERRENO'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: superficie ? `${superficie.toLocaleString('es-MX')} m²` : ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: valorTerreno ? formateador.format(valorTerreno) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR TOTAL TERRENO'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: globalesValorTerreno ? formateador.format(globalesValorTerreno) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
