import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { Fragment } from 'react';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosUsuariosProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los calálogos.
 */
const RolFormularioPermisosCatalogos = (props: RolFormularioPermisosUsuariosProps) => {
	const { soloLectura = false } = props;

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<legend>{texto('Clasificaciones de los condominios')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesVerLista}
						etiqueta={texto('Ver la lista de las clasificaciones de los condominios.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesEditarDetalles}
						etiqueta={texto('Editar los detalles de las clasificaciones de los condominios.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesCrear}
						etiqueta={texto('Crear clasificaciones de los condominios nuevas.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesEliminar}
						etiqueta={texto('Eliminar clasificaciones de los condominios.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Calidades de construcción')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionVerLista}
						etiqueta={texto('Ver la lista de las calidades de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionEditarDetalles}
						etiqueta={texto('Editar los detalles de las calidades de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionCrear}
						etiqueta={texto('Crear calidades de construcción nuevas.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionEliminar}
						etiqueta={texto('Eliminar calidades de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Clasificaciones de zona')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesZonaVerLista}
						etiqueta={texto('Ver la lista de las clasificaciones de zona.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesZonaVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesZonaEditarDetalles}
						etiqueta={texto('Editar los detalles de las clasificaciones de zona.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesZonaEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesZonaCrear}
						etiqueta={texto('Crear clasificaciones de zona nuevas.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesZonaCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosClasificacionesZonaEliminar}
						etiqueta={texto('Eliminar clasificaciones de zona.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosClasificacionesZonaEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Construcciones dominantes')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesVerLista}
						etiqueta={texto('Ver la lista de las construcciones dominantes.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEditarDetalles}
						etiqueta={texto('Editar los detalles de las construcciones dominantes.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesCrear}
						etiqueta={texto('Crear construcciones dominantes nuevas.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEliminar}
						etiqueta={texto('Eliminar construcciones dominantes.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Destinos específicos')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosDestinosEspecificosVerLista}
						etiqueta={texto('Ver la lista de los destinos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosDestinosEspecificosVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosDestinosEspecificosEditarDetalles}
						etiqueta={texto('Editar los detalles de los destinos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosDestinosEspecificosEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosDestinosEspecificosCrear}
						etiqueta={texto('Crear destinos específicos nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosDestinosEspecificosCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosDestinosEspecificosEliminar}
						etiqueta={texto('Eliminar destinos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosDestinosEspecificosEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Elementos de construcción')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosElementosConstruccionVerLista}
						etiqueta={texto('Ver la lista de los elementos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosElementosConstruccionVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosElementosConstruccionEditarDetalles}
						etiqueta={texto('Editar los detalles de los elementos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosElementosConstruccionEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosElementosConstruccionCrear}
						etiqueta={texto('Crear elementos de construcción nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosElementosConstruccionCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosElementosConstruccionEliminar}
						etiqueta={texto('Eliminar elementos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosElementosConstruccionEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Estados de conservación')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosEstadosConservacionVerLista}
						etiqueta={texto('Ver la lista de los estados de conservación.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosEstadosConservacionVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosEstadosConservacionEditarDetalles}
						etiqueta={texto('Editar los detalles de los estados de conservación.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosEstadosConservacionEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosEstadosConservacionCrear}
						etiqueta={texto('Crear estados de conservación nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosEstadosConservacionCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosEstadosConservacionEliminar}
						etiqueta={texto('Eliminar estados de conservación.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosEstadosConservacionEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Motivos de ajuste')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosMotivosAjusteVerLista}
						etiqueta={texto('Ver la lista de los motivos de ajuste.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosMotivosAjusteVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosMotivosAjusteEditarDetalles}
						etiqueta={texto('Editar los detalles de los motivos de ajuste.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosMotivosAjusteEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosMotivosAjusteCrear}
						etiqueta={texto('Crear motivos de ajuste nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosMotivosAjusteCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosMotivosAjusteEliminar}
						etiqueta={texto('Eliminar motivos de ajuste.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosMotivosAjusteEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Regímenes de propiedad')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadVerLista}
						etiqueta={texto('Ver la lista de los regímenes de propiedad.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEditarDetalles}
						etiqueta={texto('Editar los detalles de los regímenes de propiedad.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadCrear}
						etiqueta={texto('Crear regímenes de propiedad nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEliminar}
						etiqueta={texto('Eliminar regímenes de propiedad.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Servicios municipales')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesVerLista}
						etiqueta={texto('Ver la lista de los servicios municipales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesEditarDetalles}
						etiqueta={texto('Editar los detalles de los servicios municipales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesCrear}
						etiqueta={texto('Crear servicios municipales nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesEliminar}
						etiqueta={texto('Eliminar servicios municipales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Tipos de calle')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposCalleVerLista}
						etiqueta={texto('Ver la lista de los tipos de calle.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposCalleVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposCalleEditarDetalles}
						etiqueta={texto('Editar los detalles de los tipos de calle.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposCalleEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposCalleCrear}
						etiqueta={texto('Crear tipos de calle nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposCalleCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposCalleEliminar}
						etiqueta={texto('Eliminar tipos de calle.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposCalleEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Tipos de construcción')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposConstruccionVerLista}
						etiqueta={texto('Ver la lista de los tipos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposConstruccionVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposConstruccionEditarDetalles}
						etiqueta={texto('Editar los detalles de los tipos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposConstruccionEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposConstruccionCrear}
						etiqueta={texto('Crear tipos de construcción nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposConstruccionCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposConstruccionEliminar}
						etiqueta={texto('Eliminar tipos de construcción.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposConstruccionEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Tipos de inmueble')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposInmuebleVerLista}
						etiqueta={texto('Ver la lista de los tipos de inmueble.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposInmuebleVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposInmuebleEditarDetalles}
						etiqueta={texto('Editar los detalles de los tipos de inmueble.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposInmuebleEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposInmuebleCrear}
						etiqueta={texto('Crear tipos de inmueble nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposInmuebleCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosTiposInmuebleEliminar}
						etiqueta={texto('Eliminar tipos de inmueble.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosTiposInmuebleEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Unidades de superficie')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieVerLista}
						etiqueta={texto('Ver la lista de las unidades de superficie.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieEditarDetalles}
						etiqueta={texto('Editar los detalles de las unidades de superficie.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieCrear}
						etiqueta={texto('Crear unidades de superficie nuevas.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieEliminar}
						etiqueta={texto('Eliminar unidades de superficie.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Usos específicos')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUsosEspecificosVerLista}
						etiqueta={texto('Ver la lista de los usos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUsosEspecificosVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUsosEspecificosEditarDetalles}
						etiqueta={texto('Editar los detalles de los usos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUsosEspecificosEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUsosEspecificosCrear}
						etiqueta={texto('Crear usos específicos nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUsosEspecificosCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosUsosEspecificosEliminar}
						etiqueta={texto('Eliminar usos específicos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosUsosEspecificosEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<legend>{texto('Vientos cardinales')}</legend>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosVientosCardinalesVerLista}
						etiqueta={texto('Ver la lista de los vientos cardinales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosVientosCardinalesVerLista}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosVientosCardinalesEditarDetalles}
						etiqueta={texto('Editar los detalles de los vientos cardinales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosVientosCardinalesEditarDetalles}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosVientosCardinalesCrear}
						etiqueta={texto('Crear vientos cardinales nuevos.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosVientosCardinalesCrear}
						soloLectura={soloLectura}
					/>
					<McSwitchRedondo
						campo={constantes.permiso.appAdministracionCatalogosVientosCardinalesEliminar}
						etiqueta={texto('Eliminar vientos cardinales.')}
						etiquetaLineal
						id={constantes.permiso.appAdministracionCatalogosVientosCardinalesEliminar}
						soloLectura={soloLectura}
					/>
				</Col>
			</Row>
		</Fragment>
	);
};

export default RolFormularioPermisosCatalogos;
