import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';
import { eliminarUsuarioRol, obtenerUsuarioRolPorId, validarUsuarioRolEliminacion } from 'servicios/api/usuariosRoles';
import { tieneAlMenosUnPermiso, tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import RolFormularioPermisos from 'componentes/roles/RolFormularioPermisos';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';

const NOMBRE_CLASE = 'paginas/app/roles/RolDetalles';

/**
 * En esta página se muestra la información detallada del rol.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { sesion } = useSelector((state: StateType) => state);
	const [registro, setRegistro] = useState<any>();
	const [formulario, setFormulario] = useState<any>();
	const { auth0, usuario } = sesion;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.usuarioRoles);
	const { auth0AccessToken } = auth0;
	const { id } = useParams<any>();
	const [sePuedeEliminar, setSePuedeEliminar] = useState<boolean>(false);
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const RUTA_SALIR = `${constantes.ruta.appAdministracionRolesLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;

	useEffect(() => {
		obtenerRegistro();
	}, []);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Rol')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el rol, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del rol?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
		history.push(RUTA_SALIR);
	};

	/**
	 * Redirecciona al usuario a la ruta de edición.
	 */
	const eventoEditarDetalles = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		const ruta = `${constantes.ruta.appAdministracionRolesDetallesEditar}/${id}`;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando el rol.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		try {
			await eliminarUsuarioRol({ auth0AccessToken, id });
			mcNotificaciones.exito({ mensaje: texto('Rol eliminado con exito.') });
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
			history.push(RUTA_SALIR);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del rol...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerUsuarioRolPorId({ auth0AccessToken, id });
			mcLogger.log({ mensaje: `Información del rol obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			const sePuedeEliminar = await validarUsuarioRolEliminacion({ auth0AccessToken, id: datos.id });
			setSePuedeEliminar(sePuedeEliminar);
			setRegistro(datos);
			const permisosObjeto: any = {};
			datos.permisos.forEach((permiso: string) => {
				permisosObjeto[permiso] = true;
			});
			setFormulario(permisosObjeto);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el rol: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	return (
		<Contenedor>
			<Breadcrumb
				enlaces={[
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{ ruta: RUTA_SALIR, titulo: texto('Lista de roles') }
				]}
				icono={constantes.icono.rol}
				titulo={texto('Detalles del rol')}
			/>
			<Form
				initialValues={formulario}
				onSubmit={() => {}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardHeader>
								<BarraHerramientas>
									{tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEliminar, usuario }) && sePuedeEliminar && (
										<button
											className="btn btn-danger"
											id="botonEliminarRol"
											onClick={() => {
												setModalEliminar(true);
											}}
											type="button"
										>
											<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
										</button>
									)}
									{dibujarModalEliminar()}
									{tieneAlMenosUnPermiso({
										permisos: [
											constantes.permiso.appAdministracionRolesEditarDetalles,
											constantes.permiso.appAdministracionRolesEditarPermisosAplicacion,
											constantes.permiso.appAdministracionRolesEditarPermisosAvaluos,
											constantes.permiso.appAdministracionRolesEditarPermisosCatalogos,
											constantes.permiso.appAdministracionRolesEditarPermisosCondominios,
											constantes.permiso.appAdministracionRolesEditarPermisosDepartamentos,
											constantes.permiso.appAdministracionRolesEditarPermisosNotificaciones,
											constantes.permiso.appAdministracionRolesEditarPermisosPeritos,
											constantes.permiso.appAdministracionRolesEditarPermisosRoles,
											constantes.permiso.appAdministracionRolesEditarPermisosUsuarios
										],
										usuario
									}) && (
										<button className="btn btn-info" id="botonEditarRol" onClick={eventoEditarDetalles} type="button">
											<i className={constantes.icono.editar}></i> {texto('Editar detalles')}
										</button>
									)}
									<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
										<i className={constantes.icono.atras}></i> {texto('Salir')}
									</button>
								</BarraHerramientas>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="12">
										<h3>{registro?.nombre}</h3>
										<p className="card-text">{registro?.descripcion}</p>
									</Col>
									<Col md="12">
										<h5 className="mt-3">
											<i className={constantes.icono.usuarioPermisos}></i> {texto('Lista de permisos')}
										</h5>
										<RolFormularioPermisos
											permisosAplicacionEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosAvaluosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosCatalogosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosCondominiosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosCondominios, usuario })}
											permisosDepartamentosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosDepartamentos, usuario })}
											permisosNotificacionesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosPeritosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosRolesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											permisosUsuariosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesVerDetalles, usuario })}
											soloLectura
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</form>
				)}
			/>
		</Contenedor>
	);
};

export default Pagina;
