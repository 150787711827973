import Condominio from 'modelo/Condominio';
import { tieneValor } from '@mcsoft/validaciones';

/**
 * Formatea un condominio para ser guardado en base de datos.
 * - ***condominio*** - Objeto con la información del condominio.
 */
export const formatearCondominioParaBD = (condominio: any) => {
	const nuevoRegistro: Condominio = {
		...condominio,
		clasificacionConstruccionComun1Id: tieneValor(condominio.clasificacionConstruccionComun1Seleccion) ? condominio.clasificacionConstruccionComun1Seleccion.value : null,
		clasificacionConstruccionComun2Id: tieneValor(condominio.clasificacionConstruccionComun2Seleccion) ? condominio.clasificacionConstruccionComun2Seleccion.value : null,
		clasificacionConstruccionComun3Id: tieneValor(condominio.clasificacionConstruccionComun3Seleccion) ? condominio.clasificacionConstruccionComun3Seleccion.value : null,
		clasificacionConstruccionComun4Id: tieneValor(condominio.clasificacionConstruccionComun4Seleccion) ? condominio.clasificacionConstruccionComun4Seleccion.value : null,
		clasificacionConstruccionComun5Id: tieneValor(condominio.clasificacionConstruccionComun5Seleccion) ? condominio.clasificacionConstruccionComun5Seleccion.value : null,
		clasificacionConstruccionPrivativaId: tieneValor(condominio.clasificacionConstruccionPrivativaSeleccion) ? condominio.clasificacionConstruccionPrivativaSeleccion.value : null,
		clasificacionesGlobales: condominio.clasificacionesGlobales,
		incrementoConstruccionComun1: tieneValor(condominio.incrementoConstruccionComun1) ? condominio.incrementoConstruccionComun1 : null,
		incrementoConstruccionComun2: tieneValor(condominio.incrementoConstruccionComun2) ? condominio.incrementoConstruccionComun2 : null,
		incrementoConstruccionComun3: tieneValor(condominio.incrementoConstruccionComun3) ? condominio.incrementoConstruccionComun3 : null,
		incrementoConstruccionComun4: tieneValor(condominio.incrementoConstruccionComun4) ? condominio.incrementoConstruccionComun4 : null,
		incrementoConstruccionComun5: tieneValor(condominio.incrementoConstruccionComun5) ? condominio.incrementoConstruccionComun5 : null,
		incrementoConstruccionPrivativa: tieneValor(condominio.incrementoConstruccionPrivativa) ? condominio.incrementoConstruccionPrivativa : null
	};
	return nuevoRegistro;
};

/**
 * Formatea un condominio para ser mostrado en el formulario.
 * - ***condominio*** - Objeto con la información del condominio.
 */
export const formatearCondominioParaFormulario = (condominio: Condominio) => {
	const registro: any = {
		...condominio
	};
	if (tieneValor(condominio.clasificacionConstruccionComun1)) {
		registro.clasificacionConstruccionComun1Seleccion = {
			label: `${condominio.clasificacionConstruccionComun1?.codigo} - ${condominio.clasificacionConstruccionComun1?.ano}`,
			value: condominio.clasificacionConstruccionComun1?.id
		};
	}
	if (tieneValor(condominio.clasificacionConstruccionComun2)) {
		registro.clasificacionConstruccionComun2Seleccion = {
			label: `${condominio.clasificacionConstruccionComun2?.codigo} - ${condominio.clasificacionConstruccionComun2?.ano}`,
			value: condominio.clasificacionConstruccionComun2?.id
		};
	}
	if (tieneValor(condominio.clasificacionConstruccionComun3)) {
		registro.clasificacionConstruccionComun3Seleccion = {
			label: `${condominio.clasificacionConstruccionComun3?.codigo} - ${condominio.clasificacionConstruccionComun3?.ano}`,
			value: condominio.clasificacionConstruccionComun3?.id
		};
	}
	if (tieneValor(condominio.clasificacionConstruccionComun4)) {
		registro.clasificacionConstruccionComun4Seleccion = {
			label: `${condominio.clasificacionConstruccionComun4?.codigo} - ${condominio.clasificacionConstruccionComun4?.ano}`,
			value: condominio.clasificacionConstruccionComun4?.id
		};
	}
	if (tieneValor(condominio.clasificacionConstruccionComun5)) {
		registro.clasificacionConstruccionComun5Seleccion = {
			label: `${condominio.clasificacionConstruccionComun5?.codigo} - ${condominio.clasificacionConstruccionComun5?.ano}`,
			value: condominio.clasificacionConstruccionComun5?.id
		};
	}
	if (tieneValor(condominio.clasificacionConstruccionPrivativa)) {
		registro.clasificacionConstruccionPrivativaSeleccion = {
			label: `${condominio.clasificacionConstruccionPrivativa?.codigo} - ${condominio.clasificacionConstruccionPrivativa?.ano}`,
			value: condominio.clasificacionConstruccionPrivativa?.id
		};
	}
	return registro;
};
