import { Col, Row } from 'reactstrap';
import { McCampoTexto } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarClasificacionDisponibilidad } from 'servicios/api/clasificaciones';

/**
 * Formulario para una clasificación del condominio.
 */
const ClasificacionFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;

	/**
	 * Válida el año de la clasificación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioAno = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarClasificacionDisponibilidad({
			ano: Number(valor),
			auth0AccessToken,
			codigo: valores.codigo,
			id: valores.id
		});
		if (!disponible) {
			const error = 'Ya existe un clasificación con ese código para ese año';
			return error;
		}
	};

	/**
	 * Válida el código de la clasificación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioCodigo = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarClasificacionDisponibilidad({
			ano: Number(valores.ano),
			auth0AccessToken,
			codigo: valor,
			id: valores.id
		});
		if (!disponible) {
			const error = 'Ya existe un clasificación con ese código para ese año';
			return error;
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="4">
						<McCampoTexto
							campo="ano"
							etiqueta={texto('Año')}
							funcionValidacionAsincrona={validarFormularioAno}
							id="ano"
							numeroMaximo={9999}
							numeroMinimo={1800}
							obligatorio
							tipo="numeroEnteroPequeno"
						/>
					</Col>
					<Col md="4">
						<McCampoTexto campo="codigo" etiqueta={texto('Código')} funcionValidacionAsincrona={validarFormularioCodigo} id="codigo" longitudMaxima={10} obligatorio />
					</Col>
					<Col md="4">
						<McCampoTexto campo="valor" etiqueta={texto('Valor')} id="valor" obligatorio tipo="numeroNumerico" />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ClasificacionFormulario;
