import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';
import { eliminarCondominio, obtenerCondominioPorId } from 'servicios/api/condominios';
import McTablaPaginada, {
	McTablaPaginadaEncabezado,
	tablaPaginadaEventoCambiarOrdenamiento,
	tablaPaginadaEventoCambiarPaginaActual,
	tablaPaginadaEventoCambiarParametrosPaginacion,
	tablaPaginadaEventoCambiarRegistrosPorPagina
} from '@mcsoft/tabla-paginada';
import { procesarError, RegistrosPaginados } from '@mcsoft/api';
import { setPantallaCargaMostrarAction, setParametrosPaginacionDepartamentosAction, setParametrosPaginacionDepartamentosAdminAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useGenerarReporteGlobalesPdfContenido, useLimpiarReporteGlobales, useObtenerReporteGlobales } from 'hooks/useReporte';
import { useHistory, useLocation, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import Condominio from 'modelo/Condominio';
import CondominioTarjeta from 'componentes/condominios/CondominioTarjeta';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerDepartamentosPaginadosPorCondominio } from 'servicios/api/departamentos';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { tieneValor } from '@mcsoft/validaciones';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';
import useMoneda from 'hooks/useMoneda';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const NOMBRE_CLASE = 'paginas/app/condominios/CondominioDetalles';

/**
 * En esta página se muestra la información detallada del rol.
 */
const Pagina = () => {
	const moneda = useMoneda();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const urlCriterio = new URLSearchParams(location.search).get('criterio');
	const urlOrden = new URLSearchParams(location.search).get('orden');
	const urlOrdenamiento = new URLSearchParams(location.search).get('ordenamiento');
	const urlPagina = new URLSearchParams(location.search).get('pagina');
	const urlRegistrosPorPagina = new URLSearchParams(location.search).get('registrosPorPagina');
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const limpiarReporteGlobales = useLimpiarReporteGlobales();
	const obtenerReporteGlobales = useObtenerReporteGlobales();
	const generarReporteGlobalesPdfContenido = useGenerarReporteGlobalesPdfContenido();
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const { sesion } = useSelector((state: StateType) => state);
	const [registro, setRegistro] = useState<any>();
	const { auth0, usuario } = sesion;
	const {
		criterio: criterioCondominio,
		orden: ordenCondominio,
		ordenamiento: ordenamientoCondominio,
		pagina: paginaCondominio,
		registrosPorPagina: registrosPorPaginaCondominio
	} = useSelector((state: StateType) => state.parametrosPaginacion.condominios);
	const {
		criterio: criterioCondominioAdmin,
		orden: ordenCondominioAdmin,
		ordenamiento: ordenamientoCondominioAdmin,
		pagina: paginaCondominioAdmin,
		registrosPorPagina: registrosPorPaginaCondominioAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.condominiosAdmin);
	const {
		criterio: criterioDepartamentos,
		orden: ordenDepartamentos,
		ordenamiento: ordenamientoDepartamentos,
		pagina: paginaDepartamentos,
		registrosPorPagina: registrosPorPaginaDepartamentos
	} = useSelector((state: StateType) => state.parametrosPaginacion.departamentos);
	const {
		criterio: criterioDepartamentosAdmin,
		orden: ordenDepartamentosAdmin,
		ordenamiento: ordenamientoDepartamentosAdmin,
		pagina: paginaDepartamentosAdmin,
		registrosPorPagina: registrosPorPaginaDepartamentosAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.departamentosAdmin);
	const [criterio, setCriterio] = useState<string>('');
	const [orden, setOrden] = useState<string>('nombre');
	const [ordenamiento, setOrdenamiento] = useState<string>('');
	const [pagina, setPagina] = useState<number>(1);
	const [registrosPorPagina, setRegistrosPorPagina] = useState<number>(10);
	const [registrosLista, setRegistrosLista] = useState<Array<any>>([]);
	const [registrosMostrandoFinal, setRegistrosMostrandoFinal] = useState<number>(0);
	const [registrosMostrandoInicial, setRegistrosMostrandoInicial] = useState<number>(0);
	const [registrosMostrandoPorPagina, setRegistrosMostrandoPorPagina] = useState<number>(20);
	const [registrosMostrandoPorPaginaOpciones] = useState<Array<string>>();
	const [registrosPaginaFinal, setRegistrosPaginaFinal] = useState<number>(1);
	const [registrosTotal, setRegistrosTotal] = useState<number>(0);
	const [tablaEncabezados, setTablaEncabezados] = useState<Array<McTablaPaginadaEncabezado>>([]);
	const { auth0AccessToken } = auth0;
	const { id } = useParams<any>();
	const [seccionEnlaces, setSeccionEnlaces] = useState<any>([]);
	const [seccionIcono, setSeccionIcono] = useState<string>('');
	const [seccionPermisoGenerarReporte, setSeccionPermisoGenerarReporte] = useState<string>('');
	const [seccionPermisoCargarExcel, setSeccionPermisoCargarExcel] = useState<string>('');
	const [seccionPermisoCrearRegistro, setSeccionPermisoCrearRegistro] = useState<string>('');
	const [seccionPermisoEditarRegistro, setSeccionPermisoEditarRegistro] = useState<string>('');
	const [seccionPermisoEliminarRegistro, setSeccionPermisoEliminarRegistro] = useState<string>('');
	const [seccionRutaCargar, setSeccionRutaCargar] = useState<string>('');
	const [seccionRutaCreacion, setSeccionRutaCreacion] = useState<string>('');
	const [seccionRutaEditar, setSeccionRutaEditar] = useState<string>('');
	const [seccionRutaSalida, setSeccionRutaSalida] = useState<string>('');
	const [seccionTitulo, setSeccionTitulo] = useState<string>('');
	const indivisoGlobalRef = useRef<any>(null);
	const [mostrarCalculoGlobales, setMostrarCalculoGlobales] = useState<boolean>(false);

	/**
	 * Dibuja un atributo del condominio.
	 * - ***etiqueta*** - Etiqueta que del atributo (Se mostrará en pantalla md+).
	 * - ***etiquetaMovil*** - Etiqueta que del atributo (Se mostrará en pantalla sm-).
	 * - ***icono*** - Icono *FontAwesome* que se mostrará antes de la etiqueta.
	 * - ***valor*** - Valor del atributo.
	 */
	const dibujarAtributo = ({ etiqueta, etiquetaMovil, icono, valor }: { etiqueta: string; etiquetaMovil?: string; icono?: string; valor: any }) => {
		const etiquetaMovilFinal = etiquetaMovil || etiqueta;

		return (
			<div className="flex space-x-2">
				<span className="fw-bold">
					{icono && (
						<div className="mr-2">
							<i className={icono} />
						</div>
					)}
					<span className="lg:hidden flex">{etiquetaMovilFinal}:</span>
					<span className="hidden lg:flex">{etiqueta}:</span>
				</span>
				<span className="fw-light mx-2">
					<span>{valor}</span>
				</span>
			</div>
		);
	};

	/**
	 * Calcula el reporte de globales.
	 */
	const eventoCalcularReportedeGlobales = () => {
		const nombreMetodo = 'eventoCalcularReportedeGlobales';
		mcLogger.log({
			mensaje: `Calculando reporte de globales...`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		if (tieneValor(indivisoGlobalRef.current?.value) && registro && registro.superficie && registro.valorTerreno) {
			obtenerReporteGlobales({
				condominio: registro as Condominio,
				indiviso: indivisoGlobalRef.current?.value
			});
			setMostrarCalculoGlobales(true);
		}
	};

	/**
	 * Genera el PDF del reporte de globales.
	 */
	const eventoGenerarPdf = async (descargar?: boolean) => {
		const nombreMetodo = 'eventoGenerarPdf';
		try {
			mcLogger.log({
				mensaje: `Generando PDF del reporte de globales...`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo
			});
			dispatch(setPantallaCargaMostrarAction(true));
			const avaluoPdf = await generarReporteGlobalesPdfContenido({
				condominio: registro,
				indiviso: indivisoGlobalRef.current.value
			});
			if (descargar) {
				pdfMake.createPdf(avaluoPdf.contenido).download(avaluoPdf.titulo);
			} else {
				pdfMake.createPdf(avaluoPdf.contenido).open();
			}
			mcLogger.log({
				mensaje: `PDF del reporte de globales generado correctamente.`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo
			});
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.log({
				mensaje: `Error al generar PDF del reporte de globales.`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo
			});
			mcLogger.error({
				mensaje: `Error :`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: mcError.descripcion
			});
			mcNotificaciones.error({
				mensaje: mcError.descripcion,
				titulo: mcError.nombre
			});
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Evento que se ejecuta al presionar enter en el input del indiviso.
	 * - ***evento*** - Evento que ejecuta la función.
	 */
	const eventoIndivisoEnter = (evento: any) => {
		if (evento.key === 'Enter' || evento.keyCode === 13) {
			eventoCalcularReportedeGlobales();
		}
		if (evento.key === 'Escape' || evento.keyCode === 27) {
			eventoLimpiarIndiviso();
		}
	};

	/**
	 * Evento que limpia el input del buscador.
	 */
	const eventoLimpiarIndiviso = () => {
		const nombreMetodo = 'eventoLimpiarBuscador';
		mcLogger.log({
			mensaje: `Limpiando indiviso`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		indivisoGlobalRef.current.value = null;
		limpiarReporteGlobales(registro as Condominio);
		setMostrarCalculoGlobales(false);
	};

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Condominio')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el condominio, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del condominio?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: seccionRutaSalida });
		history.push(seccionRutaSalida);
	};

	/**
	 * Redirecciona a la ruta de cargado.
	 */
	const eventoCargar = () => {
		const nombreMetodo = 'eventoCargar';
		const ruta = `${seccionRutaCargar}?condominioId=${id}`;
		mcLogger.log({
			mensaje: `Redireccionando a la ruta:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: ruta
		});
		history.push(ruta);
	};

	/**
	 * Redirecciona al usuario a la ruta de edición.
	 */
	const eventoEditarDetalles = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: seccionRutaEditar });
		history.push(seccionRutaEditar);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando el condominio.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		try {
			await eliminarCondominio({ auth0AccessToken, id: registro.id });
			mcNotificaciones.exito({ mensaje: texto('Condominio eliminado con exito.') });
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: seccionRutaSalida });
			history.push(seccionRutaSalida);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del condominio...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerCondominioPorId({ auth0AccessToken, id });
			mcLogger.log({ mensaje: `Información del condominio obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			setRegistro(datos);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el condominio: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los departamentos.
	 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
	 */
	const eventoObtenerDepartamentos = async ({
		criterio,
		orden,
		ordenamiento,
		pagina,
		registrosPorPagina
	}: {
		criterio?: string;
		orden?: string;
		ordenamiento?: string;
		pagina?: number;
		registrosPorPagina?: number;
	}) => {
		const nombreMetodo = 'eventoObtenerDepartamentos';
		try {
			mcLogger.log({ mensaje: `Obteniendo lista de departamentos...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			let respuesta = null;
			respuesta = await obtenerDepartamentosPaginadosPorCondominio({
				auth0AccessToken,
				condominioId: id,
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			});
			mcLogger.log({ mensaje: `Lista de departamentos obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registrosPaginados = respuesta.datos as RegistrosPaginados;
			setRegistrosLista(registrosPaginados.lista);
			setRegistrosPaginaFinal(registrosPaginados.paginaFinal);
			setRegistrosMostrandoFinal(registrosPaginados.registroFinal);
			setRegistrosMostrandoInicial(registrosPaginados.registroInicial);
			setRegistrosMostrandoPorPagina(registrosPaginados.registrosPorPagina);
			setRegistrosTotal(registrosPaginados.total);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la lista de departamentos: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Redirecciona a la ruta de los detalles del registro.
	 * - ***registroId*** - Id del registro seleccionado.
	 */
	const eventoSeleccionarRegistro = ({ registroId }: { registroId: any }) => {
		const nombreMetodo = 'eventoSeleccionarRegistro';
		let ruta = null;
		if (esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionDepartamentosVerDetalles, usuario })) {
			ruta = `${constantes.ruta.appAdministracionDepartamentosDetalles}/${registroId}`;
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
			history.push(ruta);
		} else if (!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appCondominiosVerDetalles, usuario })) {
			ruta = `${constantes.ruta.appDepartamentosDetalles}/${registroId}`;
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
			history.push(ruta);
		} else {
			mcNotificaciones.advertencia({ mensaje: texto('No tienes permiso para acceder a la sección de detalles del avalúo.') });
		}
	};

	/**
	 * Setea los parametros de paginacion.
	 */
	const setearParametroPaginacion = () => {
		if (esSeccionAdministracion()) {
			setCriterio(criterioDepartamentosAdmin);
			setOrden(ordenDepartamentosAdmin);
			setOrdenamiento(ordenamientoDepartamentosAdmin);
			setPagina(paginaDepartamentosAdmin);
			setRegistrosPorPagina(registrosPorPaginaDepartamentosAdmin);
		} else {
			setCriterio(criterioDepartamentos);
			setOrden(ordenDepartamentos);
			setOrdenamiento(ordenamientoDepartamentos);
			setPagina(paginaDepartamentos);
			setRegistrosPorPagina(registrosPorPaginaDepartamentos);
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			const rutaSalida = `${constantes.ruta.appAdministracionCondominiosLista}?pagina=${paginaCondominioAdmin}&registrosPorPagina=${registrosPorPaginaCondominioAdmin}&ordenamiento=${ordenamientoCondominioAdmin}&orden=${ordenCondominioAdmin}&criterio=${criterioCondominioAdmin}`;
			setSeccionPermisoGenerarReporte(constantes.permiso.appAdministracionCondominiosGenerarReporteGlobales);
			setSeccionPermisoCargarExcel(constantes.permiso.appAdministracionDepartamentosCargarExcel);
			setSeccionPermisoCrearRegistro(constantes.permiso.appAdministracionDepartamentosCrear);
			setSeccionPermisoEditarRegistro(constantes.permiso.appAdministracionCondominiosEditarDetalles);
			setSeccionPermisoEliminarRegistro(constantes.permiso.appAdministracionCondominiosEliminar);
			setSeccionIcono(constantes.icono.condominio);
			setSeccionTitulo(texto('Detalles del condominio'));
			setSeccionRutaCargar(constantes.ruta.appAdministracionDepartamentosCargar);
			setSeccionRutaCreacion(constantes.ruta.appAdministracionDepartamentosNuevo);
			setSeccionRutaEditar(`${constantes.ruta.appAdministracionCondominiosDetallesEditar}/${id}`);
			setSeccionRutaSalida(rutaSalida);
			setSeccionEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de condominios') }
			]);
			setTablaEncabezados([
				{
					atributo: 'unidadPrivativa',
					campo: 'unidadPrivativa',
					claseCelda: 'text-center',
					ordenamiento: [['unidadPrivativa']],
					titulo: texto('Unidad Privativa')
				},
				{
					atributo: 'subpredio',
					campo: 'subpredio',
					claseCelda: 'text-center',
					ordenamiento: [['subpredio']],
					titulo: texto('Subpredio')
				},
				{
					atributo: 'cuentaPredial',
					campo: 'cuentaPredial',
					claseCelda: 'text-center',
					ordenamiento: [['cuentaPredial']],
					titulo: texto('Cuenta Predial')
				},
				{
					atributo: 'areaTerreno',
					campo: 'areaTerreno',
					claseCelda: 'text-center',
					ordenamiento: [['areaTerreno']],
					titulo: texto('Area de Terreno')
				},
				{
					atributo: 'indivisoSimple',
					campo: 'indivisoSimple',
					claseCelda: 'text-center',
					ordenamiento: [['indivisoSimple']],
					titulo: texto('Indiviso Simple')
				},
				{
					atributo: 'indivisoCompuesto',
					campo: 'indivisoCompuesto',
					claseCelda: 'text-center',
					ordenamiento: [['indivisoCompuesto']],
					titulo: texto('Indiviso Compuesto')
				}
			]);
		} else {
			const rutaSalida = `${constantes.ruta.appCondominiosLista}?pagina=${paginaCondominio}&registrosPorPagina=${registrosPorPaginaCondominio}&ordenamiento=${ordenamientoCondominio}&orden=${ordenCondominio}&criterio=${criterioCondominio}`;
			setSeccionPermisoGenerarReporte(constantes.permiso.appCondominiosGenerarReporteGlobales);
			setSeccionPermisoCargarExcel(constantes.permiso.appDepartamentosCargarExcel);
			setSeccionPermisoCrearRegistro(constantes.permiso.appDepartamentosCrear);
			setSeccionPermisoEditarRegistro(constantes.permiso.appCondominiosEditarDetalles);
			setSeccionPermisoEliminarRegistro(constantes.permiso.appCondominiosEliminar);
			setSeccionIcono(constantes.icono.condominio);
			setSeccionTitulo(texto('Detalles de mi condominio'));
			setSeccionRutaCargar(constantes.ruta.appDepartamentosCargar);
			setSeccionRutaCreacion(constantes.ruta.appDepartamentosNuevo);
			setSeccionRutaEditar(`${constantes.ruta.appCondominiosDetallesEditar}/${id}`);
			setSeccionRutaSalida(rutaSalida);
			setSeccionEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mis condominio') }
			]);
			setTablaEncabezados([
				{
					atributo: 'unidadPrivativa',
					campo: 'unidadPrivativa',
					claseCelda: 'text-center',
					ordenamiento: [['unidadPrivativa']],
					titulo: texto('Unidad Privativa')
				},
				{
					atributo: 'subpredio',
					campo: 'subpredio',
					claseCelda: 'text-center',
					ordenamiento: [['subpredio']],
					titulo: texto('Subpredio')
				},
				{
					atributo: 'cuentaPredial',
					campo: 'cuentaPredial',
					claseCelda: 'text-center',
					ordenamiento: [['cuentaPredial']],
					titulo: texto('Cuenta Predial')
				},
				{
					atributo: 'areaTerreno',
					campo: 'areaTerreno',
					claseCelda: 'text-center',
					ordenamiento: [['areaTerreno']],
					titulo: texto('Area de Terreno')
				},
				{
					atributo: 'indivisoSimple',
					campo: 'indivisoSimple',
					claseCelda: 'text-center',
					ordenamiento: [['indivisoSimple']],
					titulo: texto('Indiviso Simple')
				},
				{
					atributo: 'indivisoCompuesto',
					campo: 'indivisoCompuesto',
					claseCelda: 'text-center',
					ordenamiento: [['indivisoCompuesto']],
					titulo: texto('Indiviso Compuesto')
				}
			]);
		}
	};

	/**
	 * Valida si se puede generar el reporte de globales.
	 */
	const sePuedenCalcularGlobales = () => {
		if (registro?.clasificacionesGlobales?.length === 0) {
			return false;
		}
		if (!tieneValor(indivisoGlobalRef.current)) {
			return false;
		}
		if (!tieneValor(registro?.superficie)) {
			return false;
		}
		if (!tieneValor(registro?.valorTerreno)) {
			return false;
		}
		if (mostrarCalculoGlobales) {
			return false;
		}
		return true;
	};

	/**
	 * Redirecciona a la ruta de creación.
	 */
	const eventoAgregar = () => {
		const nombreMetodo = 'eventoAgregar';
		const ruta = `${seccionRutaCreacion}?condominioId=${id}`;
		mcLogger.log({
			mensaje: `Redireccionando a la ruta:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: ruta
		});
		history.push(ruta);
	};

	useEffect(() => {
		if (esSeccionAdministracion()) {
			dispatch(
				setParametrosPaginacionDepartamentosAdminAction({
					criterio: urlCriterio !== null ? urlCriterio : criterioDepartamentosAdmin,
					orden: urlOrden ? urlOrden : ordenDepartamentosAdmin,
					ordenamiento: urlOrdenamiento ? urlOrdenamiento : ordenamientoDepartamentosAdmin,
					pagina: urlPagina ? Number(urlPagina) : paginaDepartamentosAdmin,
					registrosPorPagina: urlRegistrosPorPagina ? Number(urlRegistrosPorPagina) : registrosPorPaginaDepartamentosAdmin
				})
			);
		} else {
			dispatch(
				setParametrosPaginacionDepartamentosAction({
					criterio: urlCriterio !== null ? urlCriterio : criterioDepartamentos,
					orden: urlOrden ? urlOrden : ordenDepartamentos,
					ordenamiento: urlOrdenamiento ? urlOrdenamiento : ordenamientoDepartamentos,
					pagina: urlPagina ? Number(urlPagina) : paginaDepartamentos,
					registrosPorPagina: urlRegistrosPorPagina ? Number(urlRegistrosPorPagina) : registrosPorPaginaDepartamentos
				})
			);
		}
	}, [urlCriterio, urlOrden, urlOrdenamiento, urlPagina, urlRegistrosPorPagina]);

	useEffect(() => {
		setearParametroPaginacion();
	}, [criterioDepartamentosAdmin, ordenDepartamentosAdmin, ordenamientoDepartamentosAdmin, paginaDepartamentosAdmin, registrosPorPaginaDepartamentosAdmin]);

	useEffect(() => {
		setearParametroPaginacion();
	}, [criterioDepartamentos, ordenDepartamentos, ordenamientoDepartamentos, paginaDepartamentos, registrosPorPaginaDepartamentos]);

	useEffect(() => {
		eventoObtenerDepartamentos({
			criterio,
			orden,
			ordenamiento,
			pagina,
			registrosPorPagina
		});
	}, [location, criterio, orden, ordenamiento, pagina, registrosPorPagina]);

	useEffect(() => {
		obtenerRegistro();
		obtenerVariablesDeSeccion();
	}, []);

	useEffect(() => {
		setearParametroPaginacion();
		obtenerVariablesDeSeccion();
	}, [location]);

	return (
		<Contenedor>
			<Breadcrumb enlaces={seccionEnlaces} icono={seccionIcono} titulo={seccionTitulo} />
			<Card>
				<CardHeader>
					<BarraHerramientas>
						{tienePermiso({ permiso: seccionPermisoEliminarRegistro, usuario }) && (
							<button
								className="btn btn-outline-danger"
								id="botonEliminarAvaluo"
								onClick={() => {
									setModalEliminar(true);
								}}
								type="button"
							>
								<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
							</button>
						)}
						{dibujarModalEliminar()}
						{tienePermiso({ permiso: seccionPermisoEditarRegistro, usuario }) && (
							<button className="btn btn-info" id="botonEditarCondominio" onClick={eventoEditarDetalles} type="button">
								<i className={constantes.icono.editar}></i> {texto('Editar detalles')}
							</button>
						)}
						<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
							<i className={constantes.icono.atras}></i> {texto('Salir')}
						</button>
					</BarraHerramientas>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="12">
							<CondominioTarjeta condominio={registro as Condominio} indiviso={indivisoGlobalRef.current?.value} mostrarCalculo={mostrarCalculoGlobales} />
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<BarraHerramientas>
								{tienePermiso({
									permiso: seccionPermisoCargarExcel,
									usuario
								}) && (
									<div className="">
										<button className="btn btn-outline-success" id="botonCargarExcel" onClick={eventoCargar} type="button">
											<i className={constantes.icono.archivoExcel}></i> {texto('Cargar Departamentos')}
										</button>
									</div>
								)}
								{tienePermiso({
									permiso: seccionPermisoCrearRegistro,
									usuario
								}) && (
									<div className="">
										<button className="btn btn-success" id="botonCrearDepartamento" onClick={eventoAgregar} type="button">
											<i className={constantes.icono.agregar}></i> {texto('Agregar Departamento')}
										</button>
									</div>
								)}
							</BarraHerramientas>
						</Col>
						<Col md="6">
							<Row className="mt-5">
								<Col className="text-end" md="12">
									{dibujarAtributo({
										etiqueta: texto('Valor de las Construcciones'),
										valor: registro?.globalesValorConstrucciones ? moneda(registro?.globalesValorConstrucciones) : moneda(0)
									})}
								</Col>
								<Col className="text-end" md="12">
									{dibujarAtributo({
										etiqueta: texto('Valor del Terreno'),
										valor: registro?.globalesValorTerreno ? moneda(registro?.globalesValorTerreno) : moneda(0)
									})}
								</Col>
								<Col className="text-end" md="12">
									{dibujarAtributo({
										etiqueta: texto('Valor Total'),
										valor: registro?.globalesValorTotal ? moneda(registro?.globalesValorTotal) : moneda(0)
									})}
								</Col>
							</Row>
							<BarraHerramientas>
								{mostrarCalculoGlobales &&
									tienePermiso({
										permiso: seccionPermisoGenerarReporte,
										usuario
									}) && (
										<div className="">
											<button className="btn btn-secondary" id="botonGenerarReporte" onClick={() => eventoGenerarPdf(false)} type="button">
												<i className={constantes.icono.archivoPdf}></i> {texto('Generar Reporte')}
											</button>
										</div>
									)}
								{mostrarCalculoGlobales &&
									tienePermiso({
										permiso: seccionPermisoGenerarReporte,
										usuario
									}) && (
										<div className="">
											<button
												className="btn btn-dark"
												id="botonDescargarReporte"
												onClick={() => {
													eventoGenerarPdf(true);
												}}
												type="button"
											>
												<i className={constantes.icono.descargar}></i> {texto('Descargar Reporte')}
											</button>
										</div>
									)}
								{mostrarCalculoGlobales && (
									<div className="">
										<button className="btn btn-outline-danger" id="botonLimpiar" onClick={eventoLimpiarIndiviso} type="button">
											<i className={constantes.icono.limpiar}></i> {texto('Limpiar')}
										</button>
									</div>
								)}
								<div style={{ marginLeft: '5px' }}>
									<label className="form-label" htmlFor="indivisoGlobal">
										{texto('Indiviso')}
									</label>
									<input className="form-control" disabled={mostrarCalculoGlobales} id="indivisoGlobal" onKeyDown={eventoIndivisoEnter} ref={indivisoGlobalRef} type="number" />
								</div>
								<div className="">
									<button className="btn btn-primary" disabled={!sePuedenCalcularGlobales()} id="botonCalcularGlobales" onClick={eventoCalcularReportedeGlobales} type="button">
										<i className={constantes.icono.calcular}></i> {texto('Calcular Globales')}
									</button>
								</div>
							</BarraHerramientas>
						</Col>
					</Row>
					<Row>
						<h3>{texto('Lista de Departamentos')} </h3>
					</Row>
					<Row>
						<Col md="12">
							<McTablaPaginada
								eventoCambiarOrdenamiento={({ orden, ordenamiento }) =>
									tablaPaginadaEventoCambiarOrdenamiento({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarPaginaActual={(pagina) =>
									tablaPaginadaEventoCambiarPaginaActual({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarParametrosPaginacion={({ criterio, orden, ordenamiento, pagina, registrosPorPagina }) =>
									tablaPaginadaEventoCambiarParametrosPaginacion({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarRegistrosPorPagina={(registrosPorPagina) =>
									tablaPaginadaEventoCambiarRegistrosPorPagina({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, registrosPorPagina })
								}
								eventoSeleccionarRegistro={eventoSeleccionarRegistro}
								registrosCriterio={criterio}
								registrosEncabezados={tablaEncabezados}
								registrosLista={registrosLista}
								registrosMostrandoFinal={registrosMostrandoFinal}
								registrosMostrandoInicial={registrosMostrandoInicial}
								registrosMostrandoPorPagina={registrosMostrandoPorPagina}
								registrosMostrandoPorPaginaOpciones={registrosMostrandoPorPaginaOpciones}
								registrosOrden={orden}
								registrosOrdenamiento={ordenamiento}
								registrosPaginaActual={pagina}
								registrosPaginaFinal={registrosPaginaFinal}
								registrosTotal={registrosTotal}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
