export const SET_PARAMETROS_PAGINACION_AVALUOS = 'SET_PARAMETROS_PAGINACION_AVALUOS';
export const SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN = 'SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN';
export const SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION = 'SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION';
export const SET_PARAMETROS_PAGINACION_CLASIFICACIONES = 'SET_PARAMETROS_PAGINACION_CLASIFICACIONES';
export const SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA = 'SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA';
export const SET_PARAMETROS_PAGINACION_CONDOMINIOS = 'SET_PARAMETROS_PAGINACION_CONDOMINIOS';
export const SET_PARAMETROS_PAGINACION_CONDOMINIOS_ADMIN = 'SET_PARAMETROS_PAGINACION_CONDOMINIOS_ADMIN';
export const SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES = 'SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES';
export const SET_PARAMETROS_PAGINACION_DEPARTAMENTOS = 'SET_PARAMETROS_PAGINACION_DEPARTAMENTOS';
export const SET_PARAMETROS_PAGINACION_DEPARTAMENTOS_ADMIN = 'SET_PARAMETROS_PAGINACION_DEPARTAMENTOS_ADMIN';
export const SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS = 'SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS';
export const SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION = 'SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION';
export const SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION = 'SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION';
export const SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE = 'SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE';
export const SET_PARAMETROS_PAGINACION_NOTIFICACIONES = 'SET_PARAMETROS_PAGINACION_NOTIFICACIONES';
export const SET_PARAMETROS_PAGINACION_PERITOS = 'SET_PARAMETROS_PAGINACION_PERITOS';
export const SET_PARAMETROS_PAGINACION_PERITOS_ADMIN = 'SET_PARAMETROS_PAGINACION_PERITOS_ADMIN';
export const SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD = 'SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD';
export const SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES = 'SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES';
export const SET_PARAMETROS_PAGINACION_TIPOS_CALLE = 'SET_PARAMETROS_PAGINACION_TIPOS_CALLE';
export const SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION = 'SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION';
export const SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE = 'SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE';
export const SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE = 'SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE';
export const SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS = 'SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS';
export const SET_PARAMETROS_PAGINACION_USUARIOS = 'SET_PARAMETROS_PAGINACION_USUARIOS';
export const SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES = 'SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES';
export const SET_PARAMETROS_PAGINACION_USUARIO_ROLES = 'SET_PARAMETROS_PAGINACION_USUARIO_ROLES';
export const SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES = 'SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES';
