import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import Clasificacion from 'modelo/Clasificacion';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/api/clasificaciones';

/**
 * Actualiza un clasificacion existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del clasificacion a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarClasificacion = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: Clasificacion }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarClasificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.clasificaciones}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un clasificacion en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del clasificacion a crear.
 */
export const crearClasificacion = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Clasificacion }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearClasificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina un clasificacion de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del clasificacion a eliminar.
 */
export const eliminarClasificacion = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarClasificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.clasificaciones}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un clasificacion específico de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del clasificacion a obtener.
 */
export const obtenerClasificacionPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerClasificacionPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los clasificaciones y las separa en páginas según los parámetros recibidos y obtiene la lista de clasificaciones de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerClasificacionesPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerClasificacionesPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la lista de todos los clasificaciones de un estado de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerClasificacionesTodos = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerClasificacionesTodos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}/todos
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros encontrados:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Válida si el valor de un campo único está disponible.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***campo*** - Nombre del campo a validar.
 * - ***id*** - Id del usuario para permitir actualización.
 * - ***valor*** - Valor del campo a validar.
 */
export const validarClasificacionDisponibilidad = ({
	ano,
	auth0AccessToken,
	codigo,
	id
}: {
	auth0AccessToken: string;
	ano: number;
	codigo: string;
	id: string;
}): Promise<boolean> => {
	const nombreMetodo = 'validarClasificacionDisponibilidad';
	return new Promise((resolve, reject) => {
		const valores = { ano, codigo, id };
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando disponibilidad del codigo '${codigo}' para el año '${ano}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}/validarDisponibilidad
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `El codigo '${codigo}' para el año '${ano}' esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `El codigo '${codigo}' para el año '${ano}' no esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al validar disponibilidad del codigo '${codigo}' para el año '${ano}'.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Válida si la clasificacion puede eliminarse.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del perito a eliminar.
 */
export const validarClasificacionEliminacion = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<boolean> => {
	const nombreMetodo = 'validarPeritoEliminacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando si es posible eliminar la clasificacion '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.clasificaciones}/validarEliminacion/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `La clasificacion '${id}' puede ser eliminado.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `La clasificacion '${id}' no puede ser eliminado.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al validar la eliminación de la clasificacion '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
