import Condominio from '../modelo/Condominio';
import CondominioClasificacion from '../modelo/CondominioClasificacion';
import Departamento from '../modelo/Departamento';
import generarContenidoPdfGlobales from '../util/pdf/reportes/globales';
import generarContenidoPdfPrivativas from '../util/pdf/reportes/privativas';
import { tieneValor } from '../@mcsoft/validaciones';

/**
 * Genera el contenido del PDF del reporte de globales.
 */
export const useGenerarReporteGlobalesPdfContenido =
	() =>
	async ({ condominio, indiviso }: { condominio?: Condominio; indiviso: number }) => {
		if (condominio) {
			let contenidoPdf = null;
			const fecha = new Date();
			const dia = fecha.getDate().toString().padStart(2, '0');
			const mes = fecha.getMonth().toString().padStart(2, '0');
			const ano = fecha.getFullYear().toString().padStart(2, '0');
			const tituloPdf = `Reporte Globales ${condominio?.nombre} con indiviso del ${indiviso}% (${ano}-${mes}-${dia}).pdf`;
			contenidoPdf = await generarContenidoPdfGlobales({
				condominio: condominio,
				indiviso,
				tituloPdf
			});
			return { contenido: contenidoPdf, titulo: tituloPdf };
		} else {
			const errorMensaje = `El condominio no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};

/**
 * Genera el contenido del PDF del reporte de privativas.
 */
export const useGenerarReportePrivativasPdfContenido =
	() =>
	async ({ departamento }: { departamento?: Departamento }) => {
		if (departamento) {
			let contenidoPdf = null;
			const fecha = new Date();
			const dia = fecha.getDate().toString().padStart(2, '0');
			const mes = fecha.getMonth().toString().padStart(2, '0');
			const ano = fecha.getFullYear().toString().padStart(2, '0');
			const tituloPdf = `Reporte Privativas ${departamento.condominio?.nombre} - ${departamento.unidadPrivativa} - ${departamento.subpredio} (${ano}-${mes}-${dia}).pdf`;
			contenidoPdf = await generarContenidoPdfPrivativas({
				departamento: departamento,
				tituloPdf
			});
			return { contenido: contenidoPdf, titulo: tituloPdf };
		} else {
			const errorMensaje = `El departamento no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};

/**
 * Limpia el reporte de privativas.
 */
export const useLimpiarReporteGlobales = () => (condominio: Condominio) => {
	condominio.globalesValorConstrucciones = 0;
	condominio.globalesValorTerreno = 0;
	condominio.globalesValorTotal = 0;
};

/**
 * Calcula el reporte de privativas.
 */
export const useObtenerReporteGlobales =
	() =>
	({ condominio, indiviso }: { condominio: Condominio; indiviso: number }) => {
		if (tieneValor(indiviso) && condominio && condominio.superficie && condominio.valorTerreno) {
			let sumaConstrucciones = 0;
			let construccionSuperficieTotal = 0;
			const terrenoGlobal = condominio.superficie * condominio.valorTerreno * (indiviso / 100);
			condominio?.clasificacionesGlobales?.forEach((condominioClasificacion: CondominioClasificacion) => {
				construccionSuperficieTotal = construccionSuperficieTotal + Number(condominioClasificacion.superficie);
				let valorConstruccion = condominioClasificacion.superficie * condominioClasificacion.clasificacion.valor * (indiviso / 100);
				if (condominioClasificacion.incremento > 0) {
					valorConstruccion = valorConstruccion * (condominioClasificacion.incremento / 100);
				}
				condominioClasificacion.valorConstruccion = valorConstruccion;
				sumaConstrucciones = sumaConstrucciones + valorConstruccion;
			});
			condominio.globalesConstruccionesSuperficieTotal = construccionSuperficieTotal;
			condominio.globalesValorConstrucciones = sumaConstrucciones;
			condominio.globalesValorTerreno = terrenoGlobal;
			condominio.globalesValorTotal = sumaConstrucciones + terrenoGlobal;
		}
	};

/**
 * Calcula el reporte de privativas.
 */
export const useObtenerReportePrivativas = () => (departamento: Departamento) => {
	if (departamento && departamento.condominio) {
		let terreno = 0;
		let construccionPrivativa = 0;
		let construccionComun1 = 0;
		let construccionComun2 = 0;
		let construccionComun3 = 0;
		let construccionComun4 = 0;
		let construccionComun5 = 0;
		let construccionTotal = 0;
		let construccionSuperficieTotal = 0;
		if (departamento.condominio && departamento.condominio.valorTerreno) {
			terreno = departamento.condominio.valorTerreno * departamento?.areaTerreno;
		}
		if (departamento.condominio.clasificacionConstruccionPrivativa && departamento.condominio.incrementoConstruccionPrivativa && departamento.construccionPrivativa) {
			construccionPrivativa = departamento.condominio.clasificacionConstruccionPrivativa.valor * departamento.construccionPrivativa;
			if (departamento.condominio.incrementoConstruccionPrivativa > 0) {
				construccionPrivativa = construccionPrivativa * ((Number(departamento.condominio.incrementoConstruccionPrivativa) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionPrivativa);
		}
		if (departamento.condominio.clasificacionConstruccionComun1 && departamento.condominio.incrementoConstruccionComun1 && departamento.construccionComun1) {
			construccionComun1 = departamento.condominio.clasificacionConstruccionComun1.valor * departamento.construccionComun1;
			if (departamento.condominio.incrementoConstruccionComun1 > 0) {
				construccionComun1 = construccionComun1 * ((Number(departamento.condominio.incrementoConstruccionComun1) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionComun1);
		}
		if (departamento.condominio.clasificacionConstruccionComun2 && departamento.condominio.incrementoConstruccionComun2 && departamento.construccionComun2) {
			construccionComun2 = departamento.condominio.clasificacionConstruccionComun2.valor * departamento.construccionComun2;
			if (departamento.condominio.incrementoConstruccionComun2 > 0) {
				construccionComun2 = construccionComun2 * ((Number(departamento.condominio.incrementoConstruccionComun2) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionComun2);
		}
		if (departamento.condominio.clasificacionConstruccionComun3 && departamento.condominio.incrementoConstruccionComun3 && departamento.construccionComun3) {
			construccionComun3 = departamento.condominio.clasificacionConstruccionComun3.valor * departamento.construccionComun3;
			if (departamento.condominio.incrementoConstruccionComun3 > 0) {
				construccionComun3 = construccionComun3 * ((Number(departamento.condominio.incrementoConstruccionComun3) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionComun3);
		}
		if (departamento.condominio.clasificacionConstruccionComun4 && departamento.condominio.incrementoConstruccionComun4 && departamento.construccionComun4) {
			construccionComun4 = departamento.condominio.clasificacionConstruccionComun4.valor * departamento.construccionComun4;
			if (departamento.condominio.incrementoConstruccionComun4 > 0) {
				construccionComun4 = construccionComun4 * ((Number(departamento.condominio.incrementoConstruccionComun4) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionComun4);
		}
		if (departamento.condominio.clasificacionConstruccionComun5 && departamento.condominio.incrementoConstruccionComun5 && departamento.construccionComun5) {
			construccionComun5 = departamento.condominio.clasificacionConstruccionComun5.valor * departamento.construccionComun5;
			if (departamento.condominio.incrementoConstruccionComun5 > 0) {
				construccionComun5 = construccionComun5 * ((Number(departamento.condominio.incrementoConstruccionComun5) + 100) / 100);
			}
			construccionSuperficieTotal = construccionSuperficieTotal + Number(departamento.construccionComun5);
		}
		construccionTotal = construccionPrivativa + construccionComun1 + construccionComun2 + construccionComun3 + construccionComun4 + construccionComun5;
		departamento.privativasValorConstruccionComun1 = construccionComun1;
		departamento.privativasValorConstruccionComun2 = construccionComun2;
		departamento.privativasValorConstruccionComun3 = construccionComun3;
		departamento.privativasValorConstruccionComun4 = construccionComun4;
		departamento.privativasValorConstruccionComun5 = construccionComun5;
		departamento.privativasValorConstruccionPrivativa = construccionPrivativa;
		departamento.privativasSuperficieConstruccionTotal = construccionSuperficieTotal;
		departamento.privativasValorConstruccionTotal = construccionTotal;
		departamento.privativasValorFiscal = terreno + construccionTotal;
		departamento.privativasValorTerreno = terreno;
	}
};
