import { actualizarDepartamento, crearDepartamento, obtenerDepartamentoPorId } from 'servicios/api/departamentos';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { mutatorCambiarValorCampo, mutatorCopiarValorCampo, mutatorLimpiarValorCampo } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import Condominio from 'modelo/Condominio';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import Departamento from 'modelo/Departamento';
import DepartamentoFormulario from 'componentes/departamentos/DepartamentoFormulario';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerCondominioPorId } from 'servicios/api/condominios';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { v4 as uuidv4 } from 'uuid';

const NOMBRE_CLASE = 'paginas/app/departamentos/DepartamentosDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un departamento.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const { id } = useParams<any>();
	const condominioId = new URLSearchParams(location.search).get('condominioId');
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const {
		criterio: criterioCondominio,
		orden: ordenCondominio,
		ordenamiento: ordenamientoCondominio,
		pagina: paginaCondominio,
		registrosPorPagina: registrosPorPaginaCondominio
	} = useSelector((state: StateType) => state.parametrosPaginacion.condominios);
	const {
		criterio: criterioCondominioAdmin,
		orden: ordenCondominioAdmin,
		ordenamiento: ordenamientoCondominioAdmin,
		pagina: paginaCondominioAdmin,
		registrosPorPagina: registrosPorPaginaCondominioAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.condominiosAdmin);
	const {
		criterio: criterioDepartamentos,
		orden: ordenDepartamentos,
		ordenamiento: ordenamientoDepartamentos,
		pagina: paginaDepartamentos,
		registrosPorPagina: registrosPorPaginaDepartamentos
	} = useSelector((state: StateType) => state.parametrosPaginacion.departamentos);
	const {
		criterio: criterioDepartamentosAdmin,
		orden: ordenDepartamentosAdmin,
		ordenamiento: ordenamientoDepartamentosAdmin,
		pagina: paginaDepartamentosAdmin,
		registrosPorPagina: registrosPorPaginaDepartamentosAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.departamentosAdmin);
	const [iconooSeccion, setIconoSeccion] = useState<string>('');
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>('');
	const [enlaces, setEnlaces] = useState<any>([]);
	const [formulario, setFormulario] = useState<any>({});
	const [condominio, setCondominio] = useState<Condominio>();

	useEffect(() => {
		obtenerRegistro();
	}, [id]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
	}, [id, condominio, formulario]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del departamento...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const departamento = valores as Departamento;
				await crearDepartamento({ auth0AccessToken, valores: departamento });
				mcLogger.log({ mensaje: `Departamento creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Departamento creado con éxito.`) });
			}
			if (esSeccionEdicion()) {
				const departamento = valores as Departamento;
				await actualizarDepartamento({ auth0AccessToken, id: valores.id, valores: departamento });
				mcLogger.log({ mensaje: `Departamento actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Departamento actualizado con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del departamento:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				if (condominioId) {
					const respuesta = await obtenerCondominioPorId({ auth0AccessToken, id: condominioId });
					setCondominio(respuesta.datos);
					const registro = {
						condominioId,
						id: uuidv4()
					};
					setFormulario(registro);
				}
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerDepartamentoPorId({ auth0AccessToken, id });
				setFormulario(respuesta.datos);
				if (respuesta) {
					const respuestaCondominio = await obtenerCondominioPorId({ auth0AccessToken, id: respuesta.datos.condominioId });
					setCondominio(respuestaCondominio.datos);
				}
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			if (esSeccionAdministracion()) {
				if (condominio) {
					setIconoSeccion(constantes.icono.agregar);
					setTituloSeccion(texto('Crear departamento'));
					const rutaSalida = `${constantes.ruta.appAdministracionCondominiosDetalles}/${condominio?.id}?pagina=${paginaDepartamentosAdmin}&registrosPorPagina=${registrosPorPaginaDepartamentosAdmin}&ordenamiento=${ordenamientoDepartamentosAdmin}&orden=${ordenDepartamentosAdmin}&criterio=${criterioDepartamentosAdmin}`;
					const rutaSalidaLista = `${constantes.ruta.appAdministracionCondominiosLista}?pagina=${paginaCondominioAdmin}&registrosPorPagina=${registrosPorPaginaCondominioAdmin}&ordenamiento=${ordenamientoCondominioAdmin}&orden=${ordenCondominioAdmin}&criterio=${criterioCondominioAdmin}`;
					setRutaSalida(rutaSalida);
					setEnlaces([
						{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
						{ ruta: rutaSalidaLista, titulo: texto('Lista de condominios') },
						{ ruta: rutaSalida, titulo: texto('Detalles del condominio') }
					]);
				}
			} else {
				if (condominio) {
					setIconoSeccion(constantes.icono.agregar);
					setTituloSeccion(texto('Crear departamento'));
					const rutaSalida = `${constantes.ruta.appCondominiosDetalles}/${condominio?.id}?pagina=${paginaDepartamentos}&registrosPorPagina=${registrosPorPaginaDepartamentos}&ordenamiento=${ordenamientoDepartamentos}&orden=${ordenDepartamentos}&criterio=${criterioDepartamentos}`;
					const rutaSalidaLista = `${constantes.ruta.appCondominiosLista}?pagina=${paginaCondominio}&registrosPorPagina=${registrosPorPaginaCondominio}&ordenamiento=${ordenamientoCondominio}&orden=${ordenCondominio}&criterio=${criterioCondominio}`;
					setRutaSalida(rutaSalida);
					setEnlaces([
						{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
						{ ruta: rutaSalidaLista, titulo: texto('Mis condominios') },
						{ ruta: rutaSalida, titulo: texto('Detalles de mi condominio') }
					]);
				}
			}
		}
		if (esSeccionEdicion()) {
			if (esSeccionAdministracion()) {
				if (formulario && condominio) {
					setIconoSeccion(constantes.icono.editar);
					setTituloSeccion(texto('Editar departamento'));
					const rutaSalida = `${constantes.ruta.appAdministracionDepartamentosDetalles}/${id}`;
					const rutaSalidaCondominio = `${constantes.ruta.appAdministracionCondominiosDetalles}/${condominio?.id}?pagina=${paginaDepartamentosAdmin}&registrosPorPagina=${registrosPorPaginaDepartamentosAdmin}&ordenamiento=${ordenamientoDepartamentosAdmin}&orden=${ordenDepartamentosAdmin}&criterio=${criterioDepartamentosAdmin}`;
					const rutaSalidaLista = `${constantes.ruta.appAdministracionCondominiosLista}?pagina=${paginaCondominioAdmin}&registrosPorPagina=${registrosPorPaginaCondominioAdmin}&ordenamiento=${ordenamientoCondominioAdmin}&orden=${ordenCondominioAdmin}&criterio=${criterioCondominioAdmin}`;
					setRutaSalida(rutaSalida);
					setEnlaces([
						{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
						{ ruta: rutaSalidaLista, titulo: texto('Lista de condominios') },
						{ ruta: rutaSalidaCondominio, titulo: texto('Detalles del condominio') },
						{ ruta: rutaSalida, titulo: texto('Detalles del departamento') }
					]);
				}
			} else {
				if (formulario && condominio) {
					setIconoSeccion(constantes.icono.editar);
					setTituloSeccion(texto('Editar mi departamento'));
					const rutaSalida = `${constantes.ruta.appDepartamentosDetalles}/${id}`;
					const rutaSalidaCondominio = `${constantes.ruta.appCondominiosDetalles}/${condominio?.id}?pagina=${paginaDepartamentos}&registrosPorPagina=${registrosPorPaginaDepartamentos}&ordenamiento=${ordenamientoDepartamentos}&orden=${ordenDepartamentos}&criterio=${criterioDepartamentos}`;
					const rutaSalidaLista = `${constantes.ruta.appCondominiosLista}?pagina=${paginaCondominio}&registrosPorPagina=${registrosPorPaginaCondominio}&ordenamiento=${ordenamientoCondominio}&orden=${ordenCondominio}&criterio=${criterioCondominio}`;
					setRutaSalida(rutaSalida);
					setEnlaces([
						{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
						{ ruta: rutaSalidaLista, titulo: texto('Mis condominios') },
						{ ruta: rutaSalidaCondominio, titulo: texto('Detalles de mi condominio') },
						{ ruta: rutaSalida, titulo: texto('Detalles de mi departamento') }
					]);
				}
			}
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={iconooSeccion} titulo={tituloSeccion} />
			{formulario && (
				<Form
					initialValues={formulario}
					mutators={{
						cambiarValorCampo: mutatorCambiarValorCampo,
						copiarValorCampo: mutatorCopiarValorCampo,
						limpiarValorCampo: mutatorLimpiarValorCampo
					}}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardBody>
									<Row>
										<Col md="12">
											<DepartamentoFormulario />
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
