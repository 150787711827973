import Clasificacion from '../../modelo/Clasificacion';
import { McCampoSelectorMultipleOpcion } from '../../@mcsoft/formulario';

/**
 * Formatea una lista de clasificaciones para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de clasificaciones.
 */
export const formatearListaClasificacionesMcCampoSelectorMultiple = (lista?: Array<Clasificacion>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Clasificacion) => {
			listaOpciones.push({
				nombre: `${elemento.codigo} - ${elemento.ano}`,
				valor: elemento.id
			});
		});
	}
	return listaOpciones;
};
