import disenos from './disenos';

// eslint-disable-next-line no-unused-vars
const seccion = ({ paginaActual, paginas }: { paginaActual?: number; paginas?: number }) => [
	{
		layout: disenos.sinPaddingArribaAbajo,
		margin: [0, 0, 0, 0],
		style: 'piePagina',
		table: {
			body: [
				[
					{
						border: [0, 0, 0, 0],
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						margin: [0, 0, 0, 0],
						style: { alignment: 'left' },
						text: `Página ${paginaActual} de ${paginas}`
					},
					{
						border: [0, 0, 0, 0],
						text: ''
					}
				]
			],
			widths: [38, '*', 38]
		}
	}
];

export default seccion;
