/* eslint-disable no-unused-vars */
import { Col, Row } from 'reactstrap';
import CondominioClasificacion from 'modelo/CondominioClasificacion';
import constantes from 'configuracion/constantes';
import { StateType } from 'store';
import useDecimal from 'hooks/useDecimal';
import useMoneda from 'hooks/useMoneda';
import { useSelector } from 'react-redux';

interface CondominioClasificacionTablaRenglonProps {
	/**
	 * Evento que se ejecuta al hacer click sobre el botón eliminar.
	 * - ***indice*** - Indice del registro a eliminar.
	 */
	eventoEliminar?: () => void;
	/**
	 * Indiviso para el calculo del reporte de globales.
	 */
	indiviso?: number;
	/**
	 * Indica si debe mostrarse el calculo del reporte de globales.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	mostrarCalculo?: boolean;
	/**
	 * Objeto con la información del registro.
	 */
	registro: CondominioClasificacion;
}

/**
 * Muestra un renglon de la tabla de clasificaciones globales.
 */
const CondominioClasificacionTablaRenglon = (props: CondominioClasificacionTablaRenglonProps) => {
	// Hooks Personalizados
	const decimal = useDecimal();
	const moneda = useMoneda();
	// Store
	const { usuario } = useSelector((state: StateType) => state.sesion);
	// Props
	const { eventoEliminar, indiviso, mostrarCalculo = false, registro } = props;

	return (
		<Row className="py-2">
			{!mostrarCalculo && (
				<Col className="text-center" md="3">
					<p>{`${decimal(registro.superficie)} m²`}</p>
				</Col>
			)}
			{mostrarCalculo && indiviso && (
				<Col className="text-center" md="2">
					<p>{`${decimal(registro.superficie)} m²`}</p>
				</Col>
			)}
			<Col className="text-center" md="3">
				<p>{`${registro.clasificacion.codigo} - ${registro.clasificacion.ano}`}</p>
			</Col>
			<Col className="text-center" md="3">
				<p>{moneda(registro.clasificacion.valor)}</p>
			</Col>
			{!mostrarCalculo && (
				<Col className="text-center" md="2">
					<p>{`${registro.incremento} %`}</p>
				</Col>
			)}
			{mostrarCalculo && indiviso && (
				<Col className="text-center" md="1">
					<p>{`${registro.incremento} %`}</p>
				</Col>
			)}
			{mostrarCalculo && indiviso && Number(registro.incremento) === 0 && (
				<Col className="text-center" md="3">
					<p>{moneda(registro.superficie * registro.clasificacion.valor * indiviso)}</p>
				</Col>
			)}
			{mostrarCalculo && indiviso && Number(registro.incremento) > 0 && (
				<Col className="text-center" md="3">
					<p>{moneda(registro.superficie * registro.clasificacion.valor * Number(registro.incremento) * indiviso)}</p>
				</Col>
			)}
			{eventoEliminar && (
				<Col className="text-center" md="1">
					<button className="btn btn-danger btn-sm" id="botonEliminarClasificacionGlobal" onClick={eventoEliminar} type="button">
						<i className={constantes.icono.eliminar}></i>
					</button>
				</Col>
			)}
		</Row>
	);
};

export default CondominioClasificacionTablaRenglon;
