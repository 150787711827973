import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosAplicacionProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los departamentos.
 */
const RolFormularioPermisosDepartamentos = (props: RolFormularioPermisosAplicacionProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosVerDetalles}
					etiqueta={texto('Ver los detalles de sus departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionDepartamentosVerDetalles}
					etiqueta={texto('Ver los detalles de todos los departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionDepartamentosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosEditarDetalles}
					etiqueta={texto('Editar los detalles de sus departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionDepartamentosEditarDetalles}
					etiqueta={texto('Editar los detalles de todos los departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionDepartamentosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosCrear}
					etiqueta={texto('Crear departamentos nuevos.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosCargarExcel}
					etiqueta={texto('Cargar departamentos nuevos desde un Excel.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosGenerarReportePrivativas}
					etiqueta={texto('Generar el PDF del reporte de privativas de sus departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosGenerarReportePrivativas}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionDepartamentosGenerarReportePrivativas}
					etiqueta={texto('Generar el PDF del reporte de privativas de todos los departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionDepartamentosGenerarReportePrivativas}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appDepartamentosEliminar}
					etiqueta={texto('Eliminar sus departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appDepartamentosEliminar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionDepartamentosEliminar}
					etiqueta={texto('Eliminar todos los departamentos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionDepartamentosEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosDepartamentos;
