import './BarraHerramientas.scss';
import { Col, Row } from 'reactstrap';
import { ReactNode } from 'react';

interface BarraHerramientasProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Componente contenedor de la barra de herramientas de la página.
 */
const BarraHerramientas = (props: BarraHerramientasProps) => {
	const { children } = props;

	return (
		<Row className="mt-2 mb-2">
			<Col lg="12">
				<div className="barra-herramientas d-flex flex-row justify-content-end align-items-end">{children}</div>
			</Col>
		</Row>
	);
};

export default BarraHerramientas;
