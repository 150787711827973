import {
	SET_PARAMETROS_PAGINACION_AVALUOS,
	SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN,
	SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_CLASIFICACIONES,
	SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA,
	SET_PARAMETROS_PAGINACION_CONDOMINIOS,
	SET_PARAMETROS_PAGINACION_CONDOMINIOS_ADMIN,
	SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES,
	SET_PARAMETROS_PAGINACION_DEPARTAMENTOS,
	SET_PARAMETROS_PAGINACION_DEPARTAMENTOS_ADMIN,
	SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS,
	SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION,
	SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE,
	SET_PARAMETROS_PAGINACION_NOTIFICACIONES,
	SET_PARAMETROS_PAGINACION_PERITOS,
	SET_PARAMETROS_PAGINACION_PERITOS_ADMIN,
	SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD,
	SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES,
	SET_PARAMETROS_PAGINACION_TIPOS_CALLE,
	SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE,
	SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE,
	SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS,
	SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES,
	SET_PARAMETROS_PAGINACION_USUARIO_ROLES,
	SET_PARAMETROS_PAGINACION_USUARIOS,
	SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES
} from './actionTypes';
import { ReduxAccion } from 'store/actions';

/**
 * Asigna los parámetros de paginación de los avalúos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionAvaluosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_AVALUOS
	};
};

/**
 * Asigna los parámetros de paginación de los avalúos administración en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionAvaluosAdminAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN
	};
};

/**
 * Asigna los parámetros de paginación de las calidades de construcción en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionCalidadesContruccionAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION
	};
};

/**
 * Asigna los parámetros de paginación de las clasificaciones de condominios en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionClasificacionesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CLASIFICACIONES
	};
};

/**
 * Asigna los parámetros de paginación de las clasificaciones de zona en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionClasificacionesZonaAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA
	};
};

/**
 * Asigna los parámetros de paginación de los condominios en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionCondominiosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CONDOMINIOS
	};
};

/**
 * Asigna los parámetros de paginación de los condominios en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionCondominiosAdminAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CONDOMINIOS_ADMIN
	};
};

/**
 * Asigna los parámetros de paginación de las construcciones dominantes en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionConstruccionesDominantesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES
	};
};

/**
 * Asigna los parámetros de paginación de los departamentos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionDepartamentosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_DEPARTAMENTOS
	};
};

/**
 * Asigna los parámetros de paginación de los departamentos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionDepartamentosAdminAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_DEPARTAMENTOS_ADMIN
	};
};

/**
 * Asigna los parámetros de paginación de los destinos especificos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionDestinosEspecificosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS
	};
};

/**
 * Asigna los parámetros de paginación de los elementos de construcción en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionElementosConstruccionAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION
	};
};

/**
 * Asigna los parámetros de paginación de los estados de conservación en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionEstadosConservacionAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION
	};
};

/**
 * Asigna los parámetros de paginación de los motivos de ajuste en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionMotivosAjusteAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE
	};
};

/**
 * Asigna los parámetros de paginación de las notificaciones en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionNotificacionesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_NOTIFICACIONES
	};
};

/**
 * Asigna los parámetros de paginación de los peritos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionPeritosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_PERITOS
	};
};

/**
 * Asigna los parámetros de paginación de los peritos administración en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionPeritosAdminAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_PERITOS_ADMIN
	};
};

/**
 * Asigna los parámetros de paginación de los regimenes de propiedad en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionRegimenesPropiedadAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD
	};
};

/**
 * Asigna los parámetros de paginación de los servicios municipales en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionServiciosMunicipalesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES
	};
};

/**
 * Asigna los parámetros de paginación de los tipos de calle en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionTiposCalleAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_TIPOS_CALLE
	};
};

/**
 * Asigna los parámetros de paginación de los tipos de construcción en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionTiposConstruccionAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION
	};
};

/**
 * Asigna los parámetros de paginación de los tipos de inmueble en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionTiposInmuebleAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE
	};
};

/**
 * Asigna los parámetros de paginación de las unidades de superficie en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionUnidadesSuperficieAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE
	};
};

/**
 * Asigna los parámetros de paginación de los usos especificos en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionUsosEspecificosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS
	};
};

/**
 * Asigna los parámetros de paginación de las notificaciones en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionUsuarioNotificacionesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES
	};
};

/**
 * Asigna los parámetros de paginación de los roles en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionUsuarioRolesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_USUARIO_ROLES
	};
};

/**
 * Asigna los parámetros de paginación de los usuarios en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionUsuariosAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_USUARIOS
	};
};

/**
 * Asigna los parámetros de paginación de los vientos cardinales en el store de *Redux*.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const setParametrosPaginacionVientosCardinalesAction = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): ReduxAccion => {
	const parametrosPaginacion = { criterio, orden, ordenamiento, pagina, registrosPorPagina };
	return {
		payload: { parametrosPaginacion },
		type: SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES
	};
};
