interface AlertaProps {
	/**
	 * Clase de *Tailwind*.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	claseTailwind?: string;
	/**
	 * Icono *FontAwesome* que se mostrará.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-triangle-exclamation'*
	 */
	icono?: string;
	/**
	 * Mensaje que se mostrará en la alerta.
	 */
	mensaje: string;
}

export default function Alerta(props: AlertaProps) {
	// Props
	const { icono, mensaje } = props;

	return (
		<div className="alert alert-primary d-flex justify-content-center mt-2">
			{icono && (
				<div className="text-2xl">
					<i className={icono}></i>
				</div>
			)}
			<p>{mensaje}</p>
		</div>
	);
}
