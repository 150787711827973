import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosAplicacionProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los avalúos.
 */
const RolFormularioPermisosCondominios = (props: RolFormularioPermisosAplicacionProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosVerLista}
					etiqueta={texto('Ver la lista de sus condominios.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionCondominiosVerLista}
					etiqueta={texto('Ver la lista de todos los condominios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionCondominiosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosVerDetalles}
					etiqueta={texto('Ver los detalles de sus condominios.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionCondominiosVerDetalles}
					etiqueta={texto('Ver los detalles de todos los condominios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionCondominiosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosEditarDetalles}
					etiqueta={texto('Editar los detalles de sus condominios.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionCondominiosEditarDetalles}
					etiqueta={texto('Editar los detalles de todos los condominios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionCondominiosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosCrear}
					etiqueta={texto('Crear condominios nuevos.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosGenerarReporteGlobales}
					etiqueta={texto('Generar el PDF del reporte de globales de sus condiminios.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosGenerarReporteGlobales}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionCondominiosGenerarReporteGlobales}
					etiqueta={texto('Generar el PDF del reporte de globales de todos los condominios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionCondominiosGenerarReporteGlobales}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appCondominiosEliminar}
					etiqueta={texto('Eliminar sus condominios.')}
					etiquetaLineal
					id={constantes.permiso.appCondominiosEliminar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionCondominiosEliminar}
					etiqueta={texto('Eliminar todos los condominios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionCondominiosEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosCondominios;
