import Condominio from '../../../../modelo/Condominio';
import datosCondominio from './datosCondominio';
import datosConstrucciones from './datosConstrucciones';
import datosTerreno from './datosTerreno';
import encabezado from './encabezado';
import marcoPagina from './marcoPagina';

const pagina = ({ condominio, indiviso }: { condominio: Condominio; indiviso: number }) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ condominio }));
		promesas.push(datosCondominio(condominio));
		promesas.push(datosTerreno(condominio));
		promesas.push(datosConstrucciones({ condominio, indiviso }));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
