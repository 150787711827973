import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import aplicacion from 'configuracion/aplicacion';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import Condominio from 'modelo/Condominio';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { crearDepartamentos } from 'servicios/api/departamentos';
import Departamento from 'modelo/Departamento';
import { leerArchivoExcel } from '@mcsoft/archivos';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerCondominioPorId } from 'servicios/api/condominios';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import useDecimal from 'hooks/useDecimal';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

const NOMBRE_CLASE = 'paginas/app/departamentos/DepartamentosCargar';

/**
 * En esta página se muestra la información detallada un departamento.
 */
const Pagina = () => {
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const dispatch = useDispatch();
	const decimal = useDecimal();
	const inputRef = useRef<any>(null);
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.departamentos);
	const { auth0AccessToken } = auth0;
	const condominioId = new URLSearchParams(location.search).get('condominioId');
	const [seccionEnlaces, setSeccionEnlaces] = useState<Array<any>>([]);
	const [seccionIcono, setSeccionIcono] = useState<string>('');
	const [seccionRutaSalida, setSeccionRutaSalida] = useState<string>('');
	const [seccionTitulo, setSeccionTitulo] = useState<string>('');
	const [condominio, setCondominio] = useState<Condominio>();
	const [registros, setRegistros] = useState<Array<Departamento>>([]);

	/**
	 * Redirecciona al perito a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: seccionRutaSalida });
		history.push(seccionRutaSalida);
	};

	/**
	 * Carga en base de datos los registros leídos.
	 */
	const eventoCargar = async () => {
		const nombreMetodo = 'eventoCargar';
		try {
			mcLogger.api({
				mensaje: `Creando departamentos...`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: registros
			});
			const respuesta = await crearDepartamentos({ auth0AccessToken, valores: registros as any });
			mcLogger.api({
				mensaje: `Departamentos creados con éxito.`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: respuesta.datos
			});
			mcNotificaciones.exito({
				mensaje: texto(`Departamentos creados con éxito.`)
			});
			eventoCancelar();
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({
				mensaje: `Error al crear el departamentos:`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: mcError.descripcion
			});
			mcNotificaciones.error({
				mensaje: mcError.descripcion,
				titulo: mcError.nombre
			});
		}
	};

	/**
	 * Limpia los registros leídos.
	 */
	const eventoLimpiar = () => {
		const nombreMetodo = 'eventoLimpiar';
		mcLogger.log({
			mensaje: `Limpiando los registros leídos:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: registros
		});
		setRegistros([]);
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.type = 'text';
			inputRef.current.type = 'file';
		}
	};

	/**
	 * Obtiene la información del archivo seleccionado y lo guarda en el state local del componente.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoSeleccionarArchivo = (evento: any): void => {
		const nombreMetodo = 'eventoSeleccionarArchivo';
		if (evento.target.files && evento.target.files.length > 0) {
			const [archivoSeleccionado] = evento.target.files;
			const lector = new FileReader();
			lector.readAsDataURL(archivoSeleccionado);
			lector.onload = () => {
				mcLogger.log({
					mensaje: `Leyendo archivo de Excel...`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: archivoSeleccionado.mozFullPath
				});
				const registros: Departamento[] = [];
				const reader = new FileReader();
				reader.readAsArrayBuffer(archivoSeleccionado);
				reader.onload = async () => {
					const buffer = reader.result;
					if (buffer) {
						const resultado = await leerArchivoExcel({
							buffer: buffer as ArrayBuffer,
							numeroHoja: 1
						});
						mcLogger.log({
							mensaje: `Archivo de Excel leido con éxito:`,
							nombreArchivo: NOMBRE_CLASE,
							nombreMetodo,
							objetoExtra: resultado
						});
						for (let i = 2; i < resultado.length; i++) {
							registros.push({
								areaTerreno: Number(resultado[i][6]),
								condominioId: condominioId || '',
								construccionComun1: Number(resultado[i][8]),
								construccionComun2: Number(resultado[i][9]),
								construccionComun3: Number(resultado[i][10]),
								construccionComun4: Number(resultado[i][11]),
								construccionComun5: Number(resultado[i][12]),
								construccionPrivativa: Number(resultado[i][7]),
								cuentaPredial: resultado[i][3],
								id: uuidv4(),
								indivisoCompuesto: Number(resultado[i][5]),
								indivisoSimple: Number(resultado[i][4]),
								subpredio: resultado[i][2],
								unidadPrivativa: resultado[i][1]
							});
						}
						mcLogger.log({
							mensaje: `Registros capturados:`,
							nombreArchivo: NOMBRE_CLASE,
							nombreMetodo,
							objetoExtra: registros
						});
						setRegistros(registros);
					}
				};
			};
			lector.onerror = (error) => {
				mcLogger.error({
					mensaje: `Error al leer el archivo:`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: error
				});
			};
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		if (condominioId) {
			dispatch(setPantallaCargaMostrarAction(true));
			try {
				mcLogger.api({
					mensaje: `Obteniendo condominio con el id ${condominioId}...`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo
				});
				const respuesta = await obtenerCondominioPorId({ auth0AccessToken, id: condominioId });
				mcLogger.api({
					mensaje: `Condominio con el id ${condominioId} obtenido con éxito.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: respuesta.datos
				});
				mcLogger.react({
					mensaje: `setCondominio:`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: respuesta.datos
				});
				setCondominio(respuesta.datos);
			} catch (error: any) {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener el condominio con el id ${condominioId}:`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError.descripcion
				});
				mcNotificaciones.error({
					mensaje: mcError.descripcion,
					titulo: mcError.nombre
				});
			}
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setSeccionIcono(constantes.icono.archivoExcel);
			setSeccionTitulo(texto('Cargar desde Excel'));
			const rutaSalida = `${constantes.ruta.appAdministracionCondominiosDetalles}/${condominioId}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			const rutaSalidaLista = `${constantes.ruta.appAdministracionCondominiosLista}`;
			setSeccionRutaSalida(rutaSalida);
			setSeccionEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalidaLista, titulo: texto('Lista de condominios') },
				{ ruta: rutaSalida, titulo: texto('Detalles del condominio') }
			]);
		} else {
			setSeccionIcono(constantes.icono.archivoExcel);
			setSeccionTitulo(texto('Cargar desde Excel'));
			const rutaSalida = `${constantes.ruta.appCondominiosDetalles}/${condominioId}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			const rutaSalidaLista = `${constantes.ruta.appCondominiosLista}`;
			setSeccionRutaSalida(rutaSalida);
			setSeccionEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalidaLista, titulo: texto('Lista de condominios') },
				{ ruta: rutaSalida, titulo: texto('Detalles de mi condominio') }
			]);
		}
	};

	useEffect(() => {
		obtenerRegistro();
	}, [condominioId]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
	}, [condominioId, condominio]);

	return (
		<Contenedor>
			<Breadcrumb enlaces={seccionEnlaces} icono={seccionIcono} titulo={seccionTitulo} />
			<Card>
				<CardHeader>
					<BarraHerramientas>
						<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
							<i className={constantes.icono.atras}></i> {texto('Salir')}
						</button>
					</BarraHerramientas>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="12">
							<h3>
								<span>{texto('Condominio')}: </span>
								<span className="fw-light">{condominio?.nombre}</span>
							</h3>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<input
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								className="form-control w-100 border border-2 border-dark rounded-3"
								id="formFile"
								onChange={eventoSeleccionarArchivo}
								ref={inputRef}
								type="file"
							/>
						</Col>
					</Row>
					{registros && registros.length === 0 && (
						<Row>
							<Col className="text-center mt-5 mb-3 fs-3" md="12">
								<p>{texto('La estructura del archivo de excel debe ser la siguiente')}</p>
							</Col>
							<Col md="12">
								<img alt="ejemplo-excel" src={aplicacion.tutoriales.ejemploExcelCondominios} width="100%" />
							</Col>
						</Row>
					)}
					{registros && registros.length > 0 && (
						<div className="mt-5">
							<BarraHerramientas>
								<button className="btn btn-outline-danger" id="botonLimpiar" onClick={eventoLimpiar} type="button">
									<i className={constantes.icono.limpiar}></i> {texto('Limpiar')}
								</button>
								<button className="btn btn-success" id="botonGuardar" onClick={eventoCargar} type="button">
									<i className={constantes.icono.subir}></i> {texto('Cargar')}
								</button>
							</BarraHerramientas>
						</div>
					)}
					{registros && registros.length > 0 && (
						<Row className="mt-5">
							<Col className="text-center fw-bold" md="1">
								<p>{texto('U. Privativa')}</p>
							</Col>
							<Col className="text-center fw-bold" md="1">
								<p>{texto('Subpredio')}</p>
							</Col>
							<Col className="text-center fw-bold" md="2">
								<p>{texto('Cuenta Predial')}</p>
							</Col>
							<Col className="text-center fw-bold" md="3">
								<p>{texto('Indiviso Simple')}</p>
							</Col>
							<Col className="text-center fw-bold" md="3">
								<p>{texto('Indiviso Compuesto')}</p>
							</Col>
							<Col className="text-center fw-bold" md="2">
								<p>{texto('Área de Terreno')}</p>
							</Col>
						</Row>
					)}
					{registros &&
						registros?.map((registro, indice) => (
							<Row className="my-2" key={`registro_${indice}_${registro.unidadPrivativa}_${registro.subpredio}`}>
								<Col className="text-center" md="1">
									<p>{registro.unidadPrivativa}</p>
								</Col>
								<Col className="text-center" md="1">
									<p>{registro.subpredio}</p>
								</Col>
								<Col className="text-center" md="2">
									<p>{registro.cuentaPredial}</p>
								</Col>
								<Col className="text-center" md="3">
									<p>{decimal(registro.indivisoSimple)}</p>
								</Col>
								<Col className="text-center" md="3">
									<p>{decimal(registro.indivisoCompuesto)}</p>
								</Col>
								<Col className="text-center" md="2">
									<p>{registro.areaTerreno ? `${decimal(registro.areaTerreno)} m²` : ''}</p>
								</Col>
							</Row>
						))}
					{registros && registros.length > 0 && (
						<div className="mt-5">
							<BarraHerramientas>
								<button className="btn btn-outline-danger" id="botonLimpiar" onClick={eventoLimpiar} type="button">
									<i className={constantes.icono.limpiar}></i> {texto('Limpiar')}
								</button>
								<button className="btn btn-success" id="botonGuardar" onClick={eventoCargar} type="button">
									<i className={constantes.icono.subir}></i> {texto('Cargar')}
								</button>
							</BarraHerramientas>
						</div>
					)}
				</CardBody>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
