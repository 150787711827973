import { Col, Row } from 'reactstrap';
import { McCampoSelectorMultiple, McCampoSelectorMultipleOpcion, McCampoTexto } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Alerta from 'componentes/comun/Alerta';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Clasificacion from 'modelo/Clasificacion';
import CondominioClasificacion from 'modelo/CondominioClasificacion';
import CondominioClasificacionTablaRenglon from './CondominioClasificacionTablaRenglon';
import constantes from 'configuracion/constantes';
import { formatearListaClasificacionesMcCampoSelectorMultiple } from 'util/modelo/clasificaciones';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerClasificacionesTodos } from 'servicios/api/clasificaciones';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tieneValor } from '@mcsoft/validaciones';
import { v4 as uuidv4 } from 'uuid';
import { validarCondominioDisponibilidad } from 'servicios/api/condominios';

const NOMBRE_CLASE = 'paginas/app/componentes/condominios/CondominioFormulario';

/**
 * Formulario para un condominio.
 */
const CondominioFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const formulario = useForm();
	const formularioValores = useFormState();
	const dispatch = useDispatch();
	const { clasificacionGlobalSeleccion, clasificacionesGlobales, id: condominioId, incrementoGlobal, superficieGlobal } = formularioValores.values;
	const [clasificaciones, setClasificaciones] = useState<Array<Clasificacion>>([]);
	const [opcionesClasificacionesPrivativas, setOpcionesClasificacionesPrivativas] = useState<Array<McCampoSelectorMultipleOpcion>>([]);
	const [opcionesClasificacionesGlobales, setOpcionesClasificacionesGlobales] = useState<Array<McCampoSelectorMultipleOpcion>>([]);

	/**
	 * Agrega una clasificación global.
	 */
	const eventoAgregarClasificacionGlobal = () => {
		const nombreMetodo = 'eventoAgregarClasificacionGlobal';
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const clasificacionesGlobalesNuevas = [...clasificacionesGlobales];
		const clasificacionGlobalNueva = {
			clasificacion: clasificaciones.filter((clasificacion) => clasificacion.id === clasificacionGlobalSeleccion.value)[0],
			clasificacionId: clasificacionGlobalSeleccion.value,
			condominioId: condominioId,
			id: uuidv4(),
			incremento: incrementoGlobal,
			superficie: superficieGlobal
		};
		mcLogger.log({
			mensaje: `Agregando una clasificación global...`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: clasificacionGlobalNueva
		});
		clasificacionesGlobalesNuevas.push(clasificacionGlobalNueva);
		cambiarValorCampo('clasificacionesGlobales', clasificacionesGlobalesNuevas);
		limpiarValorCampo('clasificacionGlobalSeleccion');
		limpiarValorCampo('incrementoGlobal');
		limpiarValorCampo('superficieGlobal');
		limpiarValorCampo('valorUnitarioGlobal');
	};

	/**
	 * Elimina una clasificación global.
	 * - ***indice*** - Indice de la clasificación a eliminar.
	 */
	const eventoEliminarClasificacionGlobal = (indice: number) => {
		const nombreMetodo = 'eventoEliminarClasificacionGlobal';
		const { cambiarValorCampo } = formulario.mutators;
		mcLogger.log({
			mensaje: `Eliminando una clasificación global...`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: clasificacionesGlobales[indice]
		});
		const clasificacionesGlobalesNuevas = [...clasificacionesGlobales];
		const clasificacionesEliminadas = clasificacionesGlobalesNuevas.splice(indice, 1);
		const clasificacionEliminada = clasificacionesEliminadas[0].clasificacion;
		const opcionesClasificacionesNuevas = [...opcionesClasificacionesGlobales];
		opcionesClasificacionesNuevas.push({
			nombre: `${clasificacionEliminada.codigo} - ${clasificacionEliminada.ano}`,
			valor: clasificacionEliminada.id
		});
		setOpcionesClasificacionesGlobales(opcionesClasificacionesNuevas);
		cambiarValorCampo('clasificacionesGlobales', clasificacionesGlobalesNuevas);
	};

	/**
	 * Válida el nombre del condominio.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const nombreMetodo = 'validarFormularioNombre';
		const disponible = await validarCondominioDisponibilidad({
			auth0AccessToken,
			campo: 'nombre',
			id: valores.id,
			valor
		});
		if (!disponible) {
			const error = 'Ya existe un condominio con ese nombre';
			mcLogger.dev({
				mensaje: `Validando el nombre del condominio...`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: error
			});
			return error;
		}
	};

	/**
	 * Valida una clasificación global.
	 */
	const validarClasificacionGlobal = () => {
		if (!tieneValor(clasificacionGlobalSeleccion)) {
			return false;
		}
		if (!tieneValor(incrementoGlobal)) {
			return false;
		}
		if (!tieneValor(superficieGlobal)) {
			return false;
		}
		return true;
	};

	/**
	 * Obtiene la lista de clasificaciones.
	 */
	const obtenerListaClasificaciones = async () => {
		const nombreMetodo = 'obtenerListaClasificaciones';
		dispatch(setPantallaCargaMostrarAction(true));
		try {
			mcLogger.api({
				mensaje: `Obteniendo lista de todas las clasificaciones...`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo
			});
			const respuesta = await obtenerClasificacionesTodos(auth0AccessToken);
			mcLogger.api({
				mensaje: `Lista de todas las clasificaciones obtenida con éxito.`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: respuesta.datos
			});
			mcLogger.react({
				mensaje: `setClasificaciones:`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: respuesta.datos
			});
			setClasificaciones(respuesta.datos);
			const listaClasificacionesPrivativas = respuesta.datos;
			const listaClasificacionesGlobales = respuesta.datos;
			const opcionesPrivativas = formatearListaClasificacionesMcCampoSelectorMultiple(listaClasificacionesPrivativas);
			mcLogger.react({
				mensaje: `setOpcionesClasificacionesPrivativas:`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: opcionesPrivativas
			});
			setOpcionesClasificacionesPrivativas(opcionesPrivativas);
			const opcionesGlobales = formatearListaClasificacionesMcCampoSelectorMultiple(listaClasificacionesGlobales);
			mcLogger.react({
				mensaje: `setOpcionesClasificacionesGlobales:`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: opcionesGlobales
			});
			setOpcionesClasificacionesGlobales(opcionesGlobales);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({
				mensaje: `Error al obtener la lista de todas las clasificaciones: `,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: mcError.descripcion
			});
			mcNotificaciones.error({
				mensaje: mcError.descripcion,
				titulo: mcError.nombre
			});
		} finally {
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	useEffect(() => {
		obtenerListaClasificaciones();
	}, []);

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="nombre" longitudMaxima={255} obligatorio />
					</Col>
					<Col md="6">
						<McCampoTexto campo="ubicacion" etiqueta={texto('Ubicación')} id="ubicacion" longitudMaxima={1000} />
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<McCampoTexto campo="claveCatastral" etiqueta={texto('Clave Catastral')} id="claveCatastral" longitudMaxima={50} />
					</Col>
					<Col md="3">
						<McCampoTexto campo="superficie" etiqueta={texto('Superficie')} id="superficie" tipo="numeroNumerico" />
					</Col>
					<Col md="3">
						<McCampoTexto campo="valorTerreno" etiqueta={texto('Valor del Terreno')} id="valorTerreno" tipo="numeroNumerico" />
					</Col>
				</Row>
				<Row>
					<Col xl="6">
						<h3>{texto('Privativas')}</h3>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionPrivativaSeleccion"
									etiqueta={texto('Clasif. Construcción Privativa')}
									id="clasificacionConstruccionPrivativaSeleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto
									campo="incrementoConstruccionPrivativa"
									etiqueta={texto('Incremento Construcción Privativa')}
									id="incrementoConstruccionPrivativa"
									tipo="numeroNumerico"
								/>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionComun1Seleccion"
									etiqueta={texto('Clasif. Construcción Común')}
									id="clasificacionConstruccionComun1Seleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoConstruccionComun1" etiqueta={texto('Incremento Construcción Común')} id="incrementoConstruccionComun1" tipo="numeroNumerico" />
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionComun2Seleccion"
									etiqueta={texto('Clasif. Construcción Común 2')}
									id="clasificacionConstruccionComun2Seleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoConstruccionComun2" etiqueta={texto('Incremento Construcción Común 2')} id="incrementoConstruccionComun2" tipo="numeroNumerico" />
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionComun3Seleccion"
									etiqueta={texto('Clasif. Construcción Común 3')}
									id="clasificacionConstruccionComun3Seleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoConstruccionComun3" etiqueta={texto('Incremento Construcción Común 3')} id="incrementoConstruccionComun3" tipo="numeroNumerico" />
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionComun4Seleccion"
									etiqueta={texto('Clasif. Construcción Común 4')}
									id="clasificacionConstruccionComun4Seleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoConstruccionComun4" etiqueta={texto('Incremento Construcción Común 4')} id="incrementoConstruccionComun4" tipo="numeroNumerico" />
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionConstruccionComun5Seleccion"
									etiqueta={texto('Clasif. Construcción Común 5')}
									id="clasificacionConstruccionComun5Seleccion"
									opciones={opcionesClasificacionesPrivativas}
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoConstruccionComun5" etiqueta={texto('Incremento Construcción Común 5')} id="incrementoConstruccionComun5" tipo="numeroNumerico" />
							</Col>
						</Row>
					</Col>
					<Col xl="6">
						<h3>{texto('Globales')}</h3>
						<Row>
							<Col md="6">
								<McCampoSelectorMultiple
									campo="clasificacionGlobalSeleccion"
									etiqueta={texto('Clasificación')}
									id="clasificacionGlobalSeleccion"
									opciones={opcionesClasificacionesGlobales}
									sinValidacion
								/>
							</Col>
							<Col md="6">
								<McCampoTexto campo="incrementoGlobal" etiqueta={texto('Incremento')} id="incrementoGlobal" sinValidacion tipo="numeroNumerico" />
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<McCampoTexto campo="superficieGlobal" etiqueta={texto('Superficie')} id="superficieGlobal" sinValidacion tipo="numeroNumerico" />
							</Col>
						</Row>
						<BarraHerramientas>
							<button className="btn btn-success" disabled={!validarClasificacionGlobal()} id="botonAgregar" onClick={eventoAgregarClasificacionGlobal} type="button">
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						</BarraHerramientas>
						{clasificacionesGlobales && clasificacionesGlobales.length > 0 && (
							<Row className="py-2 fw-bold">
								<Col className="text-center" md="3">
									<p>{texto('Superficie')}</p>
								</Col>
								<Col className="text-center" md="3">
									<p>{texto('Codigo - Año')}</p>
								</Col>
								<Col className="text-center" md="3">
									<p>{texto('Valor')}</p>
								</Col>
								<Col className="text-center" md="2">
									<p>{texto('Incremento')}</p>
								</Col>
								<Col className="text-center" md="1"></Col>
							</Row>
						)}
						{clasificacionesGlobales && clasificacionesGlobales.length === 0 && <Alerta mensaje="Sin Elementos" />}
						{clasificacionesGlobales &&
							clasificacionesGlobales.map((clasificacionGlobal: CondominioClasificacion, indice: number) => (
								<CondominioClasificacionTablaRenglon
									eventoEliminar={() => eventoEliminarClasificacionGlobal(indice)}
									key={`condominioClasificacion_${indice}`}
									registro={clasificacionGlobal}
								/>
							))}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default CondominioFormulario;
