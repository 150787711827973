import { actualizarCondominio, crearCondominio, obtenerCondominioPorId } from 'servicios/api/condominios';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { formatearCondominioParaBD, formatearCondominioParaFormulario } from 'util/modelo/condominios';
import { mutatorCambiarValorCampo, mutatorCopiarValorCampo, mutatorLimpiarValorCampo } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import Condominio from 'modelo/Condominio';
import CondominioFormulario from 'componentes/condominios/CondominioFormulario';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { v4 as uuidv4 } from 'uuid';

const NOMBRE_CLASE = 'paginas/app/condominios/CondominioDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un condominio.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const { location } = history;
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.condominios);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.condominiosAdmin);
	const [iconoSeccion, setIconoSeccion] = useState<string>('');
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [formulario, setFormulario] = useState<any>();
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appAvaluosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del condominio...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registro = valores as Condominio;
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const condominio = formatearCondominioParaBD(valores);
				await crearCondominio({ auth0AccessToken, valores: condominio });
				mcLogger.log({ mensaje: `Condominio creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Condominio creado con éxito.`) });
			}
			if (esSeccionEdicion()) {
				const condominio = formatearCondominioParaBD(valores);
				await actualizarCondominio({ auth0AccessToken, id: registro.id, valores: condominio });
				mcLogger.log({ mensaje: `Condominio actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Condominio actualizado con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del condominio:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				setFormulario({
					clasificacionesGlobales: [],
					id: uuidv4(),
					usuarioId: usuario.id
				});
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerCondominioPorId({ auth0AccessToken, id });
				const condominio = formatearCondominioParaFormulario(respuesta.datos);
				const formulario = { ...condominio };
				setFormulario(formulario);
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear condominio'));
			setIconoSeccion(constantes.icono.agregar);
			const rutaSalida = `${constantes.ruta.appCondominiosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de condominios') }
			]);
		}
		if (esSeccionEdicion()) {
			if (esSeccionAdministracion()) {
				setTituloSeccion(texto('Editar condominio'));
				setIconoSeccion(constantes.icono.editar);
				const rutaSalida = `${constantes.ruta.appAdministracionCondominiosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appAdministracionCondominiosLista}?pagina=${paginaAdmin}&registrosPorPagina=${registrosPorPaginaAdmin}&ordenamiento=${ordenamientoAdmin}&orden=${ordenAdmin}&criterio=${criterioAdmin}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{
						ruta: rutaSalidaLista,
						titulo: texto('Lista de condominios')
					},
					{ ruta: rutaSalida, titulo: texto('Detalles del condominio') }
				]);
			} else {
				setTituloSeccion(texto('Editar mi condominio'));
				setIconoSeccion(constantes.icono.editar);
				const rutaSalida = `${constantes.ruta.appCondominiosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appCondominiosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{
						ruta: rutaSalidaLista,
						titulo: texto('Mis condominios')
					},
					{ ruta: rutaSalida, titulo: texto('Detalles de mi condominio') }
				]);
			}
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={iconoSeccion} titulo={tituloSeccion} />
			{formulario && (
				<Form
					initialValues={formulario}
					mutators={{
						cambiarValorCampo: mutatorCambiarValorCampo,
						copiarValorCampo: mutatorCopiarValorCampo,
						limpiarValorCampo: mutatorLimpiarValorCampo
					}}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardBody>
									<Row>
										<Col md="12">
											<CondominioFormulario />
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
