const estilos = {
	croquisInformacion: {
		alignment: 'center',
		fontSize: 6
	},
	encabezado: {
		fontSize: 7
	},
	encabezadoEtiqueta: {
		alignment: 'right',
		italics: true
	},
	encabezadoEtiquetaNegritas: {
		alignment: 'right',
		bold: true,
		fontSize: 10
	},
	encabezadoEtiquetaTelefono: {
		alignment: 'right'
	},
	encabezadoTexto: {
		italics: true
	},
	encabezadoTextoFolio: {
		alignment: 'center',
		bold: true,
		color: '#c21313',
		fontSize: 12
	},
	encabezadoTextoNombrePerito: {
		bold: true,
		fontSize: 10,
		italics: true
	},
	encabezadoTextoRoej: {
		alignment: 'center',
		fillColor: '#e6e6e6'
	},
	encabezadoTextoTelefono: {
		bold: true
	},
	encabezadoTitulo: {
		alignment: 'center',
		bold: true,
		fontSize: 9,
		margin: [0, 10, 0, 0]
	},
	piePagina: {
		fontSize: 7
	},
	seccion: {
		fontSize: 8
	},
	seccionEtiqueta: {
		alignment: 'right',
		bold: true
	},
	seccionEtiquetaNormal: {
		alignment: 'right'
	},
	seccionTablaCelda: {
		alignment: 'center',
		fontSize: 9
	},
	seccionTablaCeldaDerecha: {
		alignment: 'right',
		fontSize: 7
	},
	seccionTablaCeldaDerechaNegrita: {
		alignment: 'right',
		bold: true,
		fontSize: 8
	},
	seccionTablaCeldaInformacion: {
		fontSize: 6
	},
	seccionTablaCeldaNegrita: {
		alignment: 'center',
		bold: true,
		fontSize: 9
	},
	seccionTablaEncabezado: {
		alignment: 'center',
		bold: true,
		fontSize: 9
	},
	seccionTitulo: {
		alignment: 'center',
		bold: true,
		fontSize: 8
	}
};

export default estilos;
