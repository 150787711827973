import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import Condominio from 'modelo/Condominio';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/api/condominios';

/**
 * Actualiza un condominio existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del condominio a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarCondominio = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: Condominio }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarCondominio';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.condominios}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un condominio en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del condominio a crear.
 */
export const crearCondominio = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Condominio }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearCondominio';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina un condominio de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del condominio a eliminar.
 */
export const eliminarCondominio = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarCondominio';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.condominios}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un condominio específico de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del condominio a obtener.
 */
export const obtenerCondominioPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCondominioPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los condominios y las separa en páginas según los parámetros recibidos y obtiene la lista de condominios de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerCondominiosPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCondominioesPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los avalúos relacionados a un usuario y los separa en páginas según los parámetros recibidos y obtiene la lista de avalúos de la página especificada.
 * - ***usuarioId*** - Id del usuario relacionado a los avalúos.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerCondominiosPaginadosPorUsuario = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina,
	usuarioId
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
	usuarioId: string;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCondominiosPaginadosPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}/paginados/${usuarioId}
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la lista de todos los condominios de un estado de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerCondominiosTodos = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCondominiosTodos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}/todos
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros encontrados:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Válida si el valor de un campo único está disponible.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***campo*** - Nombre del campo a validar.
 * - ***id*** - Id del usuario para permitir actualización.
 * - ***valor*** - Valor del campo a validar.
 */
export const validarCondominioDisponibilidad = ({ auth0AccessToken, campo, id, valor }: { auth0AccessToken: string; campo: string; id: string; valor: any }): Promise<boolean> => {
	const nombreMetodo = 'validarCondominioDisponibilidad';
	return new Promise((resolve, reject) => {
		const valores = { campo, id, valor };
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando disponibilidad del valor '${valor}' para el campo '${campo}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.condominios}/validarDisponibilidad
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' no esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
