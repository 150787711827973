import 'moment/locale/es-mx';
import Condominio from '../../../../modelo/Condominio';
import CondominioClasificacion from '../../../../modelo/CondominioClasificacion';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const formateador = new Intl.NumberFormat('es-MX', {
	currency: 'MXN',
	style: 'currency'
});

const seccion = ({ condominio, indiviso }: { condominio: Condominio; indiviso: number }) =>
	new Promise((resolve) => {
		if (condominio && indiviso) {
			const { clasificacionesGlobales, globalesConstruccionesSuperficieTotal, globalesValorConstrucciones, globalesValorTotal } = condominio;

			const body = [
				[
					{
						border: [1, 1, 1, 1],
						colSpan: 5,
						fillColor: '#dcdcdc',
						margin: [0, 5, 0, 5],
						style: 'seccionTablaEncabezado',
						text: 'DETALLE DE BLOQUES DE CONSTRUCCIÓN GLOBAL'
					},
					{},
					{},
					{},
					{}
				],
				[
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'CLASIFICACIÓN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'SUPERFICIE'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'VALOR UNITARIO'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'INCREMENTO'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'TOTAL'
					}
				]
			];

			clasificacionesGlobales?.forEach((condominioClasificacion: CondominioClasificacion) => {
				if (
					condominioClasificacion.clasificacion &&
					condominioClasificacion.clasificacion.valor &&
					condominioClasificacion.superficie &&
					condominioClasificacion.incremento &&
					condominioClasificacion.valorConstruccion
				) {
					body.push([
						{
							border: [1, 1, 1, 1],
							margin: [0, 5, 0, 5],
							style: 'seccionTablaCelda',
							text: condominioClasificacion.clasificacion.codigo
						},
						{
							border: [1, 1, 1, 1],
							margin: [0, 5, 0, 5],
							style: 'seccionTablaCelda',
							text: condominioClasificacion.superficie ? `${condominioClasificacion.superficie.toLocaleString('es-MX')} m²` : ''
						},
						{
							border: [1, 1, 1, 1],
							margin: [0, 5, 0, 5],
							style: 'seccionTablaCelda',
							text: condominioClasificacion.clasificacion.valor ? formateador.format(condominioClasificacion.clasificacion.valor) : formateador.format(0)
						},
						{
							border: [1, 1, 1, 1],
							margin: [0, 5, 0, 5],
							style: 'seccionTablaCelda',
							text: condominioClasificacion.incremento ? `${condominioClasificacion.incremento.toLocaleString('es-MX')} %` : ''
						},
						{
							border: [1, 1, 1, 1],
							margin: [0, 5, 0, 5],
							style: 'seccionTablaCelda',
							text: condominioClasificacion.valorConstruccion ? formateador.format(condominioClasificacion.valorConstruccion) : formateador.format(0)
						}
					]);
				}
			});

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 50, 0, 0],
					style: 'seccion',
					table: {
						body: body,
						widths: ['*', '*', '*', '*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'SUPERFICIE DE CONSTRUCCIÓN TOTAL'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: globalesConstruccionesSuperficieTotal ? `${globalesConstruccionesSuperficieTotal.toLocaleString('es-MX')} m²` : ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR TOTAL CONTRUCCIÓN'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: globalesValorConstrucciones ? formateador.format(globalesValorConstrucciones) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 50, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'INDIVISO'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: indiviso ? `${indiviso.toLocaleString('es-MX')} %` : ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR FISCAL'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: globalesValorTotal ? formateador.format(globalesValorTotal) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
