import Condominio from '../../../../modelo/Condominio';
import estilos from './estilos';
import pagina01 from './pagina01';
import piePagina from './piePagina';

const generarContenidoPdf = ({ condominio, indiviso, tituloPdf }: { condominio: Condominio; indiviso: number; tituloPdf: string }): Promise<any> =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(pagina01({ condominio, indiviso }));
		Promise.all(promesas).then((paginas) => {
			const contenidoCompleto = [].concat.apply([], paginas as any);
			const contenido = {
				content: contenidoCompleto,
				footer: function (paginaActual: number, paginas: number) {
					return piePagina({ paginaActual, paginas });
				},
				info: {
					title: tituloPdf
				},
				pageMargins: [38, 35, 38, 60],
				pageOrientation: 'portrait',
				pageSize: 'LETTER',
				styles: estilos
			};
			resolve(contenido);
		});
	});

export default generarContenidoPdf;
