import { Col, Row } from 'reactstrap';
import Alerta from 'componentes/comun/Alerta';
import Condominio from 'modelo/Condominio';
import CondominioClasificacionTablaRenglon from './CondominioClasificacionTablaRenglon';
import { texto } from 'idiomas';
import useDecimal from 'hooks/useDecimal';
import useMoneda from 'hooks/useMoneda';

interface CondominioTarjetaProps {
	/**
	 * Objeto con la información del condominio.
	 */
	condominio: Condominio;
	/**
	 * Indiviso para el calculo del reporte de globales.
	 */
	indiviso?: number;
	/**
	 * Indica si debe mostrarse el calculo del reporte de globales.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	mostrarCalculo?: boolean;
}

/**
 * Muestra la información de un rol.
 */
const CondominioTarjeta = (props: CondominioTarjetaProps) => {
	// Hooks Personalizados
	const decimal = useDecimal();
	const moneda = useMoneda();
	// Props
	const { condominio, indiviso, mostrarCalculo = false } = props;

	/**
	 * Dibuja un atributo del condominio.
	 * - ***etiqueta*** - Etiqueta que del atributo (Se mostrará en pantalla md+).
	 * - ***etiquetaMovil*** - Etiqueta que del atributo (Se mostrará en pantalla sm-).
	 * - ***icono*** - Icono *FontAwesome* que se mostrará antes de la etiqueta.
	 * - ***valor*** - Valor del atributo.
	 */
	const dibujarAtributo = ({ etiqueta, etiquetaMovil, icono, valor }: { etiqueta: string; etiquetaMovil?: string; icono?: string; valor: any }) => {
		const etiquetaMovilFinal = etiquetaMovil || etiqueta;

		return (
			<div className="d-flex flex-row">
				<span className="fw-bold">
					{icono && (
						<div className="mr-2">
							<i className={icono} />
						</div>
					)}
					<span className="lg:hidden flex">{etiquetaMovilFinal}:</span>
					<span className="hidden lg:flex">{etiqueta}:</span>
				</span>
				<span className="fw-light mx-2">
					<span>{valor}</span>
				</span>
			</div>
		);
	};

	return (
		<>
			<Row>
				<h3>{texto('Información')} </h3>
			</Row>
			<Row>
				<Col md="6">
					{dibujarAtributo({
						etiqueta: texto('Nombre'),
						valor: condominio?.nombre
					})}
					{dibujarAtributo({
						etiqueta: texto('Ubicación'),
						valor: condominio?.ubicacion
					})}
				</Col>
				<Col md="6">
					{dibujarAtributo({
						etiqueta: texto('Clave Catastral'),
						valor: condominio?.claveCatastral
					})}
					{dibujarAtributo({
						etiqueta: texto('Superficie'),
						valor: condominio?.superficie ? `${decimal(condominio?.superficie)} m²` : ''
					})}
					{dibujarAtributo({
						etiqueta: texto('Valor del Terreno'),
						valor: condominio?.valorTerreno ? moneda(condominio?.valorTerreno) : ''
					})}
				</Col>
			</Row>
			<Row className="mt-5">
				<Col md="6">
					<h3>{texto('Privativas')}</h3>
					{condominio?.clasificacionConstruccionPrivativa &&
						dibujarAtributo({
							etiqueta: texto('Construcción Privativa'),
							valor: `${condominio.clasificacionConstruccionPrivativa.codigo} - ${condominio.clasificacionConstruccionPrivativa.ano} (${moneda(
								condominio.clasificacionConstruccionPrivativa.valor
							)}) ${condominio.incrementoConstruccionPrivativa ? ` + INC ${condominio.incrementoConstruccionPrivativa} %` : ''}`
						})}
					{condominio?.clasificacionConstruccionComun1 &&
						dibujarAtributo({
							etiqueta: texto('Construcción Común'),
							valor: `${condominio.clasificacionConstruccionComun1.codigo} - ${condominio.clasificacionConstruccionComun1.ano} (${moneda(
								condominio.clasificacionConstruccionComun1.valor
							)}) ${condominio.incrementoConstruccionComun1 ? ` + INC ${condominio.incrementoConstruccionComun1} %` : ''}`
						})}
					{condominio?.clasificacionConstruccionComun2 &&
						dibujarAtributo({
							etiqueta: texto('Construcción Común 2'),
							valor: `${condominio.clasificacionConstruccionComun2.codigo} - ${condominio.clasificacionConstruccionComun2.ano} (${moneda(
								condominio.clasificacionConstruccionComun2.valor
							)}) ${condominio.incrementoConstruccionComun2 ? ` + INC ${condominio.incrementoConstruccionComun2} %` : ''}`
						})}
					{condominio?.clasificacionConstruccionComun3 &&
						dibujarAtributo({
							etiqueta: texto('Construcción Común 3'),
							valor: `${condominio.clasificacionConstruccionComun3.codigo} - ${condominio.clasificacionConstruccionComun3.ano} (${moneda(
								condominio.clasificacionConstruccionComun3.valor
							)}) ${condominio.incrementoConstruccionComun3 ? ` + INC ${condominio.incrementoConstruccionComun3} %` : ''}`
						})}
					{condominio?.clasificacionConstruccionComun4 &&
						dibujarAtributo({
							etiqueta: texto('Construcción Común 4'),
							valor: `${condominio.clasificacionConstruccionComun4.codigo} - ${condominio.clasificacionConstruccionComun4.ano} (${moneda(
								condominio.clasificacionConstruccionComun4.valor
							)}) ${condominio.incrementoConstruccionComun4 ? ` + INC ${condominio.incrementoConstruccionComun4} %` : ''}`
						})}
					{condominio?.clasificacionConstruccionComun5 &&
						dibujarAtributo({
							etiqueta: texto('Construcción Común 5'),
							valor: `${condominio.clasificacionConstruccionComun5.codigo} - ${condominio.clasificacionConstruccionComun5.ano} (${moneda(
								condominio.clasificacionConstruccionComun5.valor
							)}) ${condominio.incrementoConstruccionComun5 ? ` + INC ${condominio.incrementoConstruccionComun5} %` : ''}`
						})}
				</Col>
				<Col md="6">
					<h3>{texto('Globales')} </h3>
					{condominio?.clasificacionesGlobales && condominio?.clasificacionesGlobales.length > 0 && (
						<Row>
							{!mostrarCalculo && (
								<Col className="text-center" md="3">
									<p>{texto('Superficie')}</p>
								</Col>
							)}
							{mostrarCalculo && indiviso && (
								<Col className="text-center" md="2">
									<p>{texto('Superficie')}</p>
								</Col>
							)}
							<Col className="text-center" md="3">
								<p>{texto('Codigo - Año')}</p>
							</Col>
							<Col className="text-center" md="3">
								<p>{texto('Valor Unitario')}</p>
							</Col>
							{!mostrarCalculo && (
								<Col className="text-center" md="2">
									<p>{texto('Incremento')}</p>
								</Col>
							)}
							{mostrarCalculo && indiviso && (
								<Col className="text-center" md="1">
									<p>{texto('Inc.')}</p>
								</Col>
							)}
							{mostrarCalculo && indiviso && (
								<Col className="text-center" md="3">
									<p>{texto('Valor Construcción')}</p>
								</Col>
							)}
						</Row>
					)}
					{condominio?.clasificacionesGlobales && condominio?.clasificacionesGlobales.length === 0 && <Alerta mensaje="Sin Elementos" />}
					{condominio?.clasificacionesGlobales &&
						condominio?.clasificacionesGlobales.map((condominioClasificacion, indice) => (
							<CondominioClasificacionTablaRenglon
								indiviso={indiviso}
								key={`condominioClasificacion_${indice}`}
								mostrarCalculo={mostrarCalculo}
								registro={condominioClasificacion}
							/>
						))}
				</Col>
			</Row>
		</>
	);
};

export default CondominioTarjeta;
