import { getConfiguracionAplicacionAction, getConfiguracionSistemaAction, getSesionAction, setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import { Redirect, Switch } from 'react-router-dom';
import { RutaConAlMenosUnPermiso, RutaConAutenticacion, RutaConPermiso } from 'util/mc-utils/mc-autenticacion';
import { useDispatch, useSelector } from 'react-redux';
import AdministracionConfiguracionAplicacion from 'paginas/app/administracion/AplicacionConfiguracion';
import AdministracionDashboard from 'paginas/app/administracion/Dashboard';
import aplicacion from 'configuracion/aplicacion';
import AvaluoDetalles from 'paginas/app/avaluos/AvaluoDetalles';
import AvaluoDetallesEditar from 'paginas/app/avaluos/AvaluoDetallesEditar';
import AvaluosLista from 'paginas/app/avaluos/AvaluosLista';
import CalidadConstruccionDetallesEditar from 'paginas/app/catalogos/calidadesConstruccion/CalidadConstruccionDetallesEditar';
import CalidadesConstruccionLista from 'paginas/app/catalogos/calidadesConstruccion/CalidadesConstruccionLista';
import ClasificacionesDetallesEditar from 'paginas/app/catalogos/clasificaciones/ClasificacionDetallesEditar';
import ClasificacionesLista from 'paginas/app/catalogos/clasificaciones/ClasificacionesLista';
import ClasificacionesZonaLista from 'paginas/app/catalogos/clasificacionesZona/ClasificacionesZonaLista';
import ClasificacionZonaDetallesEditar from 'paginas/app/catalogos/clasificacionesZona/ClasificacionZonaDetallesEditar';
import CondominioDetalles from 'paginas/app/condominios/CondominioDetalles';
import CondominioDetallesEditar from 'paginas/app/condominios/CondominioDetallesEditar';
import CondominiosLista from 'paginas/app/condominios/CondominiosLista';
import constantes from 'configuracion/constantes';
import ConstruccionDominanteDetallesEditar from 'paginas/app/catalogos/construccionesDominantes/ConstruccionDominanteDetallesEditar';
import ConstruccionesDominantesLista from 'paginas/app/catalogos/construccionesDominantes/ConstruccionesDominantesLista';
import DepartamentoDetalles from 'paginas/app/departamentos/DepartamentoDetalles';
import DepartamentoDetallesEditar from 'paginas/app/departamentos/DepartamentoDetallesEditar';
import DepartamentosCargar from 'paginas/app/departamentos/DepartamentosCargar';
import DestinoEspecificoDetallesEditar from 'paginas/app/catalogos/destinosEspecificos/DestinoEspecificoDetallesEditar';
import DestinosEspecificosLista from 'paginas/app/catalogos/destinosEspecificos/DestinosEspecificosLista';
import ElementoConstruccionDetallesEditar from 'paginas/app/catalogos/elementosConstruccion/ElementoConstruccionDetallesEditar';
import ElementosConstruccionLista from 'paginas/app/catalogos/elementosConstruccion/ElementosConstruccionLista';
import EstadoConservacionDetallesEditar from 'paginas/app/catalogos/estadosConservacion/EstadoConservacionDetallesEditar';
import EstadosConservacionLista from 'paginas/app/catalogos/estadosConservacion/EstadosConservacionLista';
import InicioApp from 'paginas/app/InicioApp';
import ManejadorErrores from 'componentes/comun/ManejadorErrores';
import mcLogger from '@mcsoft/logger';
import McPantallaCarga from '@mcsoft/pantalla-carga';
import McVisorImagen from '@mcsoft/visor-imagen';
import MotivoAjusteDetallesEditar from 'paginas/app/catalogos/motivosAjuste/MotivoAjusteDetallesEditar';
import MotivosAjusteLista from 'paginas/app/catalogos/motivosAjuste/MotivosAjusteLista';
import NotificacionDetallesEditar from 'paginas/app/notificaciones/NotificacionDetallesEditar';
import NotificacionesLista from 'paginas/app/notificaciones/NotificacionesLista';
import PeritoDetalles from 'paginas/app/peritos/PeritoDetalles';
import PeritoDetallesEditar from 'paginas/app/peritos/PeritoDetallesEditar';
import PeritoLicenciaEditar from 'paginas/app/peritos/PeritoLicenciaEditar';
import PeritosLista from 'paginas/app/peritos/PeritosLista';
import RegimenesPropiedadLista from 'paginas/app/catalogos/regimenesPropiedad/RegimenesPropiedadLista';
import RegimenPropiedadDetallesEditar from 'paginas/app/catalogos/regimenesPropiedad/RegimenPropiedadDetallesEditar';
import RolDetalles from 'paginas/app/roles/RolDetalles';
import RolDetallesEditar from 'paginas/app/roles/RolDetallesEditar';
import RolesLista from 'paginas/app/roles/RolesLista';
import ServicioMunicipalDetallesEditar from 'paginas/app/catalogos/serviciosMunicipales/ServicioMunicipalDetallesEditar';
import ServiciosMunicipalesLista from 'paginas/app/catalogos/serviciosMunicipales/ServiciosMunicipalesLista';
import SistemaConfiguracion from 'paginas/app/sistema/SistemaConfiguracion';
import SistemaLogs from 'paginas/app/sistema/SistemaLogs';
import { StateType } from 'store';
import TemaHorizontal from 'componentes/tema-horizontal/';
import TemaVertical from 'componentes/tema-vertical/';
import { texto } from 'idiomas';
import TipoCalleDetallesEditar from 'paginas/app/catalogos/tiposCalle/TipoCalleDetallesEditar';
import TipoConstruccionDetallesEditar from 'paginas/app/catalogos/tiposConstruccion/TipoConstruccionDetallesEditar';
import TipoInmuebleDetallesEditar from 'paginas/app/catalogos/tiposInmueble/TipoInmuebleDetallesEditar';
import TiposCalleLista from 'paginas/app/catalogos/tiposCalle/TiposCalleLista';
import TiposConstruccionLista from 'paginas/app/catalogos/tiposConstruccion/TiposConstruccionLista';
import TiposInmuebleLista from 'paginas/app/catalogos/tiposInmueble/TiposInmuebleLista';
import UnidadesSuperficieLista from 'paginas/app/catalogos/unidadesSuperficie/UnidadesSuperficieLista';
import UnidadSuperficieDetallesEditar from 'paginas/app/catalogos/unidadesSuperficie/UnidadSuperficieDetallesEditar';
import { useEffect } from 'react';
import UsoEspecificoDetallesEditar from 'paginas/app/catalogos/usosEspecificos/UsoEspecificoDetallesEditar';
import UsosEspecificosLista from 'paginas/app/catalogos/usosEspecificos/UsosEspecificosLista';
import UsuarioDetalles from 'paginas/app/usuarios/UsuarioDetalles';
import UsuarioDetallesEditar from 'paginas/app/usuarios/UsuarioDetallesEditar';
import UsuarioFotoEditar from 'paginas/app/usuarios/UsuarioFotoEditar';
import UsuarioNotificaciones from 'paginas/app/usuarios/UsuarioNotificaciones';
import UsuarioPreferencias from 'paginas/app/usuarios/UsuarioPreferencias';
import UsuariosLista from 'paginas/app/usuarios/UsuariosLista';
import VientoCardinalDetallesEditar from 'paginas/app/catalogos/vientosCardinales/VientoCardinalDetallesEditar';
import VientosCardinalesLista from 'paginas/app/catalogos/vientosCardinales/VientosCardinalesLista';

const NOMBRE_CLASE = 'RutasApp';

/**
 * Contiene la estructura principal de la aplicación, la cual será utilizada solo por los usuarios registrados.
 */
const RutasApp = () => {
	const dispatch = useDispatch();
	const { nombreAplicacion } = useSelector((state: StateType) => state.configuracionSistema);
	const { pantallaCarga, sesion, visorImagen } = useSelector((state: StateType) => state);
	const { imagenes: visorImagenImagenes, mostrar: visorImagenMostrar } = visorImagen;
	const { auth0, usuario } = sesion;
	const { preferencias } = usuario;

	useEffect(() => {
		const nombreMetodo = 'useEffect';
		if (auth0) {
			mcLogger.dev({ mensaje: `Obteniendo información de la sesión.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			obtenerInformacionDeSesion();
		}
	}, [auth0]);

	useEffect(() => {
		const nombreMetodo = 'useEffect';
		if (nombreAplicacion) {
			const nombreApp = `${nombreAplicacion} | ${aplicacion.nombreSeccionApp}`;
			mcLogger.dev({ mensaje: `Cambiando nombre de la aplicación [${nombreApp}].`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			document.title = nombreApp;
		}
	}, [nombreAplicacion]);

	/**
	 * Oculta el visor de imagenes.
	 */
	const eventoCerrarVisorImagen = () => {
		dispatch(setVisorImagenImagenesAction([]));
		dispatch(setVisorImagenMostrarAction(false));
	};

	/**
	 * Obtiene la información de sesión del usuario. Si el usuario no existe lo crea.
	 */
	const obtenerInformacionDeSesion = () => {
		if (auth0 && auth0.auth0AccessToken) {
			dispatch(getSesionAction(auth0));
			dispatch(getConfiguracionSistemaAction(auth0.auth0AccessToken));
			dispatch(getConfiguracionAplicacionAction(auth0.auth0AccessToken));
		}
	};

	/**
	 * Obtiene el tema a mostrar.
	 */
	const obtenerTema = () => {
		let layoutCls = TemaVertical;

		switch (preferencias.interfazTema) {
			case 'horizontal':
				layoutCls = TemaHorizontal;
				break;
			default:
				layoutCls = TemaVertical;
				break;
		}
		return layoutCls;
	};

	const Tema = obtenerTema();

	return (
		<ManejadorErrores>
			<McPantallaCarga giroHorizontal={pantallaCarga.giroHorizontal} icono={pantallaCarga.icono} mostrar={pantallaCarga.mostrar} texto={pantallaCarga.texto} />
			<McPantallaCarga
				giroHorizontal={true}
				icono={constantes.icono.autenticando}
				mostrar={!sesion || !sesion.inicializada}
				texto={{
					mensaje: `${texto('Autenticando')}...`
				}}
			/>
			<McVisorImagen eventoCerrar={eventoCerrarVisorImagen} imagenes={visorImagenImagenes} mostrar={visorImagenMostrar} />
			{sesion && sesion.inicializada && (
				<div>
					<Tema>
						<Switch>
							{/* **************************************************************************************************** */}
							{/* 		Ordenados alfabeticamente en orden inverso para el correcto funcionamiento de switch */}
							{/* **************************************************************************************************** */}

							{/* /app/usuario/preferencias/avaluo */}
							<RutaConAutenticacion component={AvaluoDetallesEditar} path={constantes.ruta.appUsuarioPreferenciasAvaluo} />
							{/* /app/usuario/preferencias */}
							<RutaConAutenticacion component={UsuarioPreferencias} path={constantes.ruta.appUsuarioPreferencias} />
							{/* /app/usuario/perfil/foto */}
							<RutaConAutenticacion component={UsuarioFotoEditar} path={constantes.ruta.appUsuarioPerfilFoto} />
							{/* /app/usuario/perfil/editar */}
							<RutaConAutenticacion component={UsuarioDetallesEditar} path={constantes.ruta.appUsuarioPerfilEditar} />
							{/* /app/usuario/perfil */}
							<RutaConAutenticacion component={UsuarioDetalles} path={constantes.ruta.appUsuarioPerfil} />
							{/* /app/usuario/notificaciones */}
							<RutaConAutenticacion component={UsuarioNotificaciones} path={constantes.ruta.appUsuarioNotificaciones} />

							{/* /app/sistema/logs */}
							<RutaConPermiso component={SistemaLogs} path={constantes.ruta.appSistemaLogs} permiso={constantes.permiso.sysAdmin} usuario={usuario} />
							{/* /app/sistema/configuracion */}
							<RutaConPermiso component={SistemaConfiguracion} path={constantes.ruta.appSistemaConfiguracion} permiso={constantes.permiso.sysAdmin} usuario={usuario} />

							{/* /app/peritos/nuevo */}
							<RutaConPermiso component={PeritoDetallesEditar} path={constantes.ruta.appPeritosNuevo} permiso={constantes.permiso.appPeritosCrear} usuario={usuario} />
							{/* /app/peritos/licencia/editar/:id */}
							<RutaConPermiso
								component={PeritoLicenciaEditar}
								path={`${constantes.ruta.appPeritosLicenciaEditar}/:id`}
								permiso={constantes.permiso.appPeritosEditarLicencia}
								usuario={usuario}
							/>
							{/* /app/peritos/detalles/editar/:id */}
							<RutaConPermiso
								component={PeritoDetallesEditar}
								path={`${constantes.ruta.appPeritosDetallesEditar}/:id`}
								permiso={constantes.permiso.appPeritosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/peritos/detalles/:id */}
							<RutaConPermiso component={PeritoDetalles} path={`${constantes.ruta.appPeritosDetalles}/:id`} permiso={constantes.permiso.appPeritosVerDetalles} usuario={usuario} />
							{/* /app/peritos */}
							<RutaConPermiso component={PeritosLista} path={constantes.ruta.appPeritosLista} permiso={constantes.permiso.appPeritosVerLista} usuario={usuario} />

							{/* /app/inicio */}
							<RutaConAutenticacion component={InicioApp} path={constantes.ruta.appInicio} />

							{/* /app/departamentos/nuevo */}
							<RutaConPermiso
								component={DepartamentoDetallesEditar}
								path={constantes.ruta.appDepartamentosNuevo}
								permiso={constantes.permiso.appDepartamentosCrear}
								usuario={usuario}
							/>
							{/* /app/departamentos/cargar/:condominioId */}
							<RutaConPermiso
								component={DepartamentosCargar}
								path={`${constantes.ruta.appDepartamentosCargar}`}
								permiso={constantes.permiso.appDepartamentosCargarExcel}
								usuario={usuario}
							/>
							{/* /app/departamentos/detalles/editar/:id */}
							<RutaConPermiso
								component={DepartamentoDetallesEditar}
								path={`${constantes.ruta.appDepartamentosDetallesEditar}/:id`}
								permiso={constantes.permiso.appDepartamentosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/departamentos/detalles/:id */}
							<RutaConPermiso
								component={DepartamentoDetalles}
								path={`${constantes.ruta.appDepartamentosDetalles}/:id`}
								permiso={constantes.permiso.appDepartamentosVerDetalles}
								usuario={usuario}
							/>

							{/* /app/condominios/nuevo */}
							<RutaConPermiso component={CondominioDetallesEditar} path={constantes.ruta.appCondominiosNuevo} permiso={constantes.permiso.appCondominiosCrear} usuario={usuario} />
							{/* /app/condominios/detalles/editar/:id */}
							<RutaConPermiso
								component={CondominioDetallesEditar}
								path={`${constantes.ruta.appCondominiosDetallesEditar}/:id`}
								permiso={constantes.permiso.appCondominiosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/condominios/detalles/:id */}
							<RutaConPermiso
								component={CondominioDetalles}
								path={`${constantes.ruta.appCondominiosDetalles}/:id`}
								permiso={constantes.permiso.appCondominiosVerDetalles}
								usuario={usuario}
							/>
							{/* /app/condominios */}
							<RutaConPermiso component={CondominiosLista} path={constantes.ruta.appCondominiosLista} permiso={constantes.permiso.appCondominiosVerLista} usuario={usuario} />

							{/* /app/avaluos/nuevo */}
							<RutaConPermiso component={AvaluoDetallesEditar} path={constantes.ruta.appAvaluosNuevo} permiso={constantes.permiso.appAvaluosCrear} usuario={usuario} />
							{/* /app/avaluos/detalles/editar/:id */}
							<RutaConPermiso
								component={AvaluoDetallesEditar}
								path={`${constantes.ruta.appAvaluosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAvaluosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/avaluos/detalles/:id */}
							<RutaConPermiso component={AvaluoDetalles} path={`${constantes.ruta.appAvaluosDetalles}/:id`} permiso={constantes.permiso.appAvaluosVerDetalles} usuario={usuario} />
							{/* /app/avaluos */}
							<RutaConPermiso component={AvaluosLista} path={constantes.ruta.appAvaluosLista} permiso={constantes.permiso.appAvaluosVerLista} usuario={usuario} />

							{/* /app/administracion/usuarios/foto/editar/:id */}
							<RutaConPermiso
								component={UsuarioFotoEditar}
								path={`${constantes.ruta.appAdministracionUsuariosFotoEditar}/:id`}
								permiso={constantes.permiso.appAdministracionUsuariosEditarFoto}
								usuario={usuario}
							/>
							{/* /app/administracion/usuarios/detalles/editar/:id */}
							<RutaConAlMenosUnPermiso
								component={UsuarioDetallesEditar}
								path={`${constantes.ruta.appAdministracionUsuariosDetallesEditar}/:id`}
								permisos={[constantes.permiso.appAdministracionUsuariosEditarDetalles, constantes.permiso.appAdministracionRolesAsignar]}
								usuario={usuario}
							/>
							{/* /app/administracion/usuarios/detalles/:id */}
							<RutaConPermiso
								component={UsuarioDetalles}
								path={`${constantes.ruta.appAdministracionUsuariosDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionUsuariosVerDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/usuarios */}
							<RutaConPermiso
								component={UsuariosLista}
								path={constantes.ruta.appAdministracionUsuariosLista}
								permiso={constantes.permiso.appAdministracionUsuariosVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/roles/nuevo */}
							<RutaConPermiso
								component={RolDetallesEditar}
								path={constantes.ruta.appAdministracionRolesNuevo}
								permiso={constantes.permiso.appAdministracionRolesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/roles/detalles/editar/:id */}
							<RutaConAlMenosUnPermiso
								component={RolDetallesEditar}
								path={`${constantes.ruta.appAdministracionRolesDetallesEditar}/:id`}
								permisos={[
									constantes.permiso.appAdministracionRolesEditarDetalles,
									constantes.permiso.appAdministracionRolesEditarPermisosAplicacion,
									constantes.permiso.appAdministracionRolesEditarPermisosRoles,
									constantes.permiso.appAdministracionRolesEditarPermisosUsuarios
								]}
								usuario={usuario}
							/>
							{/* /app/administracion/roles/detalles/:id */}
							<RutaConPermiso
								component={RolDetalles}
								path={`${constantes.ruta.appAdministracionRolesDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionRolesVerDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/roles */}
							<RutaConPermiso
								component={RolesLista}
								path={constantes.ruta.appAdministracionRolesLista}
								permiso={constantes.permiso.appAdministracionRolesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/peritos/licencia/editar/:id */}
							<RutaConPermiso
								component={PeritoLicenciaEditar}
								path={`${constantes.ruta.appAdministracionPeritosLicenciaEditar}/:id`}
								permiso={constantes.permiso.appAdministracionPeritosEditarLicencia}
								usuario={usuario}
							/>
							{/* /app/administracion/peritos/detalles/editar/:id */}
							<RutaConPermiso
								component={PeritoDetallesEditar}
								path={`${constantes.ruta.appAdministracionPeritosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionPeritosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/peritos/detalles/:id */}
							<RutaConPermiso
								component={PeritoDetalles}
								path={`${constantes.ruta.appAdministracionPeritosDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionPeritosVerDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/peritos */}
							<RutaConPermiso
								component={PeritosLista}
								path={constantes.ruta.appAdministracionPeritosLista}
								permiso={constantes.permiso.appAdministracionPeritosVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/notificaciones/nuevo */}
							<RutaConPermiso
								component={NotificacionDetallesEditar}
								path={constantes.ruta.appAdministracionNotificacionesNuevo}
								permiso={constantes.permiso.appAdministracionNotificacionesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/notificaciones/editar/:id */}
							<RutaConPermiso
								component={NotificacionDetallesEditar}
								path={`${constantes.ruta.appAdministracionNotificacionesDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionNotificacionesEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/notificaciones */}
							<RutaConPermiso
								component={NotificacionesLista}
								path={constantes.ruta.appAdministracionNotificacionesLista}
								permiso={constantes.permiso.appAdministracionNotificacionesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/departamentos/nuevo */}
							<RutaConPermiso
								component={DepartamentoDetallesEditar}
								path={`${constantes.ruta.appAdministracionDepartamentosNuevo}/:id`}
								permiso={constantes.permiso.appAdministracionDepartamentosCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/departamentos/cargar/:condominioId */}
							<RutaConPermiso
								component={DepartamentosCargar}
								path={`${constantes.ruta.appAdministracionDepartamentosCargar}`}
								permiso={constantes.permiso.appAdministracionDepartamentosCargarExcel}
								usuario={usuario}
							/>
							{/* /app/administracion/departamentos/detalles/editar/:id */}
							<RutaConPermiso
								component={DepartamentoDetallesEditar}
								path={`${constantes.ruta.appAdministracionDepartamentosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionDepartamentosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/departamentos/detalles/:id */}
							<RutaConPermiso
								component={DepartamentoDetalles}
								path={`${constantes.ruta.appAdministracionDepartamentosDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionDepartamentosVerDetalles}
								usuario={usuario}
							/>

							{/* /app/administracion/dashboard */}
							<RutaConPermiso
								component={AdministracionDashboard}
								path={constantes.ruta.appAdministracionDashboard}
								permiso={constantes.permiso.appAdministracionDashboard}
								usuario={usuario}
							/>

							{/* /app/administracion/configuracion */}
							<RutaConAlMenosUnPermiso
								component={AdministracionConfiguracionAplicacion}
								path={constantes.ruta.appAdministracionConfiguracionAplicacion}
								permisos={[
									constantes.permiso.appAdministracionConfiguracionDatos,
									constantes.permiso.appAdministracionConfiguracionGeneral,
									constantes.permiso.appAdministracionConfiguracionImagenes
								]}
								usuario={usuario}
							/>

							{/* /app/administracion/condominios/detalles/editar/:id */}
							<RutaConPermiso
								component={CondominioDetallesEditar}
								path={`${constantes.ruta.appAdministracionCondominiosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCondominiosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/condominios/detalles/:id */}
							<RutaConPermiso
								component={CondominioDetalles}
								path={`${constantes.ruta.appAdministracionCondominiosDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionCondominiosVerDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/condominios */}
							<RutaConPermiso
								component={CondominiosLista}
								path={constantes.ruta.appAdministracionCondominiosLista}
								permiso={constantes.permiso.appAdministracionCondominiosVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/vientosCardinales/nuevo */}
							<RutaConPermiso
								component={VientoCardinalDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosVientosCardinalesNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosVientosCardinalesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/vientosCardinales/editar/:id */}
							<RutaConPermiso
								component={VientoCardinalDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosVientosCardinalesDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosVientosCardinalesEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/vientosCardinales */}
							<RutaConPermiso
								component={VientosCardinalesLista}
								path={constantes.ruta.appAdministracionCatalogosVientosCardinalesLista}
								permiso={constantes.permiso.appAdministracionCatalogosVientosCardinalesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/usosEspecificos/nuevo */}
							<RutaConPermiso
								component={UsoEspecificoDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosUsosEspecificosNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosUsosEspecificosCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/usosEspecificos/editar/:id */}
							<RutaConPermiso
								component={UsoEspecificoDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosUsosEspecificosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosUsosEspecificosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/usosEspecificos */}
							<RutaConPermiso
								component={UsosEspecificosLista}
								path={constantes.ruta.appAdministracionCatalogosUsosEspecificosLista}
								permiso={constantes.permiso.appAdministracionCatalogosUsosEspecificosVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/unidadesSuperficie/nuevo */}
							<RutaConPermiso
								component={UnidadSuperficieDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosUnidadesSuperficieNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/unidadesSuperficie/editar/:id */}
							<RutaConPermiso
								component={UnidadSuperficieDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosUnidadesSuperficieDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/unidadesSuperficie */}
							<RutaConPermiso
								component={UnidadesSuperficieLista}
								path={constantes.ruta.appAdministracionCatalogosUnidadesSuperficieLista}
								permiso={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/tiposInmueble/nuevo */}
							<RutaConPermiso
								component={TipoInmuebleDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosTiposInmuebleNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosTiposInmuebleCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposInmueble/editar/:id */}
							<RutaConPermiso
								component={TipoInmuebleDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosTiposInmuebleDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosTiposInmuebleEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposInmueble */}
							<RutaConPermiso
								component={TiposInmuebleLista}
								path={constantes.ruta.appAdministracionCatalogosTiposInmuebleLista}
								permiso={constantes.permiso.appAdministracionCatalogosTiposInmuebleVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/tiposConstruccion/nuevo */}
							<RutaConPermiso
								component={TipoConstruccionDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosTiposConstruccionNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosTiposConstruccionCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposConstruccion/editar/:id */}
							<RutaConPermiso
								component={TipoConstruccionDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosTiposConstruccionDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosTiposConstruccionEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposConstruccion */}
							<RutaConPermiso
								component={TiposConstruccionLista}
								path={constantes.ruta.appAdministracionCatalogosTiposConstruccionLista}
								permiso={constantes.permiso.appAdministracionCatalogosTiposConstruccionVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/tiposCalle/nuevo */}
							<RutaConPermiso
								component={TipoCalleDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosTiposCalleNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosTiposCalleCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposCalle/editar/:id */}
							<RutaConPermiso
								component={TipoCalleDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosTiposCalleDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosTiposCalleEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/tiposCalle */}
							<RutaConPermiso
								component={TiposCalleLista}
								path={constantes.ruta.appAdministracionCatalogosTiposCalleLista}
								permiso={constantes.permiso.appAdministracionCatalogosTiposCalleVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/serviciosMunicipales/nuevo */}
							<RutaConPermiso
								component={ServicioMunicipalDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosServiciosMunicipalesNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/serviciosMunicipales/editar/:id */}
							<RutaConPermiso
								component={ServicioMunicipalDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosServiciosMunicipalesDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/serviciosMunicipales */}
							<RutaConPermiso
								component={ServiciosMunicipalesLista}
								path={constantes.ruta.appAdministracionCatalogosServiciosMunicipalesLista}
								permiso={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/regimenesPropiedad/nuevo */}
							<RutaConPermiso
								component={RegimenPropiedadDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosRegimenesPropiedadNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/regimenesPropiedad/editar/:id */}
							<RutaConPermiso
								component={RegimenPropiedadDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosRegimenesPropiedadDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/regimenesPropiedad */}
							<RutaConPermiso
								component={RegimenesPropiedadLista}
								path={constantes.ruta.appAdministracionCatalogosRegimenesPropiedadLista}
								permiso={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/motivosAjuste/nuevo */}
							<RutaConPermiso
								component={MotivoAjusteDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosMotivosAjusteNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosMotivosAjusteCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/motivosAjuste/editar/:id */}
							<RutaConPermiso
								component={MotivoAjusteDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosMotivosAjusteDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosMotivosAjusteEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/motivosAjuste */}
							<RutaConPermiso
								component={MotivosAjusteLista}
								path={constantes.ruta.appAdministracionCatalogosMotivosAjusteLista}
								permiso={constantes.permiso.appAdministracionCatalogosMotivosAjusteVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/estadosConservacion/nuevo */}
							<RutaConPermiso
								component={EstadoConservacionDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosEstadosConservacionNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosEstadosConservacionCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/estadosConservacion/editar/:id */}
							<RutaConPermiso
								component={EstadoConservacionDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosEstadosConservacionDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosEstadosConservacionEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/estadosConservacion */}
							<RutaConPermiso
								component={EstadosConservacionLista}
								path={constantes.ruta.appAdministracionCatalogosEstadosConservacionLista}
								permiso={constantes.permiso.appAdministracionCatalogosEstadosConservacionVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/elementosConstruccion/nuevo */}
							<RutaConPermiso
								component={ElementoConstruccionDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosElementosConstruccionNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosElementosConstruccionCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/elementosConstruccion/editar/:id */}
							<RutaConPermiso
								component={ElementoConstruccionDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosElementosConstruccionDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosElementosConstruccionEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/elementosConstruccion */}
							<RutaConPermiso
								component={ElementosConstruccionLista}
								path={constantes.ruta.appAdministracionCatalogosElementosConstruccionLista}
								permiso={constantes.permiso.appAdministracionCatalogosElementosConstruccionVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/destinosEspecificos/nuevo */}
							<RutaConPermiso
								component={DestinoEspecificoDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosDestinosEspecificosNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosDestinosEspecificosCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/destinosEspecificos/editar/:id */}
							<RutaConPermiso
								component={DestinoEspecificoDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosDestinosEspecificosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosDestinosEspecificosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/destinosEspecificos */}
							<RutaConPermiso
								component={DestinosEspecificosLista}
								path={constantes.ruta.appAdministracionCatalogosDestinosEspecificosLista}
								permiso={constantes.permiso.appAdministracionCatalogosDestinosEspecificosVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/construccionesDominantes/nuevo */}
							<RutaConPermiso
								component={ConstruccionDominanteDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosConstruccionesDominantesNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/construccionesDominantes/editar/:id */}
							<RutaConPermiso
								component={ConstruccionDominanteDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosConstruccionesDominantesDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/construccionesDominantes */}
							<RutaConPermiso
								component={ConstruccionesDominantesLista}
								path={constantes.ruta.appAdministracionCatalogosConstruccionesDominantesLista}
								permiso={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/clasificacionesZona/nuevo */}
							<RutaConPermiso
								component={ClasificacionZonaDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosClasificacionesZonaNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesZonaCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/clasificacionesZona/editar/:id */}
							<RutaConPermiso
								component={ClasificacionZonaDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosClasificacionesZonaDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesZonaEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/clasificacionesZona */}
							<RutaConPermiso
								component={ClasificacionesZonaLista}
								path={constantes.ruta.appAdministracionCatalogosClasificacionesZonaLista}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesZonaVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/clasificaciones/nuevo */}
							<RutaConPermiso
								component={ClasificacionesDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosClasificacionesNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/clasificaciones/editar/:id */}
							<RutaConPermiso
								component={ClasificacionesDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosClasificacionesDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/clasificaciones */}
							<RutaConPermiso
								component={ClasificacionesLista}
								path={constantes.ruta.appAdministracionCatalogosClasificacionesLista}
								permiso={constantes.permiso.appAdministracionCatalogosClasificacionesVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/catalogos/calidadesConstruccion/nuevo */}
							<RutaConPermiso
								component={CalidadConstruccionDetallesEditar}
								path={constantes.ruta.appAdministracionCatalogosCalidadesConstruccionNuevo}
								permiso={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionCrear}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/calidadesConstruccion/editar/:id */}
							<RutaConPermiso
								component={CalidadConstruccionDetallesEditar}
								path={`${constantes.ruta.appAdministracionCatalogosCalidadesConstruccionDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/catalogos/calidadesConstruccion */}
							<RutaConPermiso
								component={CalidadesConstruccionLista}
								path={constantes.ruta.appAdministracionCatalogosCalidadesConstruccionLista}
								permiso={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionVerLista}
								usuario={usuario}
							/>

							{/* /app/administracion/avaluos/detalles/editar/:id */}
							<RutaConPermiso
								component={AvaluoDetallesEditar}
								path={`${constantes.ruta.appAdministracionAvaluosDetallesEditar}/:id`}
								permiso={constantes.permiso.appAdministracionAvaluosEditarDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/avaluos/detalles/:id */}
							<RutaConPermiso
								component={AvaluoDetalles}
								path={`${constantes.ruta.appAdministracionAvaluosDetalles}/:id`}
								permiso={constantes.permiso.appAdministracionAvaluosVerDetalles}
								usuario={usuario}
							/>
							{/* /app/administracion/avaluos */}
							<RutaConPermiso
								component={AvaluosLista}
								path={constantes.ruta.appAdministracionAvaluosLista}
								permiso={constantes.permiso.appAdministracionAvaluosVerLista}
								usuario={usuario}
							/>

							{/* /app/inicio */}
							<Redirect from="/*" to={constantes.ruta.appInicio} />
						</Switch>
					</Tema>
				</div>
			)}
		</ManejadorErrores>
	);
};

export default RutasApp;
