import 'moment/locale/es-mx';
import Departamento from '../../../../modelo/Departamento';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const formateador = new Intl.NumberFormat('es-MX', {
	currency: 'MXN',
	style: 'currency'
});

const seccion = (departamento: Departamento) =>
	new Promise((resolve) => {
		if (departamento && departamento.condominio) {
			const {
				condominio,
				construccionComun1,
				construccionComun2,
				construccionComun3,
				construccionComun4,
				construccionComun5,
				construccionPrivativa,
				privativasSuperficieConstruccionTotal,
				privativasValorConstruccionComun1,
				privativasValorConstruccionComun2,
				privativasValorConstruccionComun3,
				privativasValorConstruccionComun4,
				privativasValorConstruccionComun5,
				privativasValorConstruccionPrivativa,
				privativasValorConstruccionTotal,
				privativasValorFiscal
			} = departamento;
			const {
				clasificacionConstruccionComun1,
				clasificacionConstruccionComun2,
				clasificacionConstruccionComun3,
				clasificacionConstruccionComun4,
				clasificacionConstruccionComun5,
				clasificacionConstruccionPrivativa,
				incrementoConstruccionComun1,
				incrementoConstruccionComun2,
				incrementoConstruccionComun3,
				incrementoConstruccionComun4,
				incrementoConstruccionComun5,
				incrementoConstruccionPrivativa
			} = condominio;

			const body = [
				[
					{
						border: [1, 1, 1, 1],
						colSpan: 6,
						fillColor: '#dcdcdc',
						margin: [0, 5, 0, 5],
						style: 'seccionTablaEncabezado',
						text: 'DETALLE DE BLOQUES DE CONSTRUCCIÓN'
					},
					{},
					{},
					{},
					{},
					{}
				],
				[
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'CLASIFICACIÓN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'SUPERFICIE'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'VALOR UNITARIO'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'INCREMENTO'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'TIPO'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCeldaNegrita',
						text: 'TOTAL'
					}
				]
			];

			if (clasificacionConstruccionPrivativa && incrementoConstruccionPrivativa && construccionPrivativa && privativasValorConstruccionPrivativa) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionPrivativa.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionPrivativa ? `${construccionPrivativa.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionPrivativa ? formateador.format(clasificacionConstruccionPrivativa.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionPrivativa ? `${incrementoConstruccionPrivativa.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'PRIVATIVA'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionPrivativa ? formateador.format(privativasValorConstruccionPrivativa) : formateador.format(0)
					}
				]);
			}

			if (clasificacionConstruccionComun1 && incrementoConstruccionComun1 && construccionComun1 && privativasValorConstruccionComun1) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun1.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionComun1 ? `${construccionComun1.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun1 ? formateador.format(clasificacionConstruccionComun1.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionComun1 ? `${incrementoConstruccionComun1.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'COMÚN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionComun1 ? formateador.format(privativasValorConstruccionComun1) : formateador.format(0)
					}
				]);
			}

			if (clasificacionConstruccionComun2 && incrementoConstruccionComun2 && construccionComun2 && privativasValorConstruccionComun2) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun2.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionComun2 ? `${construccionComun2.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun2 ? formateador.format(clasificacionConstruccionComun2.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionComun2 ? `${incrementoConstruccionComun2.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'COMÚN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionComun2 ? formateador.format(privativasValorConstruccionComun2) : formateador.format(0)
					}
				]);
			}

			if (clasificacionConstruccionComun3 && incrementoConstruccionComun3 && construccionComun3 && privativasValorConstruccionComun3) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun3.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionComun3 ? `${construccionComun3.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun3 ? formateador.format(clasificacionConstruccionComun3.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionComun3 ? `${incrementoConstruccionComun3.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'COMÚN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionComun3 ? formateador.format(privativasValorConstruccionComun3) : formateador.format(0)
					}
				]);
			}

			if (clasificacionConstruccionComun4 && incrementoConstruccionComun4 && construccionComun4 && privativasValorConstruccionComun4) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun4.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionComun4 ? `${construccionComun4.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun4 ? formateador.format(clasificacionConstruccionComun4.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionComun4 ? `${incrementoConstruccionComun4.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'COMÚN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionComun4 ? formateador.format(privativasValorConstruccionComun4) : formateador.format(0)
					}
				]);
			}

			if (clasificacionConstruccionComun5 && incrementoConstruccionComun5 && construccionComun5 && privativasValorConstruccionComun5) {
				body.push([
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun5.codigo
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: construccionComun5 ? `${construccionComun5.toLocaleString('es-MX')} m²` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: clasificacionConstruccionComun5 ? formateador.format(clasificacionConstruccionComun5.valor) : formateador.format(0)
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: incrementoConstruccionComun5 ? `${incrementoConstruccionComun5.toLocaleString('es-MX')} %` : ''
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: 'COMÚN'
					},
					{
						border: [1, 1, 1, 1],
						margin: [0, 5, 0, 5],
						style: 'seccionTablaCelda',
						text: privativasValorConstruccionComun5 ? formateador.format(privativasValorConstruccionComun5) : formateador.format(0)
					}
				]);
			}

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 50, 0, 0],
					style: 'seccion',
					table: {
						body: body,
						widths: ['*', '*', '*', '*', '*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'SUPERFICIE DE CONSTRUCCIÓN TOTAL'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: privativasSuperficieConstruccionTotal ? `${privativasSuperficieConstruccionTotal.toLocaleString('es-MX')} m²` : ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR TOTAL CONTRUCCIÓN'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: privativasValorConstruccionTotal ? formateador.format(privativasValorConstruccionTotal) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 50, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR FISCAL'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: privativasValorFiscal ? formateador.format(privativasValorFiscal) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
