import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import constantes from 'configuracion/constantes';
import RolFormularioPermisosAplicacion from './RolFormularioPermisosAplicacion';
import RolFormularioPermisosAvaluos from './RolFormularioPermisosAvaluos';
import RolFormularioPermisosCatalogos from './RolFormularioPermisosCatalogos';
import RolFormularioPermisosCondominios from './RolFormularioPermisosCondominios';
import RolFormularioPermisosDepartamentos from './RolFormularioPermisosDepartamentos';
import RolFormularioPermisosNotificaciones from './RolFormularioPermisosNotificaciones';
import RolFormularioPermisosPeritos from './RolFormularioPermisosPeritos';
import RolFormularioPermisosRoles from './RolFormularioPermisosRoles';
import RolFormularioPermisosUsuarios from './RolFormularioPermisosUsuarios';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useSelector } from 'react-redux';

const PestanaTipo = {
	APLICACION: 'aplicacion',
	AVALUOS: 'avaluos',
	CATALOGOS: 'catalogos',
	CONDOMINIOS: 'condominios',
	DEPARTAMENTOS: 'departamentos',
	NOTIFICACIONES: 'notificaciones',
	PERITOS: 'peritos',
	ROLES: 'roles',
	USUARIOS: 'usuarios'
};

interface RolFormularioPermisosProps {
	/**
	 * Indica si los permisos de la aplicación serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosAplicacionEditables?: boolean;
	/**
	 * Indica si los permisos de los avalúos serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosAvaluosEditables?: boolean;
	/**
	 * Indica si los permisos de los catálogos serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosCatalogosEditables?: boolean;
	/**
	 * Indica si los permisos de los condominios serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosCondominiosEditables?: boolean;
	/**
	 * Indica si los permisos de los departamentos serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosDepartamentosEditables?: boolean;
	/**
	 * Indica si los permisos de las notificaciones serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosNotificacionesEditables?: boolean;
	/**
	 * Indica si los permisos de los peritos serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosPeritosEditables?: boolean;
	/**
	 * Indica si los permisos de los roles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosRolesEditables?: boolean;
	/**
	 * Indica si los permisos de los usuarios serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	permisosUsuariosEditables?: boolean;
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos de un rol.
 */
const RolFormularioPermisos = (props: RolFormularioPermisosProps) => {
	const history = useHistory();
	const location = useLocation();
	const { sesion } = useSelector((state: StateType) => state);
	const { usuario } = sesion;
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.APLICACION);
	const {
		permisosAplicacionEditables,
		permisosAvaluosEditables,
		permisosCatalogosEditables,
		permisosCondominiosEditables,
		permisosDepartamentosEditables,
		permisosNotificacionesEditables,
		permisosPeritosEditables,
		permisosRolesEditables,
		permisosUsuariosEditables,
		soloLectura = false
	} = props;

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.APLICACION);
	}, [location]);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		let pestanaTipoInicial = '';
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosUsuarios, usuario })) {
			pestanaTipoInicial = PestanaTipo.USUARIOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosRoles, usuario })) {
			pestanaTipoInicial = PestanaTipo.ROLES;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosPeritos, usuario })) {
			pestanaTipoInicial = PestanaTipo.PERITOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosNotificaciones, usuario })) {
			pestanaTipoInicial = PestanaTipo.NOTIFICACIONES;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosCatalogos, usuario })) {
			pestanaTipoInicial = PestanaTipo.CATALOGOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosAvaluos, usuario })) {
			pestanaTipoInicial = PestanaTipo.AVALUOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosCondominios, usuario })) {
			pestanaTipoInicial = PestanaTipo.CONDOMINIOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosAplicacion, usuario })) {
			pestanaTipoInicial = PestanaTipo.APLICACION;
		}
		setPestanaActiva(pestana ? pestana : pestanaTipoInicial);
	}, [location]);

	return (
		<div className="d-flex align-items-start">
			<div className="nav flex-column nav-pills me-3">
				{permisosAplicacionEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.APLICACION ? ' active' : ''}`}
						id="pestanaAplicacion"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.APLICACION}`)}
						type="button"
					>
						{texto('Aplicación')}
					</button>
				)}
				{permisosAvaluosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.AVALUOS ? ' active' : ''}`}
						id="pestanaAvaluos"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.AVALUOS}`)}
						type="button"
					>
						{texto('Avalúos')}
					</button>
				)}
				{permisosCondominiosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.CONDOMINIOS ? ' active' : ''}`}
						id="pestanaCondominios"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.CONDOMINIOS}`)}
						type="button"
					>
						{texto('Condominios')}
					</button>
				)}
				{permisosCatalogosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.CATALOGOS ? ' active' : ''}`}
						id="pestanaCatalogos"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.CATALOGOS}`)}
						type="button"
					>
						{texto('Catálogos')}
					</button>
				)}
				{permisosDepartamentosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.DEPARTAMENTOS ? ' active' : ''}`}
						id="pestanaDepartamentos"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DEPARTAMENTOS}`)}
						type="button"
					>
						{texto('Departamentos')}
					</button>
				)}
				{permisosNotificacionesEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.NOTIFICACIONES ? ' active' : ''}`}
						id="pestanaNotificaciones"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.NOTIFICACIONES}`)}
						type="button"
					>
						{texto('Notificaciones')}
					</button>
				)}
				{permisosPeritosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.PERITOS ? ' active' : ''}`}
						id="pestanaPeritos"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.PERITOS}`)}
						type="button"
					>
						{texto('Peritos')}
					</button>
				)}
				{permisosRolesEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.ROLES ? ' active' : ''}`}
						id="pestanaRoles"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.ROLES}`)}
						type="button"
					>
						{texto('Roles')}
					</button>
				)}
				{permisosUsuariosEditables && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.USUARIOS ? ' active' : ''}`}
						id="pestanaUsuarios"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.USUARIOS}`)}
						type="button"
					>
						{texto('Usuarios')}
					</button>
				)}
			</div>
			<div className="tab-content">
				{permisosAplicacionEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.APLICACION ? ' show active' : ''}`} id="contenidoAplicacion">
						<RolFormularioPermisosAplicacion soloLectura={soloLectura} />
					</div>
				)}
				{permisosAvaluosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.AVALUOS ? ' show active' : ''}`} id="contenidoAvaluos">
						<RolFormularioPermisosAvaluos soloLectura={soloLectura} />
					</div>
				)}
				{permisosCondominiosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.CONDOMINIOS ? ' show active' : ''}`} id="contenidoCondominios">
						<RolFormularioPermisosCondominios soloLectura={soloLectura} />
					</div>
				)}
				{permisosCatalogosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.CATALOGOS ? ' show active' : ''}`} id="contenidoCatalogos">
						<RolFormularioPermisosCatalogos soloLectura={soloLectura} />
					</div>
				)}
				{permisosDepartamentosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.DEPARTAMENTOS ? ' show active' : ''}`} id="contenidoDepartamentos">
						<RolFormularioPermisosDepartamentos soloLectura={soloLectura} />
					</div>
				)}
				{permisosNotificacionesEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.NOTIFICACIONES ? ' show active' : ''}`} id="contenidoNotificaciones">
						<RolFormularioPermisosNotificaciones soloLectura={soloLectura} />
					</div>
				)}
				{permisosPeritosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.PERITOS ? ' show active' : ''}`} id="contenidoPeritos">
						<RolFormularioPermisosPeritos soloLectura={soloLectura} />
					</div>
				)}
				{permisosRolesEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.ROLES ? ' show active' : ''}`} id="contenidoRoles">
						<RolFormularioPermisosRoles soloLectura={soloLectura} />
					</div>
				)}
				{permisosUsuariosEditables && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.USUARIOS ? ' show active' : ''}`} id="contenidoUsuarios">
						<RolFormularioPermisosUsuarios soloLectura={soloLectura} />
					</div>
				)}
			</div>
		</div>
	);
};

export default RolFormularioPermisos;
