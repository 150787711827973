import 'moment/locale/es-mx';
import aplicacion from '../../../../configuracion/aplicacion';
import Condominio from '../../../../modelo/Condominio';
import { convertirImagenUrlABase64 } from '../../../../@mcsoft/archivos';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const seccion = ({ condominio }: { condominio: Condominio }) =>
	new Promise((resolve) => {
		if (condominio) {
			convertirImagenUrlABase64(aplicacion.imagenes.gobiernoGuadalajaraLogo).then((logoBase64) => {
				const contenido = [
					{
						layout: disenos.sinPaddingArribaAbajo,
						style: 'encabezado',
						table: {
							body: [
								[
									{
										border: [0, 0, 0, 0],
										image: logoBase64,
										margin: [0, 0, 0, 0],
										rowSpan: 5,
										style: { alignment: 'center' },
										width: 60
									},
									{
										border: [0, 0, 0, 0],
										colSpan: 2,
										margin: [0, 0, 0, 0],
										style: { alignment: 'center', bold: true, fontSize: 13 },
										text: 'AYUNTAMIENTO CONSTITUCIONAL DE GUADALAJARA'
									},
									{}
								],
								[
									{},
									{
										border: [0, 0, 0, 0],
										colSpan: 2,
										margin: [0, 0, 0, 0],
										style: { alignment: 'center', fontSize: 12 },
										text: 'TESORERÍA MUNICIPAL'
									},
									{}
								],
								[
									{},
									{
										border: [0, 0, 0, 0],
										colSpan: 2,
										margin: [0, 0, 0, 0],
										style: { alignment: 'center', fontSize: 9 },
										text: 'DIRECCIÓN DE CATASTRO'
									},
									{}
								],
								[
									{},
									{
										border: [0, 0, 0, 0],
										colSpan: 2,
										margin: [0, 0, 0, 0],
										style: { alignment: 'center', bold: true, fontSize: 15 },
										text: 'AVALÚO CATASTRAL GLOBALES'
									},
									{}
								],
								[
									{},
									{
										border: [0, 0, 0, 0],
										margin: [0, 0, 0, 0],
										style: { alignment: 'right', bold: true, fontSize: 10 },
										text: 'Fecha:'
									},
									{
										border: [0, 0, 0, 0],
										margin: [0, 0, 0, 0],
										style: { alignment: 'center', fontSize: 10 },
										text: moment(new Date()).tz('America/Bahia_Banderas').locale(false).format('DD/MM/YYYY')
									}
								]
							],
							widths: [100, '*', 60]
						}
					}
				];
				resolve(contenido);
			});
		}
	});

export default seccion;
