import datosCondominio from './datosCondominio';
import datosConstrucciones from './datosConstrucciones';
import datosTerreno from './datosTerreno';
import Departamento from '../../../../modelo/Departamento';
import encabezado from './encabezado';
import marcoPagina from './marcoPagina';

const pagina = ({ departamento }: { departamento: Departamento }) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ departamento }));
		promesas.push(datosCondominio(departamento));
		promesas.push(datosTerreno(departamento));
		promesas.push(datosConstrucciones(departamento));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
