/**
 * Este archivo contiene la configuración de la aplicación.
 */
export default {
	api: {
		id: {
			estadoPredeterminado: 'e6256253-ff60-45b8-883c-ef5f31358e12',
			estadoPredeterminadoNombre: 'Jalisco',
			municipioPredeterminado: 'c8592215-647e-4c48-b411-de1bac13aaf0',
			municipioPredeterminadoNombre: 'Guadalajara',
			paisPredeterminado: '9eeeec08-4495-401a-a1e1-05dd403d4d51',
			paisPredeterminadoNombre: 'México'
		},
		recurso: {
			avaluos: 'avaluos',
			clasificaciones: 'clasificaciones',
			condominios: 'condominios',
			configuracionAplicacion: 'configuracionAplicacion',
			configuracionSistema: 'configuracionSistema',
			departamentos: 'departamentos',
			estados: 'estados',
			municipios: 'municipios',
			opciones: 'opciones',
			paises: 'paises',
			peritos: 'peritos',
			prueba: 'prueba',
			usuarios: 'usuarios',
			usuariosNotificaciones: 'usuariosNotificaciones',
			usuariosRoles: 'usuariosRoles',
			widgets: 'widgets'
		},
		servidor: process.env.REACT_APP_API_SERVIDOR,
		version: process.env.REACT_APP_API_VERSION
	},
	auth0: {
		audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
		domain: process.env.REACT_APP_AUTH0_DOMAIN
	},
	aws: {
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		region: process.env.REACT_APP_AWS_REGION,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
	},
	google: {
		claveApi: process.env.REACT_APP_GOOGLE_CLAVE_API,
		urlApiMapasEstaticos: process.env.REACT_APP_GOOGLE_URL_API_MAPAS_ESTATICOS
	},
	imagenes: {
		banderaMexico64x42: '/imagenes/banderas/mexico_64x42.jpg',
		banderaUsa64x42: '/imagenes/banderas/usa_64x42.jpg',
		gobiernoGuadalajaraLogo: '/imagenes/logos/logo-gobierno-guadalajara.png',
		logoAplicacion700x200: '/imagenes/logos/aplicacion_700x200.png',
		logoClaro1080x202: '/imagenes/logos/aplicacion-claro_1080x202.png',
		logoClaro202x202: '/imagenes/logos/aplicacion-claro_202x202.png',
		logoOscuro1080x202: '/imagenes/logos/aplicacion-oscuro_1080x202.png',
		logoOscuro202x202: '/imagenes/logos/aplicacion-oscuro_202x202.png',
		predeterminadaAvaluoCroquis: '/imagenes/avaluos/croquis.png',
		predeterminadaAvaluoMapa: '/imagenes/avaluos/mapa.png',
		predeterminadaAvaluoMapaPersonalizado: '/imagenes/avaluos/mapaPersonalizado.jpeg',
		predeterminadaFotoPerfil100x100: '/imagenes/predeterminadas/foto-perfil_100x100.jpg',
		predeterminadaFotoPerfil50x50: '/imagenes/predeterminadas/foto-perfil_50x50.jpg',
		predeterminadaFotoPerfil700x700: '/imagenes/predeterminadas/foto-perfil_700x700.jpg',
		predeterminadaFotoPeritoLicencia200: '/imagenes/predeterminadas/perito-licencia_400x260.jpg',
		predeterminadaFotoPeritoLicencia400: '/imagenes/predeterminadas/perito-licencia_400x260.jpg',
		predeterminadaFotoPeritoLicencia700: '/imagenes/predeterminadas/perito-licencia_400x260.jpg',
		rosaVientos: '/imagenes/avaluos/rosaVientos.png',
		tecnologiaAuth0150x43: '/imagenes/tecnologias/auth0_150x43.png',
		tecnologiaAws150x43: '/imagenes/tecnologias/aws_150x43.png',
		tecnologiaNode150x43: '/imagenes/tecnologias/node_150x43.png',
		tecnologiaPostgres150x43: '/imagenes/tecnologias/postgres_150x43.png',
		tecnologiaReact150x43: '/imagenes/tecnologias/react_150x43.png',
		tecnologiaSequelize150x43: '/imagenes/tecnologias/sequelize_150x43.png'
	},
	nombreSeccionApp: 'App',
	nombreSeccionWeb: 'Web',
	paypal: {
		account: process.env.REACT_APP_PAYPAL_SANDBOX_ACCOUNT,
		clientId: process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
		secret: process.env.REACT_APP_PAYPAL_SANDBOX_SECRET
	},
	tutoriales: {
		ejemploAvaluoAntecedentes: '/imagenes/tutoriales/ejemplo-avaluo-antecedentes.jpg',
		ejemploAvaluoCaracteristicas: '/imagenes/tutoriales/ejemplo-avaluo-caracteristicas.jpg',
		ejemploAvaluoCodigoValuador: '/imagenes/tutoriales/ejemplo-avaluo-codigo-valuador.jpg',
		ejemploAvaluoConstrucciones: '/imagenes/tutoriales/ejemplo-avaluo-construcciones.jpg',
		ejemploAvaluoDistribucion: '/imagenes/tutoriales/ejemplo-avaluo-distribucion.jpg',
		ejemploAvaluoElementosConstruccion: '/imagenes/tutoriales/ejemplo-avaluo-elementos-construccion.jpg',
		ejemploAvaluoFotos: '/imagenes/tutoriales/ejemplo-avaluo-fotos.jpg',
		ejemploAvaluoGeneral: '/imagenes/tutoriales/ejemplo-avaluo-general.jpg',
		ejemploAvaluoInstalacionesEspeciales: '/imagenes/tutoriales/ejemplo-avaluo-instalaciones-especiales.jpg',
		ejemploAvaluoObservaciones: '/imagenes/tutoriales/ejemplo-avaluo-observaciones.jpg',
		ejemploAvaluoPdfCoeficientesUtilizacionOcupacion: '/imagenes/tutoriales/ejemplo-avaluo-pdf-coeficientes-utilizacion-ocupacion.jpg',
		ejemploAvaluoPdfCoordenadasDd: '/imagenes/tutoriales/ejemplo-avaluo-pdf-coordenadas-dd.jpg',
		ejemploAvaluoPdfCoordenadasDms: '/imagenes/tutoriales/ejemplo-avaluo-pdf-coordenadas-dms.jpg',
		ejemploAvaluoPdfCoordenadasUtm: '/imagenes/tutoriales/ejemplo-avaluo-pdf-coordenadas-utm.jpg',
		ejemploAvaluoPdfEncabezadoCompacto: '/imagenes/tutoriales/ejemplo-avaluo-pdf-encabezado-compacto.jpg',
		ejemploAvaluoPdfEncabezadoNormal: '/imagenes/tutoriales/ejemplo-avaluo-pdf-encabezado-normal.jpg',
		ejemploAvaluoPdfEtiquetaConstruccionClasificacion: '/imagenes/tutoriales/ejemplo-avaluo-pdf-etiqueta-construccion-clasificacion.jpg',
		ejemploAvaluoPdfEtiquetaConstruccionCodigo: '/imagenes/tutoriales/ejemplo-avaluo-pdf-etiqueta-construccion-codigo.jpg',
		ejemploAvaluoPdfLicencia: '/imagenes/tutoriales/ejemplo-avaluo-pdf-licencia.jpg',
		ejemploAvaluoPdfNumeroPagina: '/imagenes/tutoriales/ejemplo-avaluo-pdf-numero-pagina.jpg',
		ejemploAvaluoPdfRellenarEspacios: '/imagenes/tutoriales/ejemplo-avaluo-pdf-rellenar-espacios.jpg',
		ejemploAvaluoTerrenos: '/imagenes/tutoriales/ejemplo-avaluo-terrenos.jpg',
		ejemploAvaluoUbicacion: '/imagenes/tutoriales/ejemplo-avaluo-ubicacion.jpg',
		ejemploExcelCondominios: '/imagenes/tutoriales/ejemplo-excel-condominios.png'
	}
};
