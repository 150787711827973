import 'moment/locale/es-mx';
import Departamento from '../../../../modelo/Departamento';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const seccion = (departamento: Departamento) =>
	new Promise((resolve) => {
		if (departamento && departamento.condominio) {
			const { condominio, cuentaPredial, subpredio, unidadPrivativa } = departamento;
			const { claveCatastral, nombre, ubicacion } = condominio;

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'CUENTA PREDIAL'
								},
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'CLAVE CATASTRAL'
								},
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'SUBPREDIO'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: cuentaPredial
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: claveCatastral
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: subpredio
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									colSpan: 3,
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'NOMBRE DEL CONDOMINIO'
								},
								{},
								{}
							],
							[
								{
									border: [1, 1, 1, 1],
									colSpan: 3,
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: nombre
								},
								{},
								{}
							]
						],
						widths: ['*', '*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'UBICACIÓN'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: `${ubicacion} Unidad Privativa ${unidadPrivativa}`
								}
							]
						],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
