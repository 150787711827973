import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Formatea un numero decimal con comas.
 */
const useDecimal = () => {
	const { decimalLocale } = useSelector((state: StateType) => state.configuracionAplicacion);
	return (numero?: number | string | null) => {
		if (numero !== undefined && numero !== null) {
			return Number(numero).toLocaleString(decimalLocale);
		} else {
			return Number(0).toLocaleString(decimalLocale);
		}
	};
};

export default useDecimal;
