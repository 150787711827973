import 'moment/locale/es-mx';
import Condominio from '../../../../modelo/Condominio';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const seccion = (condominio: Condominio) =>
	new Promise((resolve) => {
		if (condominio) {
			const { claveCatastral, nombre, ubicacion } = condominio;

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'CLAVE CATASTRAL'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: claveCatastral
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'NOMBRE DEL CONDOMINIO'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: nombre
								}
							]
						],
						widths: ['*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'UBICACIÓN'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: ubicacion
								}
							]
						],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
