import { Col, Row } from 'reactstrap';
import Departamento from 'modelo/Departamento';
import { texto } from 'idiomas';
import useDecimal from 'hooks/useDecimal';
import { useEffect } from 'react';
import useMoneda from 'hooks/useMoneda';
import { useObtenerReportePrivativas } from 'hooks/useReporte';

interface CondominioTarjetaProps {
	/**
	 * Objeto con la información del condominio.
	 */
	departamento: Departamento;
	/**
	 * Indiviso para el calculo del reporte de globales.
	 */
	indiviso?: number;
	/**
	 * Indica si debe mostrarse el calculo del reporte de globales.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	mostrarCalculo?: boolean;
}

/**
 * Muestra la información de un rol.
 */
const DepartamentoTarjeta = (props: CondominioTarjetaProps) => {
	// Hooks Personalizados
	const obtenerReportePrivativas = useObtenerReportePrivativas();
	const decimal = useDecimal();
	const moneda = useMoneda();
	// Props
	const { departamento } = props;

	/**
	 * Dibuja un atributo del condominio.
	 * - ***etiqueta*** - Etiqueta que del atributo (Se mostrará en pantalla md+).
	 * - ***etiquetaMovil*** - Etiqueta que del atributo (Se mostrará en pantalla sm-).
	 * - ***icono*** - Icono *FontAwesome* que se mostrará antes de la etiqueta.
	 * - ***valor*** - Valor del atributo.
	 */
	const dibujarAtributo = ({ etiqueta, etiquetaMovil, icono, valor }: { etiqueta: string; etiquetaMovil?: string; icono?: string; valor: any }) => {
		const etiquetaMovilFinal = etiquetaMovil || etiqueta;

		return (
			<div className="d-flex flex-row">
				<span className="fw-bold">
					{icono && (
						<div className="mr-2">
							<i className={icono} />
						</div>
					)}
					<span className="lg:hidden flex">{etiquetaMovilFinal}:</span>
					<span className="hidden lg:flex">{etiqueta}:</span>
				</span>
				<span className="fw-light mx-2">
					<span>{valor}</span>
				</span>
			</div>
		);
	};

	useEffect(() => {
		obtenerReportePrivativas(departamento);
	}, [departamento]);

	if (departamento) {
		return (
			<>
				<Row>
					<h3>{texto('Información')} </h3>
				</Row>
				<Row>
					<Col md="12">
						{dibujarAtributo({
							etiqueta: texto('Nombre del Condominio'),
							valor: departamento?.condominio?.nombre
						})}
						{dibujarAtributo({
							etiqueta: texto('Ubicación'),
							valor: departamento?.condominio?.ubicacion
						})}
					</Col>
				</Row>
				<Row>
					<Col md="6">
						{dibujarAtributo({
							etiqueta: texto('Unidad Privativa'),
							valor: departamento?.unidadPrivativa
						})}
						{dibujarAtributo({
							etiqueta: texto('Subpredio'),
							valor: departamento?.subpredio
						})}
						{dibujarAtributo({
							etiqueta: texto('Cuenta Predial'),
							valor: departamento?.cuentaPredial
						})}
						{dibujarAtributo({
							etiqueta: texto('Clave Catastral'),
							valor: departamento?.condominio?.claveCatastral
						})}
					</Col>
					<Col md="6">
						{dibujarAtributo({
							etiqueta: texto('Indiviso Simple'),
							valor: departamento?.indivisoSimple
						})}
						{dibujarAtributo({
							etiqueta: texto('Indiviso Compuesto'),
							valor: departamento?.indivisoCompuesto
						})}
					</Col>
				</Row>
				<Row className="mt-5">
					<Col md="6">
						<h3>{texto('Terreno')}</h3>
						{dibujarAtributo({
							etiqueta: texto('Área del Terreno'),
							valor: `${decimal(departamento?.areaTerreno)} m²`
						})}
						{dibujarAtributo({
							etiqueta: texto('Valor m²'),
							valor: moneda(departamento.condominio?.valorTerreno)
						})}
						{dibujarAtributo({
							etiqueta: texto('Valor del Terreno'),
							valor: moneda(departamento.privativasValorTerreno)
						})}
					</Col>
					<Col md="6">
						<h3>{texto('Valor Total')}</h3>
						{dibujarAtributo({
							etiqueta: texto('Superficie de Construcción Total'),
							valor: `${decimal(departamento?.privativasSuperficieConstruccionTotal)} m²`
						})}
						{dibujarAtributo({
							etiqueta: texto('Valor Total de la Construcción'),
							valor: moneda(departamento.privativasValorConstruccionTotal)
						})}
						{dibujarAtributo({
							etiqueta: texto('Valor Fiscal'),
							valor: moneda(departamento.privativasValorFiscal)
						})}
					</Col>
				</Row>
				<Row className="mt-5">
					{departamento.privativasValorConstruccionPrivativa !== undefined && departamento.privativasValorConstruccionPrivativa > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Privativa')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionPrivativa)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionPrivativa?.codigo} - ${departamento?.condominio?.clasificacionConstruccionPrivativa?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionPrivativa?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionPrivativa)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionPrivativa)
							})}
						</Col>
					)}
					{departamento.privativasValorConstruccionComun1 !== undefined && departamento.privativasValorConstruccionComun1 > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Común')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionComun1)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionComun1?.codigo} - ${departamento?.condominio?.clasificacionConstruccionComun1?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionComun1?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionComun1)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionComun1)
							})}
						</Col>
					)}
					{departamento.privativasValorConstruccionComun2 !== undefined && departamento.privativasValorConstruccionComun2 > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Común 2')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionComun2)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionComun2?.codigo} - ${departamento?.condominio?.clasificacionConstruccionComun2?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionComun2?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionComun2)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionComun2)
							})}
						</Col>
					)}
					{departamento.privativasValorConstruccionComun3 !== undefined && departamento.privativasValorConstruccionComun3 > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Común 3')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionComun3)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionComun3?.codigo} - ${departamento?.condominio?.clasificacionConstruccionComun3?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionComun3?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionComun3)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionComun3)
							})}
						</Col>
					)}
					{departamento.privativasValorConstruccionComun4 !== undefined && departamento.privativasValorConstruccionComun4 > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Común 4')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionComun4)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionComun4?.codigo} - ${departamento?.condominio?.clasificacionConstruccionComun4?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionComun4?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionComun4)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionComun4)
							})}
						</Col>
					)}
					{departamento.privativasValorConstruccionComun5 !== undefined && departamento.privativasValorConstruccionComun5 > 0 && (
						<Col md="6">
							<h3>{texto('Construcción Común 5')}</h3>
							{dibujarAtributo({
								etiqueta: texto('Superficie'),
								valor: `${decimal(departamento?.construccionComun5)} m²`
							})}
							{dibujarAtributo({
								etiqueta: texto('Clasificación'),
								valor: `${departamento?.condominio?.clasificacionConstruccionComun5?.codigo} - ${departamento?.condominio?.clasificacionConstruccionComun5?.ano} (${moneda(
									departamento?.condominio?.clasificacionConstruccionComun5?.valor
								)})`
							})}
							{dibujarAtributo({
								etiqueta: texto('Incremento'),
								valor: `${decimal(departamento.condominio?.incrementoConstruccionComun5)} %`
							})}
							{dibujarAtributo({
								etiqueta: texto('Valor de la Construcción'),
								valor: moneda(departamento.privativasValorConstruccionComun5)
							})}
						</Col>
					)}
				</Row>
			</>
		);
	} else {
		return null;
	}
};

export default DepartamentoTarjeta;
