/* eslint-disable no-unused-vars */
import './McCampoTexto.scss';
import { ChangeEvent, FocusEvent, Fragment, MouseEvent, useState } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import { Tooltip } from 'reactstrap';

const NUMERO_MAXIMO_INTEGER = 2147483647;
const NUMERO_MINIMO_INTEGER = -2147483648;
const NUMERO_MAXIMO_INTEGER_PEQUENO = 32767;
const NUMERO_MINIMO_INTEGER_PEQUENO = -32768;
const NUMERO_MAXIMO_INTEGER_GRANDE = 9223372036854775807;
const NUMERO_MINIMO_INTEGER_GRANDE = -9223372036854775808;
const TEXTO = {
	errorLongitudMaxima: 'Longitud máxima $longitudMaxima',
	errorLongitudMinima: 'Longitud mínima $longitudMinima',
	errorNumeroMaximo: 'Valor máximo $numeroMaximo',
	errorNumeroMinimo: 'Valor mínimo $numeroMinimo',
	errorObligatorio: 'Obligatorio',
	textoMaximo: 'Máx.',
	textoMinimo: 'Mín'
};

type McCampoTextoProps = {
	/**
	 * Nombre del campo del formulario donde se guardará el valor del input.
	 */
	campo: string;
	/**
	 * Clase del botón que se mostrará a la derecha del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoDerecha != undefined || textoDerecha != undefined) && eventoBotonDerecha != undefined
	 */
	claseBotonDerecha?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Clase del botón que se mostrará a la izquierda del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoIzquierda != undefined || textoIzquierda != undefined) && eventoBotonIzquierda != undefined
	 */
	claseBotonIzquierda?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la derecha del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoDerecha != undefined || textoDerecha != undefined
	 */
	eventoBotonDerecha?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la izquierda del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoIzquierda != undefined || textoIzquierda != undefined
	 */
	eventoBotonIzquierda?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al cambiar el valor del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	eventoCambio?: (evento: ChangeEvent<HTMLElement | HTMLTextAreaElement>) => void;
	/**
	 * Función que valida el campo de manera asíncrona.
	 * - ***meta*** - Valores meta del formulario.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	funcionValidacionAsincrona?: ({ meta, valor, valores }: { meta?: any; valor: string; valores?: any }) => Promise<string | undefined>;
	/**
	 * Icono *FontAwesome* que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoDerecha?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > informacion != undefined
	 */
	iconoInformacion?: string;
	/**
	 * Icono *FontAwesome* que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoIzquierda?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Longitud máxima que aceptara el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	longitudMaxima?: number;
	/**
	 * Longitud mínima que aceptara el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	longitudMinima?: number;
	/**
	 * Indica que el input no mostrará la longitud mínima y máxima permitida.
	 *
	 * > ***Predeterminado:*** *false*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > longitudMaxima != undefined || longitudMinima != undefined
	 */
	longitudNoMostrar?: boolean;
	/**
	 * Indica el valor máximo admitido por el campo.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	numeroMaximo?: number;
	/**
	 * Indica el valor mínimo admitido por el campo.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	numeroMinimo?: number;
	/**
	 * Indica si el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	obligatorio?: boolean;
	/**
	 * Texto que se mostrará en el input cuando este vacío.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	placeholder?: string;
	/**
	 * Cantidad de renglones visibles del área de texto.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > tipo = "areaTexto"
	 */
	renglones?: number;
	/**
	 * Indica si el input mostrará colores de validación*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	sinValidacion?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McCampoTextoTexto;
	/**
	 * Texto que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoDerecha?: any;
	/**
	 * Texto que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoIzquierda?: any;
	/**
	 * Indica el tipo del input.
	 *
	 * > ***Predeterminado:*** *'texto'*
	 *
	 * **Valores Admitidos**
	 * - **areaTexto:** Area de texto.
	 * - **buscador:** Campo de texto que muestra la ocion de limpiar el campo.
	 * - **codigoPostal:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles).
	 * - **color:** Muestra un selector de color.
	 * - **contrasena:** Campo de texto que muestra asteriscos en vez de los caracteres introducidos.
	 * - **correoElectronico:** Campo de texto que muestra el teclado para email (Solo en dispositivos móviles).
	 * - **direccionWeb:** Campo de texto que muestra el teclado para url (Solo en dispositivos móviles).
	 * - **mes:** Campo selector que muestra los meses como opciones.
	 * - **numero:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles).
	 * - **numeroEntero:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles). Y limita el valor mínimo y máximo del campo al admitido por el tipo integer de Postgres.
	 * > - Rango Admitido: -2147483648 hasta 2147483647
	 * - **numeroEnteroPequeno:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles). Y limita el valor mínimo y máximo del campo al admitido por el tipo integer de Postgres.
	 * > - Rango Admitido: -32768 hasta 32767
	 * - **numeroEnteroGrande:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles). Y limita el valor mínimo y máximo del campo al admitido por el tipo integer de Postgres.
	 * > - Rango Admitido: -9223372036854775808 hasta 9223372036854775807
	 * - **numeroNumerico:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles).
	 * - **semana:** Campo selector que muestra las semanas del año como opciones.
	 * - **telefono:** Campo de texto que muestra el teclado numérico (Solo en dispositivos móviles).
	 * - **texto:** Campo de texto.
	 */
	tipo?:
		| 'areaTexto'
		| 'buscador'
		| 'codigoPostal'
		| 'color'
		| 'contrasena'
		| 'correoElectronico'
		| 'direccionWeb'
		| 'mes'
		| 'numero'
		| 'numeroEntero'
		| 'numeroEnteroPequeno'
		| 'numeroEnteroGrande'
		| 'numeroNumerico'
		| 'semana'
		| 'telefono'
		| 'texto';
};

interface McCampoTextoTexto {
	/**
	 * Texto que se mostrará al indicar que la longitud del campo es menor al atributo longitudMinima.
	 *
	 * > ***Predeterminado:*** *'Longitud mínima $longitudMinima'*
	 *
	 * **Nota:** $longitudMinima será sustituido por el valor del atributo longitudMinima.
	 */
	errorLongitudMinima?: string;
	/**
	 * Texto que se mostrará al indicar que la longitud del campo es mayor al atributo longitudMaxima.
	 *
	 * > ***Predeterminado:*** *'Longitud máxima $longitudMaxima'*
	 *
	 * **Nota:** $longitudMaxima será sustituido por el valor del atributo longitudMaxima.
	 */
	errorLongitudMaxima?: string;
	/**
	 * Texto que se mostrará al indicar que el valor del campo es mayor al atributo numeroMaximo.
	 *
	 * > ***Predeterminado:*** *'Valor máximo $numeroMaximo'*
	 *
	 * **Nota:** $numeroMaximo será sustituido por el valor del atributo numeroMaximo.
	 */
	errorNumeroMaximo?: string;
	/**
	 * Texto que se mostrará al indicar que el valor del campo es menor al atributo numeroMinimo.
	 *
	 * > ***Predeterminado:*** *'Valor mínimo $numeroMinimo'*
	 *
	 * **Nota:** $numeroMinimo será sustituido por el valor del atributo numeroMinimo.
	 */
	errorNumeroMinimo?: string;
	/**
	 * Texto que se mostrará al indicar que el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *'Obligatorio'*
	 */
	errorObligatorio?: string;
	/**
	 * Texto que se mostrará para indicar la longitud máxima admitida por el campo.
	 *
	 * > ***Predeterminado:*** *'Máx.'*
	 */
	textoMaximo?: string;
	/**
	 * Texto que se mostrará para indicar la longitud mínima admitida por el campo.
	 *
	 * > ***Predeterminado:*** *'Mín.'*
	 */
	textoMinimo?: string;
}

/**
 * Input de texto simple.
 */
export const McCampoTexto = (props: McCampoTextoProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const {
		campo,
		claseBotonDerecha = 'primary',
		claseBotonIzquierda = 'primary',
		etiqueta,
		eventoBotonDerecha,
		eventoBotonIzquierda,
		eventoCambio,
		funcionValidacionAsincrona = () => {},
		iconoDerecha,
		iconoInformacion = 'fa-solid fa-info-circle',
		iconoIzquierda,
		id,
		informacion,
		longitudMaxima,
		longitudMinima,
		longitudNoMostrar = false,
		obligatorio = false,
		placeholder,
		renglones,
		sinValidacion = false,
		soloLectura = false,
		textoDerecha,
		textoIzquierda,
		tipo = 'texto'
	} = props;
	let { numeroMaximo, numeroMinimo, texto } = props;
	texto = { ...TEXTO, ...texto };

	/**
	 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
	 * - ***valor*** - Valor a analizar.
	 */
	const tieneValor = (valor: any): boolean => {
		if (valor === undefined) {
			return false;
		}
		if (valor === null) {
			return false;
		}
		if (valor === '') {
			return false;
		}
		return true;
	};

	/**
	 * Válida el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const validarCampo = async ({ meta, valor, valores }: { meta: FieldMetaState<string>; valor: string; valores?: any }): Promise<string | undefined | void> => {
		if (obligatorio && !tieneValor(valor)) {
			return texto?.errorObligatorio;
		}
		if (tieneValor(valor) && longitudMaxima && valor.length > longitudMaxima) {
			return texto?.errorLongitudMaxima?.replaceAll('$longitudMaxima', longitudMaxima ? longitudMaxima.toString() : '');
		}
		if (tieneValor(valor) && longitudMinima && valor.length < longitudMinima) {
			return texto?.errorLongitudMinima?.replaceAll('$longitudMinima', longitudMinima ? longitudMinima.toString() : '');
		}
		if (tipo === 'numero' || tipo === 'numeroEntero' || tipo === 'numeroEnteroGrande' || tipo === 'numeroEnteroPequeno' || tipo === 'numeroNumerico') {
			if (tieneValor(numeroMaximo) && Number(valor) > Number(numeroMaximo)) {
				return texto?.errorNumeroMaximo?.replaceAll('$numeroMaximo', numeroMaximo ? numeroMaximo.toString() : '');
			}
			if (tieneValor(numeroMinimo) && Number(valor) < Number(numeroMinimo)) {
				return texto?.errorNumeroMinimo?.replaceAll('$numeroMinimo', numeroMinimo ? numeroMinimo.toString() : '');
			}
		}
		return funcionValidacionAsincrona({ meta, valor, valores });
	};

	const CLASES_BOTON = {
		danger: 'btn btn-danger',
		'danger contorno': 'btn btn-outline-danger',
		dark: 'btn btn-dark',
		'dark contorno': 'btn btn-outline-dark',
		info: 'btn btn-info',
		'info contorno': 'btn btn-outline-info',
		light: 'btn btn-light',
		'light contorno': 'btn btn-outline-light',
		link: 'btn btn-link',
		primary: 'btn btn-primary',
		'primary contorno': 'btn btn-outline-primary',
		secondary: 'btn btn-secondary',
		'secondary contorno': 'btn btn-outline-secondary',
		success: 'btn btn-success',
		'success contorno': 'btn btn-outline-success',
		warning: 'btn btn-warning',
		'warning contorno': 'btn btn-outline-warning'
	};

	/**
	 * Evento que válida que solo se acepten números en el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const eventoValidarCampoNumeroEntero = (eventoParametro: any) => {
		const evento = eventoParametro || window.event;
		let tecla = null;
		if (evento.type === 'paste') {
			tecla = evento.clipboardData.getData('text/plain');
		} else {
			tecla = evento.keyCode || evento.which;
			tecla = String.fromCharCode(tecla);
		}
		const expresionRegular = /^[0-9]*$/;
		if (!expresionRegular.test(tecla)) {
			evento.returnValue = false;
			if (evento.preventDefault) evento.preventDefault();
		}
	};

	/**
	 * Evento que válida que solo se acepten números y puntos en el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const eventoValidarCampoNumeroNumerico = (eventoParametro: any) => {
		const evento = eventoParametro || window.event;
		let tecla = null;
		if (evento.type === 'paste') {
			tecla = evento.clipboardData.getData('text/plain');
		} else {
			tecla = evento.keyCode || evento.which;
			tecla = String.fromCharCode(tecla);
		}
		const expresionRegular = /^[.0-9]*$/;
		if (!expresionRegular.test(tecla)) {
			evento.returnValue = false;
			if (evento.preventDefault) evento.preventDefault();
		}
	};

	/**
	 * Evento que válida que solo se acepten números en el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const eventoValidarCampoTelefono = (eventoParametro: any) => {
		const evento = eventoParametro || window.event;
		let tecla = null;
		if (evento.type === 'paste') {
			tecla = evento.clipboardData.getData('text/plain');
		} else {
			tecla = evento.keyCode || evento.which;
			tecla = String.fromCharCode(tecla);
		}
		const expresionRegular = /^[0-9]*$/;
		if (!expresionRegular.test(tecla)) {
			evento.returnValue = false;
			if (evento.preventDefault) evento.preventDefault();
		}
	};

	/**
	 * Evento que válida que solo se acepten números en el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const eventoValidarCampoCodigoPostal = (eventoParametro: any) => {
		const evento = eventoParametro || window.event;
		let tecla = null;
		if (evento.type === 'paste') {
			tecla = evento.clipboardData.getData('text/plain');
		} else {
			tecla = evento.keyCode || evento.which;
			tecla = String.fromCharCode(tecla);
		}
		const expresionRegular = /^[0-9]*$/;
		if (!expresionRegular.test(tecla)) {
			evento.returnValue = false;
			if (evento.preventDefault) evento.preventDefault();
		}
	};

	let tipoOriginal = '';
	let eventoOnkeyPress: ((eventoParametro: any) => void) | undefined = undefined;
	switch (tipo) {
		case 'areaTexto':
			tipoOriginal = 'textArea';
			break;
		case 'buscador':
			tipoOriginal = 'search';
			break;
		case 'codigoPostal':
			tipoOriginal = 'number';
			eventoOnkeyPress = eventoValidarCampoCodigoPostal;
			break;
		case 'color':
			tipoOriginal = 'color';
			break;
		case 'contrasena':
			tipoOriginal = 'password';
			break;
		case 'correoElectronico':
			tipoOriginal = 'email';
			break;
		case 'direccionWeb':
			tipoOriginal = 'url';
			break;
		case 'mes':
			tipoOriginal = 'month';
			break;
		case 'numero':
			tipoOriginal = 'number';
			eventoOnkeyPress = eventoValidarCampoNumeroNumerico;
			break;
		case 'numeroEntero':
			tipoOriginal = 'number';
			numeroMaximo = tieneValor(numeroMaximo) ? numeroMaximo : NUMERO_MAXIMO_INTEGER;
			numeroMinimo = tieneValor(numeroMinimo) ? numeroMinimo : NUMERO_MINIMO_INTEGER;
			eventoOnkeyPress = eventoValidarCampoNumeroEntero;
			break;
		case 'numeroEnteroPequeno':
			tipoOriginal = 'number';
			numeroMaximo = tieneValor(numeroMaximo) ? numeroMaximo : NUMERO_MAXIMO_INTEGER_PEQUENO;
			numeroMinimo = tieneValor(numeroMinimo) ? numeroMinimo : NUMERO_MINIMO_INTEGER_PEQUENO;
			eventoOnkeyPress = eventoValidarCampoNumeroEntero;
			break;
		case 'numeroEnteroGrande':
			tipoOriginal = 'number';
			numeroMaximo = tieneValor(numeroMaximo) ? numeroMaximo : NUMERO_MAXIMO_INTEGER_GRANDE;
			numeroMinimo = tieneValor(numeroMinimo) ? numeroMinimo : NUMERO_MINIMO_INTEGER_GRANDE;
			eventoOnkeyPress = eventoValidarCampoNumeroEntero;
			break;
		case 'numeroNumerico':
			tipoOriginal = 'number';
			eventoOnkeyPress = eventoValidarCampoNumeroNumerico;
			break;
		case 'semana':
			tipoOriginal = 'week';
			break;
		case 'telefono':
			tipoOriginal = 'tel';
			eventoOnkeyPress = eventoValidarCampoTelefono;
			break;
		case 'texto':
			tipoOriginal = 'text';
			break;
		default:
			tipoOriginal = 'text';
			break;
	}

	const claseBotonDerechaOriginal = CLASES_BOTON[claseBotonDerecha];
	const claseBotonIzquierdaOriginal = CLASES_BOTON[claseBotonIzquierda];

	return (
		<Field
			name={campo}
			parse={(valor) => {
				if (!valor) {
					return '';
				} else {
					return valor;
				}
			}}
			validate={(valor: string, valores?: any, meta?: any) => validarCampo({ meta, valor, valores })}
		>
			{({ input, meta }) => {
				const valido = !meta.error && meta.touched;
				const esInvalido = meta.error;
				const eventoFocusNuevo = (evento: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					input.onFocus(evento);
					evento.target.classList.add('mc-campo-texto__input--focused');
				};
				const eventoBlurNuevo = (evento: FocusEvent<HTMLElement | HTMLTextAreaElement>) => {
					input.onBlur(evento);
					evento.target.classList.remove('mc-campo-texto__input--focused');
				};
				const eventoCambioNuevo = (evento: ChangeEvent<HTMLElement | HTMLTextAreaElement>) => {
					input.onChange(evento);
					if (eventoCambio) {
						eventoCambio(evento);
					}
				};
				return (
					<Fragment>
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						{etiqueta && (
							<label className="form-label" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && (
							<span className="text-primary">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
						<div className="input-group" style={{ marginBottom: '0px' }}>
							{(iconoIzquierda || textoIzquierda) && !eventoBotonIzquierda && (
								<span className="input-group-text">
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</span>
							)}
							{(iconoIzquierda || textoIzquierda) && eventoBotonIzquierda && (
								<button
									className={claseBotonIzquierdaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonIzquierda(evento);
									}}
								>
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</button>
							)}
							{tipo === 'areaTexto' ? (
								<textarea
									className={`form-control${valido && !sinValidacion ? ' is-valid' : ''}${esInvalido && !sinValidacion ? ' is-invalid' : ''}`}
									disabled={soloLectura}
									id={id}
									maxLength={longitudMaxima}
									minLength={longitudMinima}
									placeholder={placeholder}
									rows={renglones}
									style={soloLectura ? { backgroundColor: '#d6d5d4' } : {}}
									{...input}
									onBlur={eventoBlurNuevo}
									onChange={eventoCambioNuevo}
									onFocus={eventoFocusNuevo}
								/>
							) : (
								<input
									className={`form-control${tipo === 'color' ? ' form-control-color mw-100' : ''}${valido && !sinValidacion ? ' is-valid' : ''}${
										esInvalido && !sinValidacion ? ' is-invalid' : ''
									}`}
									disabled={soloLectura}
									id={id}
									max={numeroMaximo}
									maxLength={longitudMaxima}
									min={numeroMinimo}
									minLength={longitudMinima}
									onKeyPress={eventoOnkeyPress}
									onPaste={eventoOnkeyPress}
									placeholder={placeholder}
									style={soloLectura ? { backgroundColor: '#d6d5d4' } : {}}
									type={tipoOriginal}
									{...input}
									onBlur={eventoBlurNuevo}
									onChange={eventoCambioNuevo}
									onFocus={eventoFocusNuevo}
								/>
							)}
							{(iconoDerecha || textoDerecha) && !eventoBotonDerecha && (
								<span className="input-group-text">
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</span>
							)}
							{(iconoDerecha || textoDerecha) && eventoBotonDerecha && (
								<button
									className={claseBotonDerechaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonDerecha(evento);
									}}
								>
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</button>
							)}
						</div>
						<div className="form-text">
							{esInvalido && <span style={{ color: '#f46a6a' }}>{meta.error}</span>}
							{!longitudNoMostrar && longitudMinima && !longitudMaxima && (
								<span className="float-end" style={{ color: '#959aa3', marginBottom: '10px' }}>
									{longitudMinima} {texto?.textoMinimo}
								</span>
							)}
							{!longitudNoMostrar && !longitudMinima && longitudMaxima && (
								<span className="float-end" style={{ color: '#959aa3', marginBottom: '10px' }}>
									{longitudMaxima} {texto?.textoMaximo}
								</span>
							)}
							{!longitudNoMostrar && longitudMinima && longitudMaxima && (
								<span className="float-end" style={{ color: '#959aa3', marginBottom: '10px' }}>
									{longitudMinima}-{longitudMaxima} {texto?.textoMaximo}
								</span>
							)}
						</div>
					</Fragment>
				);
			}}
		</Field>
	);
};
