/* eslint-disable prefer-destructuring */
import { Fragment, useRef } from 'react';
import constantes from 'configuracion/constantes';
import MenuBoton from './MenuBoton';
import MenuContenedor from './MenuContenedor';
import MenuGrupo from './MenuGrupo';
import MenuSeparador from './MenuSeparador';
import SimpleBar from 'simplebar-react';
import { texto } from 'idiomas';

/**
 * Contiene el menú de navegación vertical de la aplicación.
 */
const BarraNavegacionLateralContenido = () => {
	const refSimpleBar = useRef<any>(null);

	return (
		<Fragment>
			<SimpleBar className="sidebar-menu-scroll" ref={refSimpleBar} style={{ maxHeight: '100%' }}>
				<MenuContenedor>
					<MenuSeparador etiqueta={texto('Sistema')} icono={constantes.icono.sistema} id="separadorSistema" />
					<MenuBoton
						etiqueta={texto('Configuración')}
						icono={constantes.icono.configuracionSistema}
						id="botonSistemaConfiguracion"
						permiso={constantes.permiso.sysAdmin}
						ruta={constantes.ruta.appSistemaConfiguracion}
					/>
					<MenuBoton etiqueta={texto('Logs')} icono={constantes.icono.logs} id="botonSistemaLogs" permiso={constantes.permiso.sysAdmin} ruta={constantes.ruta.appSistemaLogs} />
					<MenuSeparador etiqueta={texto('Administración')} icono={constantes.icono.administracion} id="separadorAdministracion" />
					<MenuBoton
						etiqueta={texto('Configuración')}
						icono={constantes.icono.configuracionAplicacion}
						id="botonAdministracionConfiguracionAplicacion"
						permiso={[
							constantes.permiso.appAdministracionConfiguracionDatos,
							constantes.permiso.appAdministracionConfiguracionGeneral,
							constantes.permiso.appAdministracionConfiguracionImagenes
						]}
						ruta={constantes.ruta.appAdministracionConfiguracionAplicacion}
					/>
					<MenuBoton
						etiqueta={texto('Dashboard')}
						icono={constantes.icono.dashboard}
						id="botonAdministracionDashboard"
						permiso={constantes.permiso.appAdministracionDashboard}
						ruta={constantes.ruta.appAdministracionDashboard}
					/>
					<MenuBoton
						etiqueta={texto('Notificaciones')}
						icono={constantes.icono.notificaciones}
						id="botonAdministracionNotificaciones"
						permiso={constantes.permiso.appAdministracionNotificacionesVerLista}
						ruta={constantes.ruta.appAdministracionNotificacionesLista}
					/>
					<MenuBoton
						etiqueta={texto('Roles')}
						icono={constantes.icono.rol}
						id="botonAdministracionRoles"
						permiso={constantes.permiso.appAdministracionRolesVerLista}
						ruta={constantes.ruta.appAdministracionRolesLista}
					/>
					<MenuBoton
						etiqueta={texto('Usuarios')}
						icono={constantes.icono.usuarios}
						id="botonAdministracionUsuarios"
						permiso={constantes.permiso.appAdministracionUsuariosVerLista}
						ruta={constantes.ruta.appAdministracionUsuariosLista}
					/>
					<MenuGrupo etiqueta={texto('Catálogos')} icono={constantes.icono.catalogos} id="grupoAdministracionCatalogos">
						<MenuBoton
							etiqueta={texto('Clasificaciones de Condominios')}
							icono={constantes.icono.clasificaciones}
							id="botonAdministracionClasificacion"
							permiso={constantes.permiso.appAdministracionCatalogosClasificacionesVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosClasificacionesLista}
						/>
						<MenuBoton
							etiqueta={texto('Calidades de Construcción')}
							icono={constantes.icono.calidadesConstruccion}
							id="botonAdministracionCalidadConstruccion"
							permiso={constantes.permiso.appAdministracionCatalogosCalidadesConstruccionVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosCalidadesConstruccionLista}
						/>
						<MenuBoton
							etiqueta={texto('Clasificaciones de Zona')}
							icono={constantes.icono.clasificacionesZona}
							id="botonAdministracionClasificacionZona"
							permiso={constantes.permiso.appAdministracionCatalogosClasificacionesZonaVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosClasificacionesZonaLista}
						/>
						<MenuBoton
							etiqueta={texto('Construcciones Dominantes')}
							icono={constantes.icono.construccionesDominantes}
							id="botonAdministracionConstruccionDominante"
							permiso={constantes.permiso.appAdministracionCatalogosConstruccionesDominantesVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosConstruccionesDominantesLista}
						/>
						<MenuBoton
							etiqueta={texto('Destinos Específicos')}
							icono={constantes.icono.destinosEspecificos}
							id="botonAdministracionDestinoEspecifico"
							permiso={constantes.permiso.appAdministracionCatalogosDestinosEspecificosVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosDestinosEspecificosLista}
						/>
						<MenuBoton
							etiqueta={texto('Elementos de Construcción')}
							icono={constantes.icono.elementosConstruccion}
							id="botonAdministracionElementoConstruccion"
							permiso={constantes.permiso.appAdministracionCatalogosElementosConstruccionVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosElementosConstruccionLista}
						/>
						<MenuBoton
							etiqueta={texto('Estados de Conservación')}
							icono={constantes.icono.estadosConservacion}
							id="botonAdministracionEstadoConservacion"
							permiso={constantes.permiso.appAdministracionCatalogosEstadosConservacionVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosEstadosConservacionLista}
						/>
						<MenuBoton
							etiqueta={texto('Motivos de Ajuste')}
							icono={constantes.icono.motivosAjuste}
							id="botonAdministracionMotivoAjuste"
							permiso={constantes.permiso.appAdministracionCatalogosMotivosAjusteVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosMotivosAjusteLista}
						/>
						<MenuBoton
							etiqueta={texto('Regímenes de Propiedad')}
							icono={constantes.icono.regimenesPropiedad}
							id="botonAdministracionRegimenPropiedad"
							permiso={constantes.permiso.appAdministracionCatalogosRegimenesPropiedadVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosRegimenesPropiedadLista}
						/>
						<MenuBoton
							etiqueta={texto('Servicios Municipales')}
							icono={constantes.icono.serviciosMunicipales}
							id="botonAdministracionServicioMunicipal"
							permiso={constantes.permiso.appAdministracionCatalogosServiciosMunicipalesVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosServiciosMunicipalesLista}
						/>
						<MenuBoton
							etiqueta={texto('Tipos de Calle')}
							icono={constantes.icono.tiposCalle}
							id="botonAdministracionTipoCalle"
							permiso={constantes.permiso.appAdministracionCatalogosTiposCalleVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosTiposCalleLista}
						/>
						<MenuBoton
							etiqueta={texto('Tipos de Construccion')}
							icono={constantes.icono.tiposConstruccion}
							id="botonAdministracionTipoConstruccion"
							permiso={constantes.permiso.appAdministracionCatalogosTiposConstruccionVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosTiposConstruccionLista}
						/>
						<MenuBoton
							etiqueta={texto('Tipos de Inmueble')}
							icono={constantes.icono.tiposInmueble}
							id="botonAdministracionTipoInmueble"
							permiso={constantes.permiso.appAdministracionCatalogosTiposInmuebleVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosTiposInmuebleLista}
						/>
						<MenuBoton
							etiqueta={texto('Unidades de Superficie')}
							icono={constantes.icono.unidadesSuperficie}
							id="botonAdministracionUnidadSuperficie"
							permiso={constantes.permiso.appAdministracionCatalogosUnidadesSuperficieVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosUnidadesSuperficieLista}
						/>
						<MenuBoton
							etiqueta={texto('Usos Específicos')}
							icono={constantes.icono.usosEspecificos}
							id="botonAdministracionUsoEspecifico"
							permiso={constantes.permiso.appAdministracionCatalogosUsosEspecificosVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosUsosEspecificosLista}
						/>
						<MenuBoton
							etiqueta={texto('Vientos Cardinales')}
							icono={constantes.icono.vientosCardinales}
							id="botonAdministracionVientoCardinal"
							permiso={constantes.permiso.appAdministracionCatalogosVientosCardinalesVerLista}
							ruta={constantes.ruta.appAdministracionCatalogosVientosCardinalesLista}
						/>
					</MenuGrupo>
					<MenuBoton
						etiqueta={texto('Peritos')}
						icono={constantes.icono.perito}
						id="botonAdministracionPeritos"
						permiso={constantes.permiso.appAdministracionPeritosVerLista}
						ruta={constantes.ruta.appAdministracionPeritosLista}
					/>
					<MenuBoton
						etiqueta={texto('Avalúos')}
						icono={constantes.icono.avaluo}
						id="botonAdministracionAvaluos"
						permiso={constantes.permiso.appAdministracionAvaluosVerLista}
						ruta={constantes.ruta.appAdministracionAvaluosLista}
					/>
					<MenuBoton
						etiqueta={texto('Condominiós')}
						icono={constantes.icono.condominio}
						id="botonAdministracionCondominios"
						permiso={constantes.permiso.appAdministracionCondominiosVerLista}
						ruta={constantes.ruta.appAdministracionCondominiosLista}
					/>
					<MenuSeparador etiqueta={texto('Menú')} icono={constantes.icono.menu} id="separadorMenu" />
					<MenuBoton etiqueta={texto('Inicio')} icono={constantes.icono.inicio} id="botonMenuInicio" ruta={constantes.ruta.appInicio} />
					<MenuBoton
						etiqueta={texto('Mis Peritos')}
						icono={constantes.icono.perito}
						id="botonMenuPeritos"
						permiso={constantes.permiso.appPeritosVerLista}
						ruta={constantes.ruta.appPeritosLista}
					/>
					<MenuBoton
						etiqueta={texto('Mis Avalúos')}
						icono={constantes.icono.avaluo}
						id="botonMenuAvaluos"
						permiso={constantes.permiso.appAvaluosVerLista}
						ruta={constantes.ruta.appAvaluosLista}
					/>
					<MenuBoton
						etiqueta={texto('Mis Condominios')}
						icono={constantes.icono.condominio}
						id="botonMenuCondominios"
						permiso={constantes.permiso.appCondominiosVerLista}
						ruta={constantes.ruta.appCondominiosLista}
					/>
				</MenuContenedor>
			</SimpleBar>
		</Fragment>
	);
};

export default BarraNavegacionLateralContenido;
