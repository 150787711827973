import 'moment/locale/es-mx';
import Departamento from '../../../../modelo/Departamento';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const formateador = new Intl.NumberFormat('es-MX', {
	currency: 'MXN',
	style: 'currency'
});

const seccion = (departamento: Departamento) =>
	new Promise((resolve) => {
		if (departamento && departamento.condominio) {
			const { areaTerreno, condominio, privativasValorTerreno } = departamento;
			const { valorTerreno } = condominio;

			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [1, 1, 1, 1],
									colSpan: 2,
									fillColor: '#dcdcdc',
									margin: [0, 5, 0, 5],
									style: 'seccionTablaEncabezado',
									text: 'DATOS DEL TERRENO PRIVATIVAS'
								},
								{}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'ÁREA TERRENO PRIVATIVA'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR UNITARIO TERRENO'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: areaTerreno ? `${areaTerreno.toLocaleString('es-MX')} m²` : ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: valorTerreno ? formateador.format(valorTerreno) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*']
					}
				},
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [0, 0, 0, 0],
									text: ''
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCeldaNegrita',
									text: 'VALOR TOTAL TERRENO'
								},
								{
									border: [1, 1, 1, 1],
									margin: [0, 5, 0, 5],
									style: 'seccionTablaCelda',
									text: privativasValorTerreno ? formateador.format(privativasValorTerreno) : formateador.format(0)
								}
							]
						],
						widths: ['*', '*', 20, '*', '*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
