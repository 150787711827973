import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import aplicacion from 'configuracion/aplicacion';
import { comprobarApi } from 'servicios/api/prueba';
import { estatusHttp } from '@mcsoft/api';
import fechaDeploy from '../../fechaDeploy';
import mcLogger from '@mcsoft/logger';
import { texto } from 'idiomas';
import { useAuth0 } from '@auth0/auth0-react';

const NOMBRE_CLASE = 'InicioWeb';

const Pagina = () => {
	const { loginWithRedirect } = useAuth0();
	const [estadoApi, setEstadoApi] = useState<string>('Offline');
	const [claseEstadoApi, setClaseEstadoApi] = useState<string>('bg-danger');

	useEffect(() => {
		comprobarEstadoAPI();
	}, []);

	const comprobarEstadoAPI = async () => {
		try {
			const respuestaApi = await comprobarApi();
			if (respuestaApi.codigo === estatusHttp.estatus200.codigo) {
				setEstadoApi('Online');
				setClaseEstadoApi('bg-success');
			} else {
				setEstadoApi('Offline');
				setClaseEstadoApi('bg-danger');
			}
		} catch {
			setEstadoApi('Offline');
			setClaseEstadoApi('bg-danger');
		}
	};

	/**
	 * Redirecciona a la sección de Inicio de la aplicación.
	 */
	const iniciarSesion = () => {
		const nombreMetodo = 'iniciarSesion';
		mcLogger.log({ mensaje: `Redireccionando a la ruta de inicio de sesión.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		loginWithRedirect();
	};

	return (
		<Fragment>
			<div className="my-5 pt-sm-5">
				<Container>
					<Row>
						<div className="col-12 text-center">
							<div className="home-wrapper">
								<img alt="Aplicación Base" src={aplicacion.imagenes.logoAplicacion700x200} />
								<h3 className="mt-1">{texto('Bienvenido')}</h3>
								<div className="mt-5">
									<button className="btn btn-primary" disabled={estadoApi === 'Offline'} id="botonIniciarSesion" onClick={iniciarSesion} type="button">
										{texto('Iniciar sesión')}
									</button>
								</div>
								<div className="mt-5">
									<p>{texto('Powered By')}</p>
								</div>
								<Row>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://auth0.com/">
													<img alt="Auth0" className="logo" src={aplicacion.imagenes.tecnologiaAuth0150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://aws.amazon.com/">
													<img alt="Amazon Web Services" className="logo" src={aplicacion.imagenes.tecnologiaAws150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://nodejs.org/">
													<img alt="Node JS" className="logo" src={aplicacion.imagenes.tecnologiaNode150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://www.postgresql.org/">
													<img alt="PostgreSQL" className="logo" src={aplicacion.imagenes.tecnologiaPostgres150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://reactjs.org/">
													<img alt="React JS" className="logo" src={aplicacion.imagenes.tecnologiaReact150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
									<Col md="2">
										<Card className="mt-2 maintenance-box">
											<CardBody className="p-2">
												<a href="https://sequelize.org/">
													<img alt="Sequelize" className="logo" src={aplicacion.imagenes.tecnologiaSequelize150x43} width="150px" />
												</a>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="mt-5">
									<p>
										<strong>{texto('Copyright')}</strong>
										&nbsp;{`2021 - ${new Date().getFullYear()}`} © {texto('McSoft')}
									</p>
									<p>
										<strong>{texto('Deployed')}</strong>
										&nbsp;{fechaDeploy.fechaDeploy}
									</p>
									<div className="mt-5">
										<p className="fs-5">
											<strong>{texto('Estatus Servidor')}</strong>
											&nbsp;<span className={`badge ${claseEstadoApi}`}>{estadoApi}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</Fragment>
	);
};

export default Pagina;
