import { Col, Row } from 'reactstrap';
import { McCampoTexto } from '@mcsoft/formulario';
import mcLogger from '@mcsoft/logger';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarDepartamentoDisponibilidad } from 'servicios/api/departamentos';

const NOMBRE_CLASE = 'paginas/app/componentes/condominios/CondominioFormulario';

/**
 * Formulario para un condominio.
 */
const DepartamentoFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;

	/**
	 * Válida el subpredio del departamento.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioSubpredio = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const nombreMetodo = 'validarFormularioSubpredio';
		const disponible = await validarDepartamentoDisponibilidad({
			auth0AccessToken,
			campo: 'subpredio',
			condominioId: valores.condominioId,
			id: valores.id,
			valor
		});
		if (!disponible) {
			const error = 'Ya existe un departamento con ese subpredio';
			mcLogger.dev({
				mensaje: `Validando el nombre del departamento...`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: error
			});
			return error;
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="3">
						<McCampoTexto campo="unidadPrivativa" etiqueta={texto('Unidad Privativa')} id="unidadPrivativa" longitudMaxima={10} obligatorio />
					</Col>
					<Col md="3">
						<McCampoTexto campo="subpredio" etiqueta={texto('Subpredio')} funcionValidacionAsincrona={validarFormularioSubpredio} id="subpredio" longitudMaxima={10} obligatorio />
					</Col>
					<Col md="6">
						<McCampoTexto campo="cuentaPredial" etiqueta={texto('Cuenta Predial')} id="cuentaPredial" longitudMaxima={50} />
					</Col>
				</Row>
				<Row>
					<Col md="3">
						<McCampoTexto campo="areaTerreno" etiqueta={texto('Arrea de Terreno')} id="areaTerreno" obligatorio tipo="numeroNumerico" />
					</Col>
					<Col md="3">
						<McCampoTexto campo="indivisoSimple" etiqueta={texto('Indiviso Simple')} id="indivisoSimple" obligatorio tipo="numeroNumerico" />
					</Col>
					<Col md="3">
						<McCampoTexto campo="indivisoCompuesto" etiqueta={texto('Indiviso Compuesto')} id="indivisoCompuesto" obligatorio tipo="numeroNumerico" />
					</Col>
				</Row>
				<Row>
					<Col md="2">
						<McCampoTexto campo="construccionPrivativa" etiqueta={texto('Construcción Privativa')} id="construccionPrivativa" tipo="numeroNumerico" />
					</Col>
					<Col md="2">
						<McCampoTexto campo="construccionComun1" etiqueta={texto('Construcción Común')} id="construccionComun1" tipo="numeroNumerico" />
					</Col>
					<Col md="2">
						<McCampoTexto campo="construccionComun2" etiqueta={texto('Construcción Común 2')} id="construccionComun2" tipo="numeroNumerico" />
					</Col>
					<Col md="2">
						<McCampoTexto campo="construccionComun3" etiqueta={texto('Construcción Común 3')} id="construccionComun3" tipo="numeroNumerico" />
					</Col>
					<Col md="2">
						<McCampoTexto campo="construccionComun4" etiqueta={texto('Construcción Común 4')} id="construccionComun4" tipo="numeroNumerico" />
					</Col>
					<Col md="2">
						<McCampoTexto campo="construccionComun5" etiqueta={texto('Construcción Común 5')} id="construccionComun5" tipo="numeroNumerico" />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DepartamentoFormulario;
