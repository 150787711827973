import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/api/prueba';

/**
 * Actualiza un pais existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del pais a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const comprobarApi = (): Promise<McRespuesta> => {
	const nombreMetodo = 'comprobarApi';
	return new Promise((resolve, reject) => {
		mcLogger.api({
			mensaje: `Verificando estatus de la API...`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		axios
			.get(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.prueba}
		`
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Estatus de la API verificado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al verificar el estatus de la API:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
